<template>
    <div class="hero-wrap" id="booking-section"  style="background-image: url('images/toyota_cross_background.png');" data-stellar-background-ratio="1">

        <div class="overlay"></div>

        <section class="ftco-section ftco-no-pt booking-form">
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-md-12	featured-top">
                        <div class="row no-gutters">
                            <GetEstimateFrom/>
                            <GetEstimateSideSection/>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script >
    import GetEstimateFrom from './GetEstimateForm.vue';
    import GetEstimateSideSection from './GetEstimateSideSection.vue';

    export default {
        name: 'EstimateFormSection',
        props: {

        },
        components: {
            GetEstimateFrom,
            GetEstimateSideSection,
        }
    }
</script>

<style lang="scss">
    .services-wrap {
        background-color: white;
    }
    .featured-top {
        margin-top:100px;
    }
</style>