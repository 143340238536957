<template>
    <modal :modalActive="showModal" :headerName="'Update Product'" @close="closeModal" :showModalSelectError="false" :mediaModalHeight="'900px'">
        <update-product :item="item" @close="closeModal" />
    </modal>

</template>

<script>
    import Modal from '../src/components/modal/GeneralModal.vue'
    import UpdateProduct from '../src/components/adminPannelComponents/UpdateProduct.vue'
    export default {
        props: ["showModal", "item"],
        name: 'UpdateProductView',
        data() {

            return {
            }
        },
        components: {
            Modal,
            UpdateProduct,
        },

        methods: {
            closeModal() {
                this.$emit("closeModal");
            },
        }

    }

</script>

<style lang="scss" scoped>


</style>