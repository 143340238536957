import { createI18n } from 'vue-i18n';
var i18n = createI18n({
    locale: 'en',
    globalInjection: true,
    messages: {
        en: {
            message: {
                language: "English",
                perDay: "/per day",
                orSimilar: "Or similar",
                longterm2: "Long Term Rental 20+day",
                longterm1: "Per Day Rate 10+day",
                perDay1: "Per Day",
                includedMiles: '*Includes {0} miles/per day',
                slogan: "Make Your Ride",
                slogan1: "Rent a Car Power your Travel",
                debet_card: "Debit Card",
                credit_card: "Credit Card",
                card_type: "Choose Card Type",
                your_info: "Your Information",
                address: "1324 Pharaoh Rd, SLC UT 84123",
                pick_up: "Pick-up",
                return: "Return",
                similar: "Or Similar",
                miles_included: "MIles Included",
                primary_driver: "Primary Driver",
                additional_driver: "Additional Driver",
                payment_info: "Payment Information",
                insurance_info: "Insurance Information",
                insurance_info_note: "If you declined our rental and liability coverage, please email your proof of insurance " +
                    "(declaration page) with comprehensive and collision coverage. Minimum $500 deductible required. " +
                    "Include your reservation number in the email subject and attach your proof of insurance.",
                additional_driver_note: "Additional Driver Needs To Have Valid Driving Licnese. Needs To Be over 21. (Additional Fees Apply).",
                general_info: "Please be precise with your information if we can't verify your information, the  reservation will be canceled and you will be charged $30 of processing fee.",
                yes: "Yes",
                no: "No",
            }
        },
    }
});
export default i18n;
