import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import store from './store/index.ts'
import i18n from './i18n'
import router from './router'
import Toaster from '@meforma/vue-toaster';

const speedyRentalApp = createApp(App);
if(window.location.origin === "https://carspeedyrental.com" || window.location.origin === "http://carspeedyrental.com" +
    "" || window.location.origin === "https://www.carspeedyrental.com" || window.location.origin === "http://www.carspeedyrental.com") {
    speedyRentalApp.config.globalProperties.$imgBaseUrl = '../../../backend/';
} else {
    speedyRentalApp.config.globalProperties.$imgBaseUrl = 'http://localhost/speedyrental/backend/'
}

speedyRentalApp.use(store).use(i18n).use(router).use(Toaster)
speedyRentalApp.mount('#app')
