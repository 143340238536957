<template>
    <div class="row">
        <div class="col">Vehicle</div>
        <div class="col">User Name</div>
        <div class="col">Start</div>
        <div class="col">End</div>
        <div class="col">Total Price</div>
        <div class="col">Deposit</div>
        <div class="col">prepaidFueling</div>
        <div class="col">MilesIncluded</div>
        <div class="col">Last Minute Booking</div>
        <div class="col">Young Driver Fee</div>
        <div class="col">Charge Id</div>
    </div>
</template>
<script>

</script>
<style>

</style>