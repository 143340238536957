<template >
    <div class="row"  @click="select"  :style="this.rowStyle" >
        <div class="col">{{ this.getCar() }}</div>
        <div class="col">{{ reservation.userFirstName }} {{ reservation.userLastName }}</div>
        <div class="col">{{ reservation.startDate }}</div>
        <div class="col">{{ reservation.endDate }}</div>
        <div class="col">{{ reservation.totalPrice.toFixed(2) }}</div>
        <div class="col">{{ reservation.deposit }}</div>
        <div class="col">{{ !!reservation.prepaidFueling }}</div>
        <div class="col">{{ reservation.milesIncluded }}</div>
        <div class="col">{{ !!reservation.isLastMinuteBooking }}</div>
        <div class="col">{{ reservation.youngDriverFee }}</div>
        <div class="col">{{ reservation.charge_id }}</div>
        <div class="col" id="isConfirmed" > {{reservation.isConfirmed}}</div>
    </div>
</template>
<script>
    import {selectedCar} from "../../types";
    import moment from 'moment'

    export default {
        props: ['reservation'],
        name: 'ReservationView',

        data() {
            return {
                reservationStatus: this.reservationClassification()[0],
                rowStyle: this.reservationClassification()[1],
            }

        },
        components: {
        },
        methods: {
            getCar() {
                const car = this.$store.state.cars.filter(car => car.Id === this.reservation.car_id)
               return  car[0].Name;
            },
            select() {
                this.$emit('selectAction', this.reservation.charge_id)
            },
            reservationClassification() {
                const now = moment();
                const start = moment(this.reservation.startDate);
                const end = moment(this.reservation.endDate);
                const diffHours = start.diff(now, "hours");

                if(!this.reservation.isActive) {
                    return ['Canceled', '']
                } else {

                    if (start.isSame(now, 'day') || diffHours < 12 && diffHours > 0) {
                        if (this.reservation.checked_in && !this.reservation.checked_out) {
                            return ['In Progress', 'color: #f39c12'];
                        } else if (!this.reservation.checked_in && this.reservation.checked_out) {
                            return ['Error', ''];
                        } else if(!this.reservation.checked_in && !this.reservation.checked_out){
                            return ['Coming Up', 'color: #3498db'] ;
                        }
                    } if(end.isSame(now, 'day') &&  start.isBefore(now) && this.reservation.checked_in && !this.reservation.checked_out) {
                        return ["Due Today", ''];
                    }if(start.isBefore(now) && !this.reservation.checked_out && !this.reservation.checked_in) {
                        return ['No Show', 'color: #7012f3'];
                    } if(start.isBefore(now) && end.isBefore(now) &&!this.reservation.checked_out) {
                        return ["Late", 'color: #e74c3c']
                    } else if (start.isAfter(now) && !this.reservation.checked_out && !this.reservation.checked_in) {
                        return ['Future', ''];
                    } else if (start.isBefore(now) && !this.reservation.checked_out) {
                        return ['In Progress', 'color: #f39c12'];
                    } else if (start.isBefore(now) && this.reservation.checked_in && this.reservation.checked_out) {
                        return ['Past', ''];
                    } else {
                        return ['Unknown', ''];
                    }
                }

            },

        }
    }

</script>
<style lang="scss" scoped>
    #isConfirmed {
        display: none;
    }



</style>