<template>
        <div class="row">
            <div class="col-md-12 ftco-animate">
                <div class="car-list">
                    <table class="table">
                        <table-header/>
                        <tbody>
                        <tr class="table-row" v-for="car in getCars" :key="car.Id">
                         <table-item :item="car"></table-item>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
</template>
<script>
    import TableItem from './TableItem.vue'
    import TableHeader from './TableHeader.vue'
    import { toRaw } from 'vue'
//    import {Car} from "../../types";

    export default {
        name: 'TableView',
        props: {
        },
        components: {
            TableItem,
            TableHeader
        },
        computed: {
             getCars() {
                 let cars = toRaw(this.$store.state.cars)
                 return cars;
             }
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    .table-row {

    }
</style>