// import {VueRouter} from "../main.js"
import { Cars, Estimate, Reservations } from '../types';
export default {
    cars: Cars,
    priceManipulations: null,
    insurancePrices: null,
    reservations: Reservations,
    isEstimatePriceModalOpen: false,
    possibleReservationData: null,
    taxesAndFeesPercentage: null,
    toastMessage: {},
    showLoader: null,
    showPaymentLoader: null,
    user: {},
    verificationCode: null,
    unlimtedMilesDailyFee: 13,
    plus30DailyMilage: 300,
    plus20DailyMilage: 200,
    dailyMilage: 200,
    estimate: Estimate,
    preCheckInFormReservation: null,
};
