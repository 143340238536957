<template>
    <div id="navbar">
        <div class="container" >
            <nav class="navbar navbar-expand-lg navbar-light sticky" >

                <router-link class="navbar-brand" to="/">Car<span>SpeedyRental</span></router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <ul class="navbar-nav ml-auto" style="list-style:none;" v-if="!this.$store.state.user?.first_name && !this.$store.state.user?.last_name">
                            <li class="nav-item" >
                                <router-link class="nav-link" to="/login">Sign In</router-link>
                            </li>
                            <li class="nav-item" >
                                <router-link class="nav-link" to="/register">Register</router-link>
                            </li>


                        </ul>

                        <li class="nav-item" v-else>
                            <div class="dropdown show">
                                <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ this.$store.state.user?.last_name}}, {{ this.$store.state.user?.first_name}}
                                </a>

                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <router-link class="dropdown-item" to="/adminPanel" v-if="this.$store.state.user?.user_type === 'admin'">Admin Panel</router-link>
                                    <router-link class="dropdown-item" to="/myAccount">My Account</router-link>
                                    <router-link class="dropdown-item" to="/myReservations">My Reservations</router-link>
                                    <a class="dropdown-item" href="#" @click="signOut">Sign Out</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import { useStore } from 'vuex'
    export default {
        name: 'appHeader2',
        props: {},

        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                var navbar = document.getElementById("navbar");
                if(navbar) {
                    var sticky = navbar.offsetTop + 150;
                    if (window.pageYOffset >= sticky) {
                        navbar.classList.add("navbar-sticky")
                    } else {
                        navbar.classList.remove("navbar-sticky");
                    }
                }
            },

            signOut() {
                localStorage.setItem('jwt', {});
                this.$store.dispatch("logOutUser");
                this.$router.push('/login');
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .navbar-brand {
        font-weight: 800;
        font-size: 20px;
        text-transform: uppercase;
        /*color: #fff !important;*/

        span {
            color: #ff992e;
            font-weight: 800;
            font-size: 20px;
        }
    }
    .navbar-nav {
        margin-left: auto;
    }
</style>
