<template>
    <div class="container">
        <div id="navbar">
            <nav class="navbar navbar-expand-lg navbar-light sticky" >
                <router-link class="nav-link navbar-brand"  to="/">Car<span>SpeedyRental</span></router-link>
            </nav>
        </div>
        <form @submit.prevent="continueSubmition">
            <div>
                <p class="color-red"> {{ $t("message.general_info") }}</p>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h3>{{ $t("message.primary_driver") }}</h3>
                    <!-- first name input -->
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.firstName" class="form-control" placeholder="First Name" disabled/>
                        <div class="form-error" v-if="v$.form.firstName.$error">
                            <small v-if="v$.form.firstName.required.$invalid" >Invalid First Name</small>
                        </div>
                    </div>

                    <!-- last name input -->
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.lastName"  class="form-control" placeholder="Last Name" disabled/>
                        <div class="form-error" v-if="v$.form.lastName.$error">
                            <small v-if="v$.form.lastName.required.$invalid" > Invalid Last Name</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <Datepicker v-model="form.customerDob"   :enableTimePicker="false" :placeholder="`Date Of Birth: DD/MM/YYYY`" autoApply  />
                        <div class="form-error" v-if="v$.form.customerDob.$error">
                            <small v-if="v$.form.customerDob.required.$invalid"> Date Of Birth Required</small>
                        </div>
                    </div>


                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.driverLicenseNumber" class="form-control" placeholder="Driving License #"  />
                        <div class="form-error" v-if="v$.form.driverLicenseNumber.$error">
                            <small v-if="v$.form.driverLicenseNumber.required.$invalid"> Driving License Number Is Required</small>
                            <small v-if="v$.form.driverLicenseNumber.alphaNum.$invalid"> It should Only Contain Numbers Or Letter  </small>
                        </div>
                    </div>

                    <!-- phone input -->
                    <div class="form-outline mb-4">
                        <tel-input-field  v-model="form.phone" :identifier="'userPhone'" :inputPlaceHolder="'Primary Phone Number'" @onChange="getUserPhoneNumber"></tel-input-field>
                        <div class="form-error" v-if="v$.form.phone.$error" >
                            <small   v-if="v$.form.phone.required.$invalid"> Phone Is Required</small>
                            <small  v-if="v$.form.phone.allowOnlyNumBrackets.$invalid" > Only Numbers Allowed</small>
                            <small   v-if="v$.form.phone.minLength.$invalid || v$.form.phone.maxLength.$invalid">
                                Must Be Valid Phone Number</small>
                        </div>
                    </div>

                    <!-- Email input -->
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.email" id="email" class="form-control" placeholder="Enter Email"/>
                        <div class="form-error" v-if="v$.form.email.$error" >
                            <small v-if="v$.form.email.required.$invalid"> Email Is Required</small>
                            <small v-if="v$.form.email.email.$invalid" > Not Valid Email</small>
                        </div>
                    </div>


                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.currentAddress"  class="form-control" placeholder="Home Address(Address, City, State, Zip, Country)" />
                        <div class="form-error" v-if="v$.form.currentAddress.$error">
                            <small v-if="v$.form.currentAddress.required.$invalid"> Address Is Required</small>
                        </div>
                    </div>
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.secondaryAddress"  class="form-control" placeholder="Address In Utah (hotel/Airbnb/Friend) (Address, City, State, Zip, Country)" />
                        <div class="form-error" v-if="v$.form.secondaryAddress.$error">
                            <small v-if="v$.form.secondaryAddress.required.$invalid"> Address Is Required</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.emergencyContactName"  class="form-control" placeholder="Emergency Contact Name" />
                        <div class="form-error" v-if="v$.form.emergencyContactName.$error">
                            <small v-if="v$.form.emergencyContactName.required.$invalid"> Emergency Contact Is Required</small>
                        </div>
                    </div>

                     <!--phone input-->
                    <div class="form-outline mb-4">
                        <tel-input-field  v-model="form.emergencyContactNumber"  :identifier="'emergencyPhone'" :inputPlaceHolder="`Emergency Contact's Number`" @onChange="getEmergencyPhoneNumber"></tel-input-field>
                        <div class="form-error" v-if="v$.form.emergencyContactNumber.$error" >
                            <small   v-if="v$.form.emergencyContactNumber.required.$invalid"> Phone Is Required</small>
                            <small  v-if="v$.form.emergencyContactNumber.allowOnlyNumBrackets.$invalid" > Only Numbers Allowed</small>
                            <small   v-if="v$.form.emergencyContactNumber.minLength.$invalid || v$.form.phone.maxLength.$invalid">
                                Must Be Valid Phone Number</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <p>Did You Buy Rental and Liability Insurance Through Speedyrental?</p>
                        <div class="form-check form-check-inline" style="margin-right: 40px;">
                            <input class="form-check-input" type="radio" name="insuranceOptions" id="yes" value="1" v-model="hasInsuranceBought" />
                            <label class="form-check-label" for="credit">{{ $t("message.yes") }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="insuranceOptions " id="no" value="0" v-model="hasInsuranceBought" />
                            <label class="form-check-label" for="debit">{{ $t("message.no") }}</label>
                        </div>
                    </div>

                </div>
                <div class="col-md-6" >
                    <h3>{{ $t("message.additional_driver") }}</h3>

                    <div class="form-outline mb-4">
                        <div class="form-check form-check-inline" style="margin-right: 40px;">
                            <input class="form-check-input" type="radio" name="additionalDriverOptions" id="credit" value="1" v-model="form.isAdditionalDriver" />
                            <label class="form-check-label" for="credit">{{ $t("message.yes") }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="additionalDriverOptions " id="debit" value="0" v-model="form.isAdditionalDriver" @input="resetAdditionalDriverFrom" />
                            <label class="form-check-label" for="debit">{{ $t("message.no") }}</label>
                        </div>
                    </div>

                    <span class="additional-driver-note">{{ $t("message.additional_driver_note") }}</span>
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.additionalDriverFirstName"  class="form-control additional-driver-field" placeholder="Additional Driver First Name" :disabled="this.form.isAdditionalDriver == 0" />
                        <div class="form-error" v-if="v$.form.additionalDriverFirstName.$error">
                            <small v-if="v$.form.additionalDriverFirstName.required.$invalid"> Additional Driver Name Is Required</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.additionalDiverLastName"  class="form-control additional-driver-field" placeholder="Additional Driver Last Name" :disabled="this.form.isAdditionalDriver == 0"/>
                        <div class="form-error" v-if="v$.form.additionalDiverLastName.$error">
                            <small v-if="v$.form.additionalDiverLastName.required.$invalid"> Additional Driver Name Is Required</small>
                        </div>
                    </div>

                    <!--phone input-->
                    <div class="form-outline mb-4">
                        <tel-input-field  v-model="form.additionalDriverPhone"  :identifier="'additionalDriverPhone'" :disable="this.form.isAdditionalDriver == 0" @onChange="getAdditionalDriverPhoneNumber" ></tel-input-field>
                        <div class="form-error" v-if="v$.form.additionalDriverPhone.$error" >
                            <small   v-if="v$.form.additionalDriverPhone.required.$invalid"> Phone Is Required</small>
                            <small  v-if="v$.form.additionalDriverPhone.allowOnlyNumBrackets.$invalid" > Only Numbers Allowed</small>
                            <small   v-if="v$.form.additionalDriverPhone.minLength.$invalid || v$.form.phone.maxLength.$invalid">
                                Must Be Valid Phone Number</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.additionalDriverAddress"  class="form-control additional-driver-field" placeholder="Additional Driver Address(Address, City, State, Zip, Country)" :disabled="this.form.isAdditionalDriver == 0" />
                        <div class="form-error" v-if="v$.form.additionalDriverAddress.$error">
                            <small v-if="v$.form.additionalDriverAddress.required.$invalid"> Additional Driver Address Is Required</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.additionalDriverLicenseNumber"  class="form-control additional-driver-field" placeholder="Additional Driver License#" :disabled="this.form.isAdditionalDriver == 0" />
                        <div class="form-error" v-if="v$.form.additionalDriverLicenseNumber.$error">
                            <small v-if="v$.form.additionalDriverLicenseNumber.required.$invalid"> Additional Driver License Number Is Required</small>
                            <small v-if="v$.form.additionalDriverLicenseNumber.alphaNum.$invalid"> It should Only Contain Numbers Or Letter </small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <Datepicker v-model="form.additionalDriverDob"   :enableTimePicker="false" :placeholder="`Additional Driver Date Of Birth: DD/MM/YYYY`" autoApply  :disabled="this.form.isAdditionalDriver == 0"/>
                        <div class="form-error" v-if="v$.form.additionalDriverDob.$error">
                            <small v-if="v$.form.additionalDriverDob.required.$invalid"> Additional Driver Date Of Birth Is Required</small>
                        </div>
                    </div>



                    <h3>{{ $t("message.insurance_info") }}</h3>
                    <span class="additional-driver-note">{{ $t("message.insurance_info_note") }} <a href="mailto:carspeedyrental@gmail.com">carspeedyrental@gmail.com</a> </span>
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.insuranceCompany" class="form-control" placeholder="Auto Insurance Company" :disabled="this.hasInsuranceBought == 1" />
                        <div class="form-error" v-if="v$.form.insuranceCompany.$error">
                            <small v-if="v$.form.insuranceCompany.required.$invalid"> Insurance Company Name Is Required</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.insurancePolicyNumber" class="form-control" placeholder="Insurance Policiy #"  :disabled="this.hasInsuranceBought == 1" />
                        <div class="form-error" v-if="v$.form.insurancePolicyNumber.$error">
                            <small v-if="v$.form.insurancePolicyNumber.required.$invalid"> Insurance Policy Number Is Required</small>
                            <small v-if="v$.form.insurancePolicyNumber.numeric.$invalid"> Insurance Policy Needs To Be Number</small>
                        </div>
                    </div>


                </div>

            </div>


            <div class="row">
                <div class="col-md-6">
                    <!--<h3>{{ $t("message.payment_info") }}</h3>-->
                    <!--<div class="form-outline mb-4">-->
                        <!--<input type="text" v-model="form.cardNumber" class="form-control" placeholder="Credit/Debit Card Number" />-->
                        <!--<div class="form-error" v-if="v$.form.cardNumber.$error">-->
                            <!--<small v-if="v$.form.cardNumber.required.$invalid"> Card Number Is Required</small>-->
                            <!--<small v-if="v$.form.cardNumber.numeric.$invalid"> Card Needs To Be Number</small>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="form-outline mb-4">-->
                        <!--<input type="text" v-model="form.nameOnCard" class="form-control" placeholder="Name On The Card" />-->
                        <!--<div class="form-error" v-if="v$.form.nameOnCard.$error">-->
                            <!--<small v-if="v$.form.nameOnCard.required.$invalid"> Name Is Required</small>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="form-outline mb-4">-->
                        <!--<Datepicker v-model="form.expirationDate"   :enableTimePicker="false" :placeholder="`Card Expiration Date`" autoApply />-->
                        <!--<div class="form-error" v-if="v$.form.expirationDate.$error">-->
                            <!--<small v-if="v$.form.expirationDate.required.$invalid"> Expiration Date Required</small>-->
                        <!--</div>-->
                    <!--</div>-->

                </div>
            </div>

            <!-- Submit button -->
            <button id="submit" type="submit" class="btn btn-primary btn-block ">Submit</button>

        </form>
    </div>

    <app-footer/>


    <modal :modalActive="showModal"  @close="closeModal" :showModalSelectError="false">
        <div class="loader-div" >
            <div  class="loader"></div>
        </div>

        <div class="row-cols-md-12 form-outline">
            <p>Electronic Signature</p>
            <small class="color-red">You Can Only Submit This Form Once. After You Submit This Form, You Will Not Be Able To Change it</small>
            <input type="text" v-model="form.signature"  class="form-control additional-driver-field" placeholder="Signature"  />
            <div class="signature-div">
                <p class="signature">{{this.form.signature}}</p>
            </div>
            <p>Double Check And Make Sure All The Information Is Correct To Avoid Delays and Additional Charges</p>
            <div class="terms">
                <input type="checkbox" v-model="this.agree_conditions"/> I agree <a target="_blank" href="../terms&conditions.pdf">Terms and Conditions</a>
            </div>
            <button type="submit" @click="submitPreCheckingInfo" class="btn btn-primary btn-block mb-4"  :disabled="this.agree_conditions == 0">Sign And Submit</button>
        </div>
    </modal>

</template>

<script>
    import appHeader from '../src/components/appHeader.vue'
    import { useVuelidate } from '@vuelidate/core'
    import {computed } from 'vue'
    import { required, minLength, maxLength, between, email, sameAs, numeric, alphaNum } from '@vuelidate/validators'
    import Modal from '../src/components/modal/GeneralModal.vue'
    import appFooter from '../src/components/appFooter.vue'
    import {Reservation} from "../src/types";
    import telInputField from '../src/components/tel-input-field.vue';
    import Datepicker from '@vuepic/vue-datepicker';
    import moment from 'moment'

    //must have least  6 characters and least one number special chars allowed.
    const mustInclude = (value) => {
        const regex = new RegExp("^(?=.*[A-Za-z])(?=.*?[0-9])(?=.*\\d)[A-Za-z\\d$@$!%#?&]{6,}$");
        return regex.test(value);
    }

    const allowOnlyNumBrackets = (value) => {
        // regex only valid if first three digit is surrounded by round brackets and has only numbers
        const regex = /^\(\d{3}\)[\d()]*$/;
        return regex.test(value);
    };


    const date20YearsBack = new Date(new Date().setFullYear(new Date().getFullYear() - 20));


    export default {
        name: 'PrecheckoutInfoView',

        reservationInfo: null,

        async mounted() {
            if (!this.$route.params.id) {
                this.$router.push('/errorPage');
            } else {
                await this.$store.dispatch('getReservation', this.$route.params.id);
            }
        },

        data() {
            return {
                v$: useVuelidate(),
                charge_id: this.$route.params.id,
                reservation: computed(() => this.$store.state.preCheckInFormReservation),
                date20YearsBack: new Date(new Date().setFullYear(new Date().getFullYear() - 20)),
                form: {
                    firstName: computed(() => this.$store.state.preCheckInFormReservation?.userFirstName),
                    lastName: computed(() => this.$store.state.preCheckInFormReservation?.userLastName),
                    phone: null,
                    phoneCountryCode: null,
                    email: null,
                    driverLicenseNumber: null,
                    customerDob: '',
                    currentAddress: null,
                    secondaryAddress: null,
                    emergencyContactName: null,
                    emergencyContactNumber: null,
                    emergencyContactNumberCountryCode: null,
                    additionalDriverFirstName: null,
                    additionalDiverLastName: null,
                    additionalDriverPhone: null,
                    additionalDriverPhoneCountryCode: null,
                    additionalDriverAddress: null,
                    additionalDriverLicenseNumber: null,
                    additionalDriverDob: '',
                    isAdditionalDriver: 0,
                    insuranceCompany: null,
                    insurancePolicyNumber: null,

                    drivingLicenseNumber: null,
                    primaryDriverDob: '',

                    cardNumber: '0000',
                    nameOnCard: "bbbb",
                    expirationDate: '08-01-2050',
                    signature: null,
                },
                showModal: false,
                verificationCode: null,
                phoneInputKey: 'phone-1',
                agree_conditions: false,
                hasInsuranceBought: 0,
            }
        },

        validations() {
            let validations = {
                form: {
                    firstName: { required },
                    lastName: { required },
                    phone: { required, minLength: minLength(11), maxLength: maxLength(15), allowOnlyNumBrackets },
                    email: { required, email },
                    driverLicenseNumber:  { required, alphaNum },
                    customerDob: { required, },
                    currentAddress: { required },
                    secondaryAddress: { required },
                    emergencyContactName: { required },
                    emergencyContactNumber: { required, minLength: minLength(11), maxLength: maxLength(15), allowOnlyNumBrackets },
                    cardNumber: { required, numeric },
                    nameOnCard: { required },
                    expirationDate: { required },
                },
            };
            let additionalDriverValidations = null;
            if (this.form.isAdditionalDriver == 1) {
                additionalDriverValidations = {
                    form: {
                        additionalDriverFirstName: { required },
                        additionalDiverLastName: { required },
                        additionalDriverPhone: { required, minLength: minLength(11), maxLength: maxLength(15), allowOnlyNumBrackets },
                        additionalDriverAddress: { required },
                        additionalDriverLicenseNumber: { required, alphaNum },
                        additionalDriverDob: { required, },
                    },
                }
            } else {
                additionalDriverValidations = {
                    form: {
                        additionalDriverFirstName: {  },
                        additionalDiverLastName: {   },
                        additionalDriverPhone: {   },
                        additionalDriverAddress: {   },
                        additionalDriverLicenseNumber: {   },
                        additionalDriverDob: {   },
                    },
                }
            }

            let insuranceValidations = null;
            if(this.hasInsuranceBought == 0) {
                insuranceValidations = {
                    form: {
                        insuranceCompany: { required },
                        insurancePolicyNumber: { required, numeric },
                    },
                }
            } else {
                insuranceValidations = {
                    form: {
                        insuranceCompany: { },
                        insurancePolicyNumber: { },
                    },
                }
            }
            validations.form = {
                ...validations.form,
                ...additionalDriverValidations.form,
                ...insuranceValidations.form,
            };
            return validations;
        },

        computed: {

        },

        components: {
            appHeader,
            Modal,
            telInputField,
            appFooter,
            Datepicker,
        },

        methods: {
            closeModal() {
                this.showModal = !this.showModal;
                this.form.signature = null;
            },

            getUserPhoneNumber(value) {
                this.form.phone = value.number;
                this.form.phoneCountryCode = value.countryCode;
            },

            getEmergencyPhoneNumber(value) {
                this.form.emergencyContactNumber = value.number;
                this.form.emergencyContactNumberCountryCode = value.countryCode;
            },

            getAdditionalDriverPhoneNumber(value) {
                this.form.additionalDriverPhone = value.number;
                this.form.additionalDriverPhoneCountryCode = value.countryCode;
            },

            resetAdditionalDriverFrom() {
                this.form.additionalDriverFirstName = null;
                this.form.additionalDiverLastName = null;
                this.form.additionalDriverPhone = null;
                this.form.additionalDriverAddress = null;
                this.form.additionalDriverLicenseNumber = null;
                this.form.additionalDriverDob = null;
                this.form.isAdditionalDriver = 0;
            },

            continueSubmition() {
                this.v$.form.$validate();
                if(!this.v$.form.$error) {
                    this.showModal = true;
                }
            },

            submitPreCheckingInfo() {
                if(this.agree_conditions && this.form.signature) {
                    // Create a new FormData object
                    const preCheckinFormData = new FormData();

                    // Iterate over the properties of the reservation object and append them to the FormData
                    for (let key in this.form) {
//                        moment(this.$store.state.estimate.pickupTime + ' ' + this.$store.state.estimate.pickupDate).format("YYYY-MM-DD HH:mm:ss");
                        if(key === 'additionalDriverDob' || key === 'expirationDate' || key === 'customerDob') {
                            preCheckinFormData.append(key, moment(this.form[key]).format("YYYY-MM-DD"));
                        } else{
                            preCheckinFormData.append(key, this.form[key]);
                        }

                    }
                    preCheckinFormData.append('charge_id', this.charge_id);
                    this.$store.dispatch('submitPreCheckInForm', preCheckinFormData).then(
                        response => {
                            if(response.data.status === 'success') {
                                this.closeModal();
                                this.$store.dispatch('toastMessage',  {type: 'success', msg: "Information Successfully Submitted"})
                                this.$router.push('/');
                            } else {
                                if(response.data.status === 'error') {
                                    this.closeModal();
                                    this.$store.dispatch('toastMessage',  {type: 'error', msg: response.data.msg})
                                }else this.$store.dispatch('toastMessage',  {type: 'error', msg: "Error  Try Again Later or Contact Support"})
                            }
                        }
                    )
                } else {
                    this.closeModal();
                    this.$store.dispatch('toastMessage',  {type: 'error', msg: "Signature Is Required"})
                }
            },
        },

    }

</script>

<style lang="scss" scoped>


    .terms {
        margin: 10px;
    }
    .signature-div {
        width: 360px;
        height: 70px;
        background-color: white;
        margin: 25px auto;


        .signature {
            font-family: Shalimar;
            font-size: 50px;
            height: inherit;
        }
    }

    @media screen and (max-width: 800px) {
        .signature-div {
            width: auto;
            height: auto;
        }
    }



    .container {
        margin-bottom: 100px;
        form {
            margin: auto;
            margin-top: 100px;
            padding: 10px;

            .col{
                padding-right: 0px;
            }
            .phone-div {

                #phone {
                    width: 91%;
                    display: inline;
                }
            }
            .additional-driver-note {
                font-size: 14px;
                color: red;
                display: block;
                margin: 29px;
            }
            #submit {
                width: 400px;
                margin: auto;
            }

        }

        .navbar-brand {
            font-weight: 800;
            font-size: 20px;
            text-transform: uppercase;
            /*color: #fff !important;*/

            span {
                color: #ff992e;
                font-weight: 800;
                font-size: 20px;
            }
        }

        .navbar-nav {
            margin-left: auto;
        }
        .navbar-sticky {
            position: fixed;
            z-index: 1;
            background-color: white;
            left: 0;
            right: 0;
            transition: width 10s;
            color: white;


        }

        .verification-input {
            margin-bottom: 20px;
        }

        .loader-div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.7);



            .loader {
                position: absolute;
                z-index: 2;
                border: 8px solid #f3f3f3; /* Light grey */
                border-top: 8px solid #ff992e; /* Blue */
                border-radius: 50%;
                width: 40px;
                height: 40px;
                animation: spin 2s linear infinite;
            }
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        }
    }
    .color-red {
        color: red;
    }


</style>