<template>
    <div class="container full-height">
        <app-header2 />

        <div class="row tab-row">
            <div class="tab-pane">
                <ul class="nav nav-tabs">
                    <li class="nav-item" >
                        <router-link :class="{  'active': $route.path === '/myAccount/personalInfo' || $route.path === '/myAccount' }" to="/myAccount/personalInfo" class="nav-link" >Personal Information</router-link>
                    </li>
                    <li class="nav-item" >
                        <router-link :class="{ 'active': $route.path === '/myAccount/changePassword' }" to="/myAccount/changePassword" class="nav-link " aria-current="page" exact>Change Password</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <router-view></router-view>


    </div>

    <app-footer />


</template>

<script>
    import { useVuelidate } from '@vuelidate/core'
    import appHeader2 from '../src/components/AppHeader2.vue';
    import appFooter from '../src/components/appFooter.vue';
    import { RouterLink, RouterView } from 'vue-router';
    import { required, minLength, maxLength, between, email, sameAs, numeric } from '@vuelidate/validators';


    //must have least  6 characters and least one number special chars allowed.
    const mustInclude = (value) => {
        const regex = new RegExp("^(?=.*[A-Za-z])(?=.*?[0-9])(?=.*\\d)[A-Za-z\\d$@$!%#?&]{6,}$");
        return regex.test(value);
    }

    export default {
        name: 'MyAccount',
        data() {

            return {
                v$: useVuelidate(),
                form: {
                    newPassword: null,
                    confirmPassword: null,
                    oldPassword: null,
                },
            }
        },

        components: {
            appHeader2,
            appFooter,
        },

        methods: {
            changePassword() {
                this.v$.form.$validate();
                const passwordSet = {
                    newPassword: this.form.newPassword,
                    confirmPassword: this.form.confirmPassword,
                    oldPassword: this.form.oldPassword,
                }


                if(!this.v$.form.$error) {
                    this.$store.dispatch('changePassword', passwordSet)
                }

            }
        },

        validations () {
            return {
                form: {
                    newPassword: {
                        required,
                        mustInclude,
                        minLength: minLength(6),
                        maxLength: maxLength(12),
                    },
                    confirmPassword: {
                        required,
                        minLength: minLength(6),
                        maxLength: maxLength(12),
                        sameAs: sameAs(this.form.newPassword)
                    },
                    oldPassword: {
                        required,
                    }
                }
            }
        },

    }

</script>

<style lang="scss" scoped>

    .full-height {
        height: 75vh;
    }

    .navbar-brand {
        font-weight: 800;
        font-size: 20px;
        text-transform: uppercase;
        /*color: #fff !important;*/

        span {
            color: #ff992e;
            font-weight: 800;
            font-size: 20px;
        }
    }

    .navbar-nav {
        margin-left: auto;
    }
    .navbar-sticky {
        position: fixed;
        z-index: 1;
        background-color: white;
        left: 0;
        right: 0;
        transition: width 10s;
        color: white;

    }

</style>