<template>
    <td class="car-image">
        <img :src="this.$imgBaseUrl + getCar.Picture" :alt="getCar.Name"/>

        <br/>
        <small class="price_under_image">${{(getCar.Price - 2).toFixed(2)}}/day</small>
    </td>
    <td  class="product-name">
        <h3>{{getCar.Name}}</h3>
        <small>{{ $t("message.orSimilar") }}</small>
    </td>
    <td class="price">
        <div class="price-rate">
            <h3>
                <span class="num"><small class="currency">$</small> {{(getCar.Price - 2).toFixed(2)}}</span>
                <span class="per">{{ $t("message.perDay") }} </span>
            </h3>
        </div>
    </td>
    <td class="price">
        <div class="price-rate">
            <h3>
                <span class="num"><small class="currency">$</small> {{(getCar.Price - 1).toFixed(2)}}</span>
                <span class="per">{{ $t("message.perDay") }}</span>
            </h3>
        </div>
    </td>
    <td class="price">
        <div class="price-rate">
            <h3>
                <span class="num"><small class="currency">$</small> {{getCar.Price}}</span>
                <span class="per">{{ $t("message.perDay") }}</span>
            </h3>
        </div>
    </td>
</template>
<script >
//    import Car from '../../types'
    export default {
        name: 'TableItem',
        props: ['item'],
        components: {},

        mounted() {
        },

        methods: {

        },
        computed: {
          getCar() {
              return this.item;
          }
        },
    }

</script>
<style lang="scss">
        tr {
            .car-image {
                img {
                    width: 200px;
                }
            }
        }
        .price {
            text-align: center !important;
            background: rgba(0, 0, 0, 0.05);
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff !important;
            position: relative;
            h3 {
                .num {
                    color: #1089ff;
                    font-size: 20px;
                    position: relative;
                }
                .per {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.4);
                }
            }
        }
        .product-name {
            vertical-align: middle;
            text-align: left;
            padding: 39px;
            padding-right: 37px;
            h3 {
                font-size: 18px;
            }
        }
        @media screen and (max-width: 800px) {

            tr {
                .car-image {
                    img {
                        width: 110px;
                    }
                }
                .product-name {
                    h3 {
                        font-size: 14px;
                    }
                    small {
                        font-size: 11px;
                    }
                }
                .price {

                    .price-rate {
                        margin-top: 30px;
                    }
                    overflow: hidden;
                    white-space: nowrap;
                    h3 {
                        .num {
                            font-size: 14px;
                        }
                        .per {
                            font-size: 11px;
                        }
                    }
                }
            }
            td {
                h3 {

                }
            }
        }

</style>