<template>
    <div class="col-md-4 d-flex align-items-center">
        <form @submit.prevent="openEstimateModal" class="request-form ftco-animate bg-primary">
            <h2>{{ $t("message.slogan") }}</h2>
            <span>{{ $t("message.slogan1") }}</span>
            <div class="form-group pick-box">
                <label  class="label">Pick-up/Drop-off location</label>
                <input class="form-control custom-input" v-model="form.location"  @click="showDeliveryModal" />
            </div>

            <label  class="label">Pick-up date</label>
            <div class="d-flex pickupTime">
                <div class="form-group mr-2">
                    <Datepicker v-model="form.pickUpDate"   :enableTimePicker="false" :minDate="new Date()" @internal-model-change="updateDropoffDate" autoApply />
                </div>
                <div class="form-group mr-2">
                    <Datepicker v-model="form.pickUpTime" timePicker :is24="false" autoApply>
                        <template #am-pm-button="{ toggle, value }">
                            <button @click="toggle">{{ value }}</button>
                        </template>
                    </Datepicker>
                </div>
            </div>

            <label for="" class="label">Drop-off Date</label>
            <div class="d-flex dropoffTime">
                <div class="form-group ml-2">
                <Datepicker v-model="form.dropOffDate" :enableTimePicker="false" :minDate="new Date()" autoApply />
                </div>
                <div class="form-group ml-2">
                    <Datepicker v-model="form.dropOffTime" timePicker :is24="false" autoApply>
                        <template #am-pm-button="{ toggle, value }">
                            <button @click="toggle">{{ value }}</button>
                        </template>
                    </Datepicker>
                </div>
            </div>
            <p id="errorDisplay" class="icon-error" v-if="dateError" >{{dateError}}</p>

            <div class="form-group pick-box">
                <label for="age" class="label">Your age</label>
                <select v-model="form.age" class="form-control" id="age" style="color: black !important;">
                    <!--<option value="18">18</option>-->
                    <!--<option value="19">19</option>-->
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="+25">+25</option>
                </select>
            </div>

            <div class="submit form-group">
                <button type="submit"  class="btn btn-secondary py-3 px-4">Get Estimate</button>
            </div>

        </form>
    </div>
    <general-modal
            :modalActive="modalActive"
            @close="closeModal"
            :headerName="'Delivery Options'">
        <div class="table">
            <div class="row header">
                <div class="cell location">Pickup &amp; return locations</div>
                <div class="cell price">Prices</div>
            </div>


            <div class="row">
                <div class="cell location">3975 S 1333 W, Salt Lake City, UT</div>
                <div class="cell price">Free</div>
            </div>

            <div class="row">
                <div class="cell location">Car Delivery to the Airport <a class="small" href="#car-delivery-airport" data-toggle="collapse" role="button" aria-expanded="false">More Info</a></div>
                <div class="cell price">$90</div>
            </div>
            <div class="row combined">

                <div class="cell combined" >
                    <div class="collapse" id="car-delivery-airport">
                        <div class="card card-body">
                            We will deliver the car to Salt Lake City airport and park it in the economy parking area.
                        </div>
                    </div>

                </div>
            </div>


            <div class="row">
                <div class="cell location">Private Shuttle Service<a class="small"  href="#customer-pickup-dropoff" data-toggle="collapse" role="button" aria-expanded="false"> More Info</a></div>
                <div class="cell price">$70</div>
            </div>
            <div class="row combined">
                <div class="cell combined" colspan="2">
                    <div class="collapse" id="customer-pickup-dropoff">
                        <div class="card card-body">
                            Our driver will provide airport pick-up, take you to the car location, and drop you off at the airport at the end of your trip.
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="cell location">Custom Location <a class="small" href="#custom-location" data-toggle="collapse" role="button" aria-expanded="false">More Info</a></div>
                <div class="cell price">$90</div>
            </div>
            <div class="row combined">
                <div class="cell combined" colspan="2">
                    <div class="collapse" id="custom-location">
                        <div class="card card-body">
                            Specify your preferred drop-off location within 10 miles of the initial pickup, and we'll bring the car to you.
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="card-body">
            <p>To request any of our car delivery options, please feel free to contact us: <a href="tel:+18015734248">+1 801 573 4248</a></p>
        </div>

    </general-modal>
</template>
<script >
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import moment from 'moment'
    import { ref, computed } from 'vue';
    import GeneralModal from './modal/GeneralModal.vue'
    import { useStore } from 'vuex'
export default {
    components: { Datepicker, GeneralModal },

    data() {
        const store = useStore();
        const location = '3975 S 1333 W , Salt Lake City, UT';
        let defaultAge = "+25";
        const pickUpDate = moment().add(3, 'days');
        const dropOffDate = moment().add(8, 'days');
        const pickUpTime = ref({
            hours: 9,
            minutes: 0
        });
        const dropOffTime = ref({
            hours: 9,
            minutes: 0
        });
        return {
            dateError: '',
            modalActive: false,
            form: {
                pickUpDate:  pickUpDate,
                dropOffDate: dropOffDate,
                pickUpTime: pickUpTime,
                dropOffTime: dropOffTime,
                location: location,
                age: defaultAge,
            },
            isEstimatePriceModalOpen: computed(() => store.state.isEstimatePriceModalOpen),
            isTooYoungToDrive: false,
        };
    },
    methods: {
        updateDropoffDate(date) {
            this.form.dropOffDate = moment(date).add(5, 'days')
        },
        validateInput() {
            if(moment(this.form.dropOffDate, 'DD/M/YYYY').isBefore(moment(this.form.pickUpDate, 'DD/M/YYYY'))) {
                this.dateError = "Pickup Date Can't be later than drop off Date";
                return false;
            }else if(this.form.dropOffDate == null || !this.form.pickUpDate == null) {
                this.dateError = "Invalid Drop-off Or Pick-up Date";
                return false;
            }

            this.dateError = '';
            return true;
        },
        async openEstimateModal() {

            if(this.validateInput()  && !this.isTooYoungToDrive) {
                const pickUpDate = moment(this.form.pickUpDate).format('MMM D, YYYY');
                const dropOffDate =  moment(this.form.dropOffDate).format('MMM D, YYYY');
                const pickupTime = moment(this.form.pickUpTime).format("HH:mm");
                const dropOffTime = moment(this.form.dropOffTime).format("HH:mm");
                let diff = moment(dropOffDate, 'MMM D, YYYY').diff(moment(pickUpDate, 'MMM D, YYYY'), 'days');

                // Apply discounts here

                // calculate youngdriver Fee
                let youngDriverFee = this.calculateAgeCharge(this.form.age) * diff;
                if(youngDriverFee > 500) youngDriverFee = 500;


                if(dropOffTime > pickupTime) {
                    diff += 1;
                }


                let reservation = {
                    pickupDate: pickUpDate,
                    pickupTime: pickupTime,
                    dropOffDate: dropOffDate,
                    dropOffTime: dropOffTime,
                    totalDays: diff,
                    youngDriverFee: youngDriverFee,
                }

                this.$store.dispatch('reservationData',  reservation)
                this.$store.dispatch('toggleEstimatePriceModal', true)
            }
        },

        calculateAgeCharge(age) {
            let youngDriverDaycharge = null;
//            if (age >= 18 && age <= 20) {
//                youngDriverDaycharge = 40;
//            } else

            if(age >= 20 && age <= 23) {
                youngDriverDaycharge = 30;
            } else if(age > 23 && age < 25) {
                youngDriverDaycharge = 20
            } else if(age >= 25) { youngDriverDaycharge = 0 }
            else { this.isTooYoungToDrive = true }
            return youngDriverDaycharge;
        },

        showDeliveryModal() {
            this.modalActive = true;
        },
        closeModal() {
            this.modalActive = false;
        }

    },

}
</script>
<style scoped lang="scss">

    .card-body {
        background-color: #ffeb3b ;
        padding: 10px;
        margin-bottom: 20px;
        p {
            font-size: 14px;
            padding: 12px;
            font-weight: 502;
            margin-bottom: -7px;
        }
    }




    .table {
        overflow-x: auto;
        font-size: 12px;
        display: table;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        margin-left: -12px;

        .row {
            display: table-row;
        }

        .header {
            font-weight: bold;
        }

        .cell {
            display: table-cell;
            padding: 5px;
            border: 1px solid #ccc;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .cell.price {
            width: 48px;
            text-align: center;
            font-size: 12px;
        }

        .cell.location {
            width: 245px;
            font-size: 14px;
        }

        .row.combined {
            display: table-row;

            .cell.combined {
                display: block;
                border: none;
                padding: 0px;
                text-align: center;
                width: 120%;

                }
            }
        }






    .request-form {
        padding: 30px;
        color: #fff;
        background: #1089ff !important;
        color: white;

        h2 {
            font-size: 22px;
            font-weight: 600 !important;
        }
        span {
            color: #ff992e;
        }
    }

    .pickupTime {
        margin-left: 8px;
    }
    .dropoffTime {
        margin-right: 8px;
    }
    .pick-box {
        margin: 8px;
    }
    .btn-secondary {
        width: 96%;
        height: 42px;
        background: #ff992e !important;
        border: 1px solid #ff992e !important;
        color: #fff !important;
        padding-top: 7px !important;
    }
    #errorDisplay {
        color: #f00 !important;
        font-weight: 600;
        font-size: 11px;
        margin-top: -12px;
    }
    @media screen and (max-width: 830px) {
        .col-md-4 {
            max-width: 100% !important;
            .request-form {
                width: 510px;
            }
        }

    }
</style>