var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Car = (function () {
    function Car() {
    }
    return Car;
}());
export { Car };
var Cars = (function (_super) {
    __extends(Cars, _super);
    function Cars() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Cars;
}(Array));
export { Cars };
;
var Estimate = (function () {
    function Estimate() {
    }
    return Estimate;
}());
export { Estimate };
var Reservation = (function () {
    function Reservation() {
    }
    return Reservation;
}());
export { Reservation };
var Reservations = (function (_super) {
    __extends(Reservations, _super);
    function Reservations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Reservations;
}(Array));
export { Reservations };
