<template>
    <div class="container">
        <div id="navbar">
                <nav class="navbar navbar-expand-lg navbar-light sticky" >
                    <router-link class="nav-link navbar-brand"  to="/">Car<span>SpeedyRental</span></router-link>
                </nav>
        </div>
            <div class="row-cols-md-4">
                <form @submit.prevent="loginFucntion">
                    <!-- Email input -->
                    <div class="form-outline mb-4">
                        <input type="email" v-model="form.email" class="form-control"  placeholder="Email address"/>
                        <div class="form-error" v-if="v$.form.email.$error" >
                            <small v-if="v$.form.email.required.$invalid"> Email Is Required</small>
                            <small v-if="v$.form.email.email.$invalid" > Not Valid Email</small>
                        </div>
                    </div>

                    <!-- Password input -->
                    <div class="form-outline mb-4">
                        <input type="password" v-model="form.password" id="form2Example2" class="form-control" placeholder="Password"/>
                        <div class="form-error" v-if="v$.form.password.$error">
                            <small v-if="v$.form.password.required.$invalid"> Password Is Required</small>
                        </div>
                    </div>

                    <!-- 2 column grid layout for inline styling -->
                    <div class="row mb-4">
                        <div class="col d-flex">
                            <!-- Checkbox -->
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="form2Example31" checked />
                                <label class="form-check-label" for="form2Example31"> Remember me </label>
                            </div>
                        </div>

                        <div class="col">
                            <!-- Simple link -->
                            <a href="#"  @click="forgotPassword">Forgot password?</a>
                        </div>
                    </div>

                    <!-- Submit button -->
                    <button type="submit" class="btn btn-primary btn-block mb-4">Sign in</button>

                    <!-- Register buttons -->
                    <div class="text-center">
                        <p>Not a member? <router-link  to="/register">Register</router-link></p>

                    </div>
                </form>
            </div>
        </div>


</template>

<script>
    import appHeader from '../src/components/appHeader.vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';

    export default {
        name: 'Login',

        data() {
            const email = null;
            const password = null;

            return {
                v$: useVuelidate(),
                form: {
                    email: email,
                    password: password,
                },
            }
        },

        validations () {
            return {
                form: {
                    email: {
                        required,
                        email
                    },
                    password: {
                        required,
                    },
                }
            }
        },



        components: {
            appHeader,
        },

        methods: {
            loginFucntion() {
                this.v$.form.$validate();
                const user = {
                    email: this.form.email,
                    password: this.form.password,
                }

                if(!this.v$.form.$error) {
                    this.$store.dispatch('loginUser', user).then(
                        response => {
                            if(response.data.status === 'success') {
                                this.$router.push('/');
                            } else {
                                this.$store.dispatch('toastMessage',  {type: 'error', msg: "Incorrect Email or Password"})
                            }
                        }
                    )
                }

            },

            forgotPassword() {
                alert("In order to reset your password please contact support on: support@carspeedyrental.com include your First, Last Name, Phone used for registration, and your email")
            },
        },



    }

</script>

<style lang="scss" scoped>
    form {
        margin: auto;
        margin-top: 100px;
        padding: 10px;

        .col{
            padding-right: 0px;
        }

        .form-outline {
            .form-error {
                small {
                    display: block;
                    color: #f22f41;
                    font-size: 12px;
                }
            }
        }
    }

    .navbar-brand {
        font-weight: 800;
        font-size: 20px;
        text-transform: uppercase;
        /*color: #fff !important;*/

        span {
            color: #ff992e;
            font-weight: 800;
            font-size: 20px;
        }
    }

    .navbar-nav {
        margin-left: auto;
    }
    .navbar-sticky {
        position: fixed;
        z-index: 1;
        background-color: white;
        left: 0;
        right: 0;
        transition: width 10s;
        color: white;

    }

</style>