<template>
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <h3>Change Password</h3>
                <form @submit.prevent="changePassword">
                    <div class="form-outline mb-4">
                        <input type="password" v-model="form.oldPassword"  class="form-control" placeholder="Old Password" />
                        <div class="form-error" v-if="v$.form.oldPassword.$error">
                            <small v-if="v$.form.oldPassword.required.$invalid">Old Password Is Required</small>
                            <small v-if="v$.form.oldPassword.minLength.$invalid || v$.form.oldPassword.maxLength.$invalid">
                                Password Should Be Between 6 To 12 Characters</small>
                            <small v-if="v$.form.oldPassword.mustInclude.$invalid">
                                Must include at least one number (@$!%#?&) Allowed</small>
                        </div>
                    </div>


                    <div class="form-outline mb-4">
                        <input type="password" v-model="form.newPassword"  class="form-control" placeholder="New Password" />
                        <div class="form-error" v-if="v$.form.newPassword.$error">
                            <small v-if="v$.form.newPassword.required.$invalid"> New Password Is Required</small>
                            <small v-if="v$.form.newPassword.minLength.$invalid || v$.form.newPassword.maxLength.$invalid">
                                Password Should Be Between 6 To 12 Characters</small>
                            <small v-if="v$.form.newPassword.mustInclude.$invalid">
                                Must include at least one number (@$!%#?&) Allowed</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <input type="password" v-model="form.confirmPassword"  class="form-control" placeholder="Confirm Password" />
                        <div class="form-error" v-if="v$.form.confirmPassword.$error">
                            <small v-if="v$.form.confirmPassword.required.$invalid">Confirmation Password Is Required</small>
                            <small v-if="v$.form.confirmPassword.minLength.$invalid || v$.form.confirmPassword.maxLength.$invalid">
                                Password Should Be Between 6 To 12 Characters</small>
                            <small v-if="v$.form.confirmPassword.sameAs.$invalid"> Passwords Don't Match</small>
                        </div>
                    </div>
                    <!-- Submit button -->
                    <button type="submit" class="btn btn-primary btn-block mb-4">Apply</button>
                </form>

            </div>

        </div>
    </div>
</template>

<script>
    import { useVuelidate } from '@vuelidate/core'
    import appHeader2 from '../src/components/AppHeader2.vue';
    import appFooter from '../src/components/appFooter.vue';
    import { RouterLink, RouterView } from 'vue-router';
    import { required, minLength, maxLength, between, email, sameAs, numeric } from '@vuelidate/validators';


    //must have least  6 characters and least one number special chars allowed.
    const mustInclude = (value) => {
        const regex = new RegExp("^(?=.*[A-Za-z])(?=.*?[0-9])(?=.*\\d)[A-Za-z\\d$@$!%#?&]{6,}$");
        return regex.test(value);
    }

    export default {
        name: 'MyAccount',
        data() {

            return {
                v$: useVuelidate(),
                form: {
                    newPassword: null,
                    confirmPassword: null,
                    oldPassword: null,
                },
            }
        },

        components: {
            appHeader2,
            appFooter,
        },

        methods: {
            changePassword() {
                this.v$.form.$validate();
                const passwordSet = {
                    newPassword: this.form.newPassword,
                    confirmPassword: this.form.confirmPassword,
                    oldPassword: this.form.oldPassword,
                }

                if(!this.v$.form.$error) {
                    this.$store.dispatch('changePassword', passwordSet)
                }

            }
        },

        validations () {
            return {
                form: {
                    newPassword: {
                        required,
                        mustInclude,
                        minLength: minLength(6),
                        maxLength: maxLength(12),
                    },
                    confirmPassword: {
                        required,
                        minLength: minLength(6),
                        maxLength: maxLength(12),
                        sameAs: sameAs(this.form.newPassword)
                    },
                    oldPassword: {
                        required,
                    }
                }
            }
        },

    }

</script>

<style lang="scss" scoped>

    .col-md-5 {
        margin: auto;
    }

</style>