var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import Vuex from "vuex";
import state from './state';
import mutations from './mutations';
import router from '../router';
var baseUrl = 'https://localhost/speedyrental/backend/index.php?';
var baseUrl1 = 'https://localhost/speedyrental/backend/protected.php?';
// swhich base url for backend request
if (window.location.origin == "https://carspeedyrental.com" || window.location.origin == "http://carspeedyrental.com" +
    "" || window.location.origin == "https://www.carspeedyrental.com" || window.location.origin == "http://www.carspeedyrental.com") {
    baseUrl = '../../../backend/index.php?';
    baseUrl1 = '../../../backend/protected.php?';
}
else if (window.location.origin == 'http://10.0.0.145:8080' || window.location.origin == 'https://10.0.0.145:8080') {
    baseUrl = 'http://10.0.0.145/speedyrental/backend/index.php?';
    baseUrl1 = 'http://10.0.0.145/speedyrental/backend/protected.php?';
}
var store = new Vuex.Store({
    state: state,
    mutations: mutations,
    actions: {
        toastMessage: function (_a, payload) {
            var commit = _a.commit;
            commit('ADD_TOAST_MESSAGE', payload);
        },
        fetchCars: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var cars, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.get(baseUrl + "action=getCars")];
                        case 1:
                            cars = _a.sent();
                            commit('setCars', cars.data);
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            console.log(e_1);
                            throw new Error(e_1);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        fetchPriceManipulations: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var priceManipulations, e_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.get(baseUrl + "action=getPriceManipulations")];
                        case 1:
                            priceManipulations = _a.sent();
                            commit('setPriceManipulations', priceManipulations.data);
                            return [3 /*break*/, 3];
                        case 2:
                            e_2 = _a.sent();
                            throw new Error(e_2);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        fetchInsurancePrices: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var insurancePrices, e_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.get(baseUrl + "action=getInsurancePrices")];
                        case 1:
                            insurancePrices = _a.sent();
                            commit('setInsurancePrices', insurancePrices.data);
                            return [3 /*break*/, 3];
                        case 2:
                            e_3 = _a.sent();
                            console.log(e_3);
                            throw new Error(e_3);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        fetchReservations: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var reservations, e_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.get(baseUrl1 + "action=getReservations")];
                        case 1:
                            reservations = _a.sent();
                            commit('setReservations', reservations.data);
                            return [3 /*break*/, 3];
                        case 2:
                            e_4 = _a.sent();
                            throw new Error(e_4);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        getReservationsByEmail: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var formData, result, e_5;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append('email', payload);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=getReservationsByEmail",
                                    method: "POST",
                                    data: formData,
                                })];
                        case 2:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 3:
                            e_5 = _a.sent();
                            throw new Error(e_5);
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        getReservation: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var formData, result, e_6;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append('charge_id', payload);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=getReservation",
                                    method: "POST",
                                    data: formData,
                                })];
                        case 2:
                            result = _a.sent();
                            if (result.data.status === 'success' && result.data.reservation.charge_id === router.currentRoute._value.params.id) {
                                commit('setPreCheckInFormReservation', result.data.reservation);
                            }
                            else {
                                router.push('/errorPage');
                                commit('ADD_TOAST_MESSAGE', { type: 'error', msg: "Link Is Invalid Or Expired" });
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            e_6 = _a.sent();
                            throw new Error(e_6);
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        getReservationForOnlineCheckIn: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var formData, result, e_7;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append('charge_id', payload);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=getReservation",
                                    method: "POST",
                                    data: formData,
                                })];
                        case 2:
                            result = _a.sent();
                            if (result.data.status === 'success' && result.data.reservation.charge_id) {
                                commit('setPreCheckInFormReservation', result.data.reservation);
                            }
                            else {
                                router.push('/errorPage');
                                commit('ADD_TOAST_MESSAGE', { type: 'error', msg: "Link Is Invalid Or Expired" });
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            e_7 = _a.sent();
                            throw new Error(e_7);
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        getPrecheckinInfo: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var formData, result, e_8;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append('charge_id', payload);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=getPreCheckinData",
                                    method: "POST",
                                    data: formData,
                                })];
                        case 2:
                            result = _a.sent();
                            if (result.data.status === 'success') {
                                commit('ADD_TOAST_MESSAGE', { type: 'success', msg: "Pre-Checkin Form is completed For This Reservation" });
                                return [2 /*return*/, result.data.preCheckinData];
                            }
                            else {
                                commit('ADD_TOAST_MESSAGE', { type: 'error', msg: result.data.preCheckinData });
                                return [2 /*return*/, null];
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            e_8 = _a.sent();
                            throw new Error(e_8);
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        submitPreCheckInForm: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_9;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=submitPreCheckInInfo",
                                    method: "POST",
                                    data: payload
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_9 = _a.sent();
                            throw new Error(e_9);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        toggleEstimatePriceModal: function (_a, payload) {
            var commit = _a.commit;
            commit('setEstimateModalToggle', payload);
        },
        reservationData: function (_a, payload) {
            var commit = _a.commit;
            commit('setPossibleReservationData', payload);
        },
        addCar: function (_a, payload) {
            var commit = _a.commit, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var formData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append('name', payload.name);
                            formData.append('vin', payload.vin);
                            formData.append('description', payload.description);
                            formData.append('price', payload.price);
                            formData.append('$prePaidRefueling', payload.PrePaidRefueling);
                            formData.append('mpg', payload.mpg);
                            formData.append('odo', payload.odo);
                            formData.append('year', payload.year);
                            formData.append('isRunning', payload.isRunning);
                            formData.append('isCleaned', payload.isCleaned);
                            formData.append('picture', payload.picture);
                            return [4 /*yield*/, axios({
                                    url: baseUrl1 + "action=addCar",
                                    method: "POST",
                                    data: formData,
                                    headers: {
                                        // 'Content-Type': 'application/x-www-form-urlencoded',
                                        'Authorization': "Bearer " + localStorage.getItem("jwt")
                                    },
                                    onUploadProgress: function (uploadEvent) {
                                        console.log('upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%');
                                    }
                                }).then(function (response) {
                                    if (response.data === 'success') {
                                        dispatch('fetchCars');
                                        commit('ADD_TOAST_MESSAGE', { type: 'success', msg: "Car Added Successfully" });
                                    }
                                }).catch(function (error) {
                                    commit('ADD_TOAST_MESSAGE', { type: 'error', msg: error });
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateCar: function (_a, payload) {
            var commit = _a.commit, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var formData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append('id', payload.id);
                            formData.append('name', payload.name);
                            formData.append('description', payload.description);
                            formData.append('price', payload.price);
                            formData.append('mpg', payload.mpg);
                            formData.append('odo', payload.odo);
                            formData.append('year', payload.year);
                            formData.append('isRunning', payload.isRunning);
                            formData.append('isCleaned', payload.isCleaned);
                            formData.append('picture', payload.picture);
                            return [4 /*yield*/, axios({
                                    url: baseUrl1 + "action=editCar",
                                    method: "POST",
                                    data: formData,
                                    headers: {
                                        'Authorization': "Bearer " + localStorage.getItem("jwt")
                                    },
                                    onUploadProgress: function (uploadEvent) {
                                        console.log('upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%');
                                    }
                                }).then(function (response) {
                                    if (response.data === 'success') {
                                        dispatch('fetchCars');
                                        commit('ADD_TOAST_MESSAGE', { type: 'success', msg: "Car Updated Successfully" });
                                    }
                                }).catch(function (error) {
                                    commit('ADD_TOAST_MESSAGE', { type: 'error', msg: error });
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteCar: function (_a, payload) {
            var commit = _a.commit, dispatch = _a.dispatch;
            var formData = new FormData();
            formData.append('id', payload);
            try {
                var result = axios({
                    url: baseUrl1 + "action=deleteCar",
                    method: "POST",
                    data: formData,
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem("jwt")
                    },
                }).then(function () {
                    dispatch('fetchCars');
                    commit('ADD_TOAST_MESSAGE', { type: 'success', msg: "Car Deleted Successfully" });
                });
            }
            catch (e) {
                commit('ADD_TOAST_MESSAGE', { type: 'error', msg: e });
            }
        },
        registerUser: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var register, e_10;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.post(baseUrl + "action=register", {
                                    firstName: payload.firstName,
                                    lastName: payload.lastName,
                                    email: payload.email,
                                    phone: payload.phone,
                                    phoneCountryCode: payload.phoneCountryCode,
                                    password: window.btoa(payload.password),
                                    passwordConfirm: window.btoa(payload.passwordConfirm),
                                })];
                        case 1:
                            register = _a.sent();
                            return [2 /*return*/, register];
                        case 2:
                            e_10 = _a.sent();
                            throw new Error(e_10);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        loginUser: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var response, e_11;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.post(baseUrl + "action=login", {
                                    email: payload.email,
                                    password: window.btoa(payload.password),
                                })];
                        case 1:
                            response = _a.sent();
                            commit('setAuthUser', response.data.user);
                            localStorage.setItem('jwt', response.data.jwt);
                            return [2 /*return*/, response];
                        case 2:
                            e_11 = _a.sent();
                            throw new Error(e_11);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        logOutUser: function (_a) {
            var commit = _a.commit;
            commit('setAuthUser', {});
            localStorage.setItem('jwt', '');
        },
        changePassword: function (_a, payload) {
            var commit = _a.commit, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var formData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append('confirmPassword', window.btoa(payload.confirmPassword));
                            formData.append('newPassword', window.btoa(payload.newPassword));
                            formData.append('oldPassword', window.btoa(payload.oldPassword));
                            return [4 /*yield*/, axios({
                                    url: baseUrl1 + "action=resetPassword",
                                    method: "POST",
                                    data: formData,
                                    headers: {
                                        'Authorization': "Bearer " + localStorage.getItem("jwt")
                                    },
                                }).then(function (response) {
                                    // check if we have data and it is a code
                                    if (response.data === 'success') {
                                        dispatch('logOutUser');
                                        commit('ADD_TOAST_MESSAGE', { type: 'success', msg: "Password Changed" });
                                        router.push('/login');
                                    }
                                    else if (response.data === "old_password_bad") {
                                        commit('ADD_TOAST_MESSAGE', { type: 'warning', msg: "Current Password is Incorrect" });
                                    }
                                    else {
                                        commit('ADD_TOAST_MESSAGE', { type: 'error', msg: "Error  Try Again Later or Contact Support" });
                                    }
                                }).catch(function (error) {
                                    commit('ADD_TOAST_MESSAGE', { type: 'error', msg: error });
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        // Authenticate user on refresh
        getAuthUser: function (_a) {
            var commit = _a.commit, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var response, e_12;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!localStorage.getItem("jwt")) return [3 /*break*/, 4];
                            axios.defaults.headers.common["Authorization"] =
                                "Bearer " + localStorage.getItem("jwt");
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, axios.post(baseUrl1 + "action=auth")];
                        case 2:
                            response = _a.sent();
                            commit('setAuthUser', response.data.user);
                            return [2 /*return*/, response];
                        case 3:
                            e_12 = _a.sent();
                            dispatch('logOutUser');
                            console.error(new Error(e_12));
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        verifyEmail: function (_a, payload) {
            var commit = _a.commit, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var formData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            commit('setLoader', true);
                            formData.append('email', payload);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=Verification",
                                    method: "POST",
                                    data: formData
                                }).then(function (response) {
                                    // check if we have data and it is a code
                                    if (response.data && typeof response.data === 'number') {
                                        commit('setVerificationCode', response.data);
                                        commit('setLoader', false);
                                        commit('ADD_TOAST_MESSAGE', { type: 'success', msg: "Verification Code Was Sent Successfully" });
                                    }
                                    else {
                                        commit('ADD_TOAST_MESSAGE', { type: 'warning', msg: response.data });
                                        commit('setLoader', false);
                                    }
                                }).catch(function (error) {
                                    commit('ADD_TOAST_MESSAGE', { type: 'error', msg: error });
                                    commit('setLoader', false);
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        submitStripeReservation: function (_a, payload) {
            var commit = _a.commit, dispatch = _a.dispatch, state = _a.state;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_13;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=processReservation",
                                    method: "POST",
                                    data: payload,
                                    onUploadProgress: function (uploadEvent) {
                                        console.log('upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%');
                                    }
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_13 = _a.sent();
                            throw new Error(e_13);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        updateReservation: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_14;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl1 + "action=updateReservation",
                                    method: "POST",
                                    data: payload
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_14 = _a.sent();
                            throw new Error(e_14);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        confirmReservation: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_15;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl1 + "action=confirmReservation",
                                    method: "POST",
                                    data: payload
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_15 = _a.sent();
                            throw new Error(e_15);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        cancelUnconfirmedReservation: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_16;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl1 + "action=cancelUnconfirmedReservation",
                                    method: "POST",
                                    data: payload
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_16 = _a.sent();
                            throw new Error(e_16);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        submitContract: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_17;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=sendContract",
                                    method: "POST",
                                    data: payload
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_17 = _a.sent();
                            throw new Error(e_17);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        deleteAndResendContract: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_18;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=deleteAndResendContract",
                                    method: "POST",
                                    data: payload
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_18 = _a.sent();
                            throw new Error(e_18);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        chargeCustomerAdditionalFee: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_19;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl1 + "action=chargeCustomerByStripeCustomerId",
                                    method: "POST",
                                    data: payload
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_19 = _a.sent();
                            throw new Error(e_19);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        findPromoCode: function (_a, payload) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var result, e_20;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios({
                                    url: baseUrl + "action=findPromoCode",
                                    method: "POST",
                                    data: payload
                                })];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_20 = _a.sent();
                            throw new Error(e_20);
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
    },
});
export default store;
