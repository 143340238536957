<template>
    <div class="container">
        <div id="navbar">
                <nav class="navbar navbar-expand-lg navbar-light sticky" >
                    <router-link class="nav-link navbar-brand"  to="/">Car<span>SpeedyRental</span></router-link>
                </nav>
        </div>
        
            <div class="row-cols-md-4">
                <form @submit.prevent="sendVerification">


                    <!-- first name input -->
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.firstName"  class="form-control" placeholder="First Name"/>
                        <div class="form-error" v-if="v$.form.firstName.$error">
                            <small v-if="v$.form.firstName.required.$invalid" >Invalid First Name</small>
                        </div>
                    </div>

                    <!-- last name input -->
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.lastName"  class="form-control" placeholder="Last Name" />
                        <div class="form-error" v-if="v$.form.lastName.$error">
                            <small v-if="v$.form.lastName.required.$invalid" > Invalid Last Name</small>
                        </div>
                    </div>


                    <!--&lt;!&ndash; phone input &ndash;&gt;-->
                    <!--<div class="form-outline mb-4 phone-div">-->
                        <!--<span>+1 </span><input type="text" v-model="form.phone"  class="form-control" id="phone" placeholder="Phone Number: (555) 555-5555" />-->
                        <!--<div class="form-error" v-if="v$.form.phone.$error" >-->
                            <!--<small   v-if="v$.form.phone.required.$invalid"> Phone Is Required</small>-->
                            <!--<small  v-if="v$.form.phone.numeric.$invalid" > Only Numbers Allowed</small>-->
                            <!--<small   v-if="v$.form.phone.minLength.$invalid || v$.form.phone.maxLength.$invalid">-->
                                <!--Must Be Valid Phone US Phone Number</small>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!-- phone input -->
                    <div class="form-outline mb-4">
                        <tel-input-field  v-model="form.phone" :identifier="'userPhone'" :inputPlaceHolder="'Phone # (801)5657584'" @onChange="getUserPhoneNumber"></tel-input-field>
                        <div class="form-error" v-if="v$.form.phone.$error" >
                            <small   v-if="v$.form.phone.required.$invalid"> Phone Is Required</small>
                            <small  v-if="v$.form.phone.allowOnlyNumBrackets.$invalid" > Only Numbers Allowed</small>
                            <small   v-if="v$.form.phone.minLength.$invalid || v$.form.phone.maxLength.$invalid">
                                Must Be Valid Phone Number</small>
                        </div>
                    </div>





                    <!-- Email input -->
                    <div class="form-outline mb-4">
                        <input type="text" v-model="form.email" id="email" class="form-control" placeholder="Enter Email"/>
                        <div class="form-error" v-if="v$.form.email.$error" >
                            <small v-if="v$.form.email.required.$invalid"> Email Is Required</small>
                            <small v-if="v$.form.email.email.$invalid" > Not Valid Email</small>
                        </div>
                    </div>

                    <!-- Password input -->
                    <div class="form-outline mb-4">
                        <input type="password" v-model="form.password"  class="form-control" placeholder="Password" />
                        <div class="form-error" v-if="v$.form.password.$error">
                            <small v-if="v$.form.password.required.$invalid"> Password Is Required</small>
                            <small v-if="v$.form.password.minLength.$invalid || v$.form.password.maxLength.$invalid">
                                Password Should Be Between 6 To 12 Characters</small>
                            <small v-if="v$.form.password.mustInclude.$invalid">
                                Must include at least one number (@$!%#?&) Allowed</small>
                        </div>
                    </div>

                    <div class="form-outline mb-4">
                        <input type="password" v-model="form.passwordConfirm"  class="form-control" placeholder="Confirm Password" />
                        <div class="form-error" v-if="v$.form.passwordConfirm.$error">
                            <small v-if="v$.form.passwordConfirm.required.$invalid"> Password Is Required</small>
                            <small v-if="v$.form.passwordConfirm.minLength.$invalid || v$.form.password.maxLength.$invalid">
                                Password Should Be Between 6 To 12 Characters</small>
                            <small v-if="v$.form.passwordConfirm.sameAs.$invalid"> Passwords Don't Match</small>
                        </div>
                    </div>

                    <!-- 2 column grid layout for inline styling -->
                    <div class="row mb-4">

                        <div class="col">
                            <!-- Simple link -->
                            <a href="#!">Forgot password?</a>
                        </div>
                    </div>

                    <!-- Submit button -->
                    <button type="submit" class="btn btn-primary btn-block mb-4">Register</button>

                </form>
            </div>
        </div>

    <modal :modalActive="showModal" :headerName="'Verify Your Email'" @close="closeModal" :showModalSelectError="false">
        <div class="loader-div" v-if="this.$store.state.showLoader">
            <div  class="loader"></div>
        </div>

        <div class="row-cols-md-12 form-outline">
            <p>Verification Code Has Been Sent To: {{form.email}} <br/> Check Your inbox or Spam Folder or Resend Code</p>

            <input type="number" v-model="verificationCode"  class="form-control verification-input" placeholder="Verification Code" />
            <div class="form-error" v-if="v$.verificationCode.$error">
                <small v-if="v$.verificationCode.$invalid" >Verification Code is not Valid</small>
            </div>
            <button type="submit" @click="verifyCode" class="btn btn-primary btn-block mb-4">Verify</button>
        </div>
    </modal>





</template>

<script>
    import appHeader from '../src/components/appHeader.vue'
    import { useVuelidate } from '@vuelidate/core'
    import { required, minLength, maxLength, between, email, sameAs, numeric } from '@vuelidate/validators'
    import Modal from '../src/components/modal/GeneralModal.vue';
    import telInputField from '../src/components/tel-input-field.vue';

    //must have least  6 characters and least one number special chars allowed.
    const mustInclude = (value) => {
        const regex = new RegExp("^(?=.*[A-Za-z])(?=.*?[0-9])(?=.*\\d)[A-Za-z\\d$@$!%#?&]{6,}$");
        return regex.test(value);
    }

    const allowOnlyNumBrackets = (value) => {
        // regex only valid if first three digit is surrounded by round brackets and has only numbers
        const regex = /^\(\d{3}\)[\d()]*$/;
        return regex.test(value);
    };

    export default {
        name: 'Registration',


        data() {
            return {
                v$: useVuelidate(),
                form: {
                    firstName: null,
                    lastName: null,
                    phone: null,
                    phoneCountryCode: null,
                    email: null,
                    password: null,
                    passwordConfirm: null,
                },
                showModal: false,
                verificationCode: null,
            }

        },

        validations () {
            return {
                form: {
                    firstName: { required }, // Matches this.firstName
                    lastName: { required }, // Matches this.lastName
                    phone: { required, minLength: minLength(10), maxLength: maxLength(15), allowOnlyNumBrackets },
                    email: {
                        required,
                        email
                    },
                    password: {
                        required,
                        mustInclude,
                        minLength: minLength(6),
                        maxLength: maxLength(12),
                    },
                    passwordConfirm: {
                        required,
                        minLength: minLength(6),
                        maxLength: maxLength(12),
                        sameAs: sameAs(this.form.password)
                    },
                },
                verificationCode : {
                    required
                }
            }

        },

        components: {
            appHeader,
            Modal,
            telInputField,

        },

        methods: {
            closeModal() {
                this.showModal = !this.showModal;
            },
            getUserPhoneNumber(value) {
                this.form.phone = value.number;
                this.form.phoneCountryCode = value.countryCode;
            },


            sendVerification() {
                this.v$.form.$validate();
                if(!this.v$.form.$error) {
                    this.$store.dispatch('verifyEmail', this.form.email);
                    this.showModal = true;
                }
            },

            verifyCode() {
                this.v$.verificationCode.$validate();
                if(this.verificationCode === this.$store.state.verificationCode && !this.v$.verificationCode.$error) {
//                    console.log(this.verificationCode, this.$store.state.verificationCode, this.v$.verificationCode.$error)
                    this.closeModal();
                    this.registerFucntion();
                } else {
                    this.$store.dispatch('toastMessage',  {type: 'error', msg: "Verification Code Doesn't Match"})
                }

            },

            registerFucntion() {
                if(this.verificationCode === this.$store.state.verificationCode && !this.v$.form.$error) {
                    const user = {
                        firstName: this.form.firstName,
                        lastName: this.form.lastName,
                        phone: this.form.phone,
                        phoneCountryCode: this.form.phoneCountryCode,
                        email: this.form.email,
                        password: this.form.password,
                        passwordConfirm: this.form.passwordConfirm,
                    }
                    //reset verification code
                    this.$store.state.verificationCode = null
                    this.$store.dispatch('registerUser', user).then(
                    response => {
                        if(response.data === 'success') {
                            this.$router.push('/login');
                            this.$store.dispatch('toastMessage',  {type: 'success', msg: "Welcome to Speedyrental"})
                        } else {
                            this.$store.dispatch('toastMessage',  {type: 'error', msg: "Error  Try Again Later or Contact Support"})
                        }
                    }
                )}
            },
        },

    }

</script>

<style lang="scss" scoped>
    form {
        margin: auto;
        margin-top: 100px;
        padding: 10px;

        .col{
            padding-right: 0px;
        }
        .phone-div {

            #phone {
                width: 91%;
                display: inline;
            }
        }
    }

    .navbar-brand {
        font-weight: 800;
        font-size: 20px;
        text-transform: uppercase;
        /*color: #fff !important;*/

        span {
            color: #ff992e;
            font-weight: 800;
            font-size: 20px;
        }
    }

    .navbar-nav {
        margin-left: auto;
    }
    .navbar-sticky {
        position: fixed;
        z-index: 1;
        background-color: white;
        left: 0;
        right: 0;
        transition: width 10s;
        color: white;


    }

    .verification-input {
        margin-bottom: 20px;
    }

    .loader-div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.7);



        .loader {
            position: absolute;
            z-index: 2;
            border: 8px solid #f3f3f3; /* Light grey */
            border-top: 8px solid #ff992e; /* Blue */
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 2s linear infinite;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    }



</style>