<template>
    <div class="text-center btn-action-group" style="padding:20px;">
        <button type="button" class="btn btn-info btn_print" @click="printInvoice">Print</button>
        <button type="button" class="btn btn-info btn_print" @click="sendContract">Send</button>
        <!--<input type="button" id="rep" value="Print" class="btn btn-info btn_print">-->
    </div>
    <div class="row">
        <div class="container">
            <div class="col-12 doc-content " style="margin-bottom: 200px;" id="doc-content">

                <div style="position: absolute;
                            opacity: 0.35;
                            font-size: 32px;
                            letter-spacing: 8px;
                            -webkit-transform: rotate(-35deg);
                            top: 16%;
                            left: 4%;
                            font-weight: 700;">
                    Not Valid Without Receipt
                </div>
                <div style="position: absolute;
                            opacity: 0.35;
                            font-size: 32px;
                            letter-spacing: 8px;
                            -webkit-transform: rotate(-35deg);
                            top: 50%;
                            left: 4%;
                            font-weight: 700;">
                    Not Valid Without Receipt
                </div>
                <div style="position: absolute;
                            opacity: 0.35;
                            font-size: 32px;
                            letter-spacing: 8px;
                            -webkit-transform: rotate(-35deg);
                            top: 80%;
                            left: 4%;
                            font-weight: 700;">
                    Not Valid Without Receipt
                </div>

                <p class="title" style="font-weight: 400; vertical-align: baseline;font-size: 18pt;margin-bottom: -5px; text-align: center;">Car Rental Contract</p>
                <p class="subheader" style="font-size: 14pt; text-align: center;">SpeedyRental LLC</p>

                <div style="text-align: left; font-size: 13px;">
                    <div class="car-info">
                        <div>Vehicle Pickup/Dropoff Location: 1333 Pharaoh Cir, SLC UT 84123</div>
                        <div><span>Vehicle Check Out Date: </span> {{ this.formatPickupDate }}</div>
                        <div><span>Vehicle is Due Back:</span> {{this.formatDropoffDate}}</div>
                        <div>Odometer Reading: ___{{this.reservation.startMileage}}___</div>
                        <div>Miles Included: __{{this.reservation.milesIncluded}}___</div>
                        <div>Rental Car: __{{this.getCarName()}} ____</div>
                        <div>Vin #: ___{{this.getVin()}}___</div>
                        <div>Daily Rate: _${{ this.calculateDaylyPrice }}</div>
                        <div>Refundable Deposit:__ ${{ this.reservation.deposit }} __</div>
                        <div>Base Rate:__ ${{ this.reservation.carBaseRate }} __</div>
                        <div>Taxes&Fees:__ ${{ this.reservation.totalTaxAndFees }} __</div>
                        <div>Car Rental Cost(including taxes & fees):__ ${{ this.reservation.totalPriceWithoutInsurance?.toFixed(2)}} __</div>
                        <div>Insurance: __${{this.reservation.ldwCost +  this.reservation.rcliCost}}__</div>
                        <div>Total(including Insurance): __${{ this.reservation.totalPriceWithInsurance?.toFixed(2) }}__</div>
                        <p class="c13"><span class="c0">Gas Tank Reading: &nbsp;[X ] Full, &nbsp;[ &nbsp;] &frac34;, &nbsp;[ &nbsp;]&frac12;, &nbsp;[ &nbsp;]&frac14; </span></p>
                    </div>
                    <div class="renter-info">
                        <div>Car Renter: ___{{this.reservation.userFirstName +', '+ this.reservation.userLastName}}___</div>
                        <div>Home Address: ___{{ this.preCheckinInfo.currentAddress }}_______</div>
                        <div>Driver’s License #:____{{ this.preCheckinInfo.driverLicenseNumber }}_____ </div>
                        <div>Date of Birth:____{{ this.formatPrimaryDriverDob }}_____</div>
                        <div>Local Address:________{{ this.preCheckinInfo.secondaryAddress }}________</div>
                        <div v-if="showInsuranceInfo">Auto Insurance Company:__{{ this.preCheckinInfo.insuranceCompany}}___ Policy #:__{{this.preCheckinInfo.insurancePolicyNumber}}_____ </div>
                        <div>Payment Type:
                            <span v-if="this.reservation.funding_type === 'credit'">Credit Card</span>
                            <span  v-else-if="this.reservation.funding_type === 'debit'">Debit Card</span>
                            <span  v-else >Other</span>
                        </div>
                        <!--<div>Card Number:___{{this.preCheckinInfo.cardNumber}}___  Name on the Card: ___{{this.preCheckinInfo.nameOnCard}}___</div>-->
                        <!--<div>Expiration Date: __{{this.formatCardExpirationDate}}__ </div>-->


                        <div v-if="this.preCheckinInfo.isAdditionalDriver">
                            <div class="separate" style="margin: 25px 0px 0px 0px; font-weight: 800;">Additional Driver</div>
                            <div>Name: ____{{ this.additionalDriver.additionalDriverFirstName }} {{ this.additionalDriver.additionalDriverLastName }}____</div>
                            <div>Home Address: ______{{ this.additionalDriver.additionalDriverAddress }}________</div>
                            <div>Driver’s License #:___{{ this.additionalDriver.additionalDriverLicenseNumber }}___</div>
                            <div>Date of Birth:__{{  this.formattAdditionalDriverDob  }}__</div>
                        </div>
                        <div v-else>
                            <div class="separate" style="margin: 25px 0px 0px 0px; font-weight: 800;">Additional Driver</div>
                            <div>Name: _______________N/A______________________</div>
                            <div>Home Address: __________N/A______________________________</div>
                            <div>Driver’s License #:_______N/A_______________ </div>
                            <div>Date of Birth:___ _N/A_ ______</div>
                        </div>

                    </div>
                    <div class="conditions" style="margin-top: 30px;">
                        <div style="font-size: 11px; margin-bottom: 10px">
                            <b>Taxes and Fees Include: 7.6% Sales Tax | 9.5% Tourism Tax | $4/Day Registration Fee | 20% Rental Facility Fee</b>
                        </div>

                        <b>
                            We maintain low prices by reducing vehicle cleaning time by  requiring car renters to clean their own rental cars.
                            Please Clean and return the rental car the same way you picked it up to avoid any extra charges.
                        </b>
                        <div>
                            <div id="condistions-p1">
                                The Individual mentioned above in this Car Rental Contract hereby agrees to fill the fuel tank at the above indicated level upon returning the car.
                                Failure to fill the tank at the prescribed level will result in an additional penalty charge of $15 convenience fee plus the fuel cost up-to full tank amount

                                It is the Car Renter’s responsibility for all lost car keys and / or a lockout situation.
                            </div>
                            <div id="condistions-p2" style="margin-top: 30px;white-space: pre-line;">
                                Only the person(s) that are listed on this Car Rental Agreement and above the age of twenty one may drive this vehicle.
                                The rental doesn’t include any type of insurance.  SpeedyRental can purchase Collision Damage Waiver,
                                Primary or supplemental Liability Insurance for extra cost. If Renter declines Collision Damage Waiver
                                and/or Primary liability insurance  the Car Renter will be fully responsible for the cost of any repair
                                up to the value of the vehicle.  The above mentioned Car Renter or Renter’s insurance company is hereby
                                responsible for all collisions or any other types of damages to the vehicle regardless if Renter or
                                someone else is at fault or the cause is not known. <b>Car Renter is required to produce valid documents
                                that prove renter has full(Collision and Comprehensive) insurance coverage for or extendable to the
                                rental car.</b> Car Renter is also responsible for any deductibles or co-payment required by Renters insurance.<br/>
                                No smoking or e-cigarettes are permitted in the car. Additional fee of $150 will be charged for
                                smoking violations. No pets allowed in the car unless it is being disclosed in writing and additional
                                fees are paid in full. <b>The vehicle must be cleaned thoroughly and returned before Due Back Date and
                                Time to the same location and condition in which it was picked up (If the car is not returned on time,
                                it will be considered overdue, and will be reported as stolen to law enforcement authorities.
                                As a result, you may be subject to criminal charges and penalties as per the law.</b> There will be additional fees due if
                                the vehicle is not returned  as specified  above.  Car renter agrees that cleaning fees can be charged
                                from the refundable   deposit   if the vehicle returned dirty and requires   cleaning or Where it is permitted
                                by law the Car Renter hereby authorizes us to process their credit/debit card information   in their name
                                for all Car Rental charges,  including the full vehicle value of any vehicle that is not returned to the
                                Car Rental Company, all fines, towing, any court costs, penalties, and or administrative fees that we
                                may incur for parking, traffic and or other violations that may be incurred by the Car Renter during
                                the Car Rental term period as stated above and to apply any payments made to the charges in whatever
                                order that the Car Rental Company sees as necessary. For more detailed information visit our terms
                                and conditions at: <b>www.carspeedyrental.com/terms&conditions.pdf</b><br/>
                                By signing below Car Renter agrees all the terms mentioned in this contract and hereby bound by the
                                terms and conditions of this Car Rental Agreement.


                                <div class="signature-section" id="signature-section" style="text-align: left; margin-top: 70px;">
                                    <div> ____{{ this.preCheckinInfo.signature}}____/____<span class="signature">{{ this.preCheckinInfo.signature}}</span>____/____{{ this.formatTimeStamp}}_____</div>
                                </div>

                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from 'axios'
    import { computed } from 'vue'
    import moment from 'moment'
//    import Modal from '../src/components/modal/modal.vue';
    export default {
//        props: ['reservation'], // Declare the expected props
        name: 'ReservationContractView',


        data() {
            return {
                reservation: JSON.parse(this.$route.query.reservation),
                preCheckinInfo: JSON.parse(this.$route.query.preCheckinInfo),
                additionalDriver:  this.parseAdditionalDriver(JSON.parse(this.$route.query.preCheckinInfo))

            }
        },

        computed: {
            formattAdditionalDriverDob() {
                return moment(this.additionalDriver.additionalDriverDob).format('MM-DD-YYYY');
            },

            formatPrimaryDriverDob() {
                return moment(this.preCheckinInfo.customerDob).format('MM-DD-YYYY');
            },
            formatCardExpirationDate() {
                return moment(this.preCheckinInfo.expirationDate).format('MM-DD-YYYY');
            },

            formatTimeStamp() {
                return moment(this.preCheckinInfo.timestamp).format('MM-DD-YYYY');
            },

            formatPickupDate() {
                return moment(this.reservation.startDate).format('MM-DD-YYYY, h a');
            },

            formatDropoffDate() {
                return moment(this.reservation.endDate).format('MM-DD-YYYY, h a');
            },
            showInsuranceInfo() {
                return this.preCheckinInfo.insuranceCompany !== "null" && this.preCheckinInfo.insurancePolicyNumber !== "null";
            },

            calculateDays() {
                let start = moment(this.reservation.startDate);
                let end = moment(this.reservation.endDate);
                let days = end.diff(start, 'days');
                const pickupTime = start.format("HH:mm");
                const dropOffTime  = end.format("HH:mm");
                // Check if end time is greater than start time and add an extra day if true
                if(dropOffTime > pickupTime) {
                    days += 1;
                }

                return days;
            },
            calculateDaylyPrice() {
                const price = this.reservation.carBaseRate / this.calculateDays;
                console.log(this.reservation.carBaseRate, this.calculateDays, price)
                return price.toFixed(2);
            }
        },
        components: {
        },
        methods: {
            detectMob() {
                return (window.innerWidth <= 500 );
            },
            getCarName() {
                return this.$store.state.cars.filter(car => car.Id === this.reservation.car_id)[0].Name;
            },
            getVin() {
                return this.$store.state.cars.filter(car => car.Id === this.reservation.car_id)[0].vin;
            },

            parseAdditionalDriver(preCheckinInfo) {
                if(preCheckinInfo.isAdditionalDriver) {
                    const additionalDriverObject = {};
                    for (const key in preCheckinInfo) {
                        if (preCheckinInfo.hasOwnProperty(key) && key.includes('additional')) {
                            additionalDriverObject[key] = preCheckinInfo[key];
                        }
                    }
                    return additionalDriverObject;
                }
                else return null;
            },


            printInvoice() {
                let element = document.getElementById('doc-content');

                let opt =
                    {
                        margin:       1,
                        filename:     'pageContent_invoice.pdf',
                        image:        { type: 'jpeg', quality: 0.98 },
                        html2canvas:  { scale: 2 },
                        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
                        pagebreak: { mode: 'avoid', before: '#condistions-p2' },
                    };

                // New Promise-based usage:
                html2pdf().set(opt).from(element).save();
            },

            sendContract() {
                let element = document.getElementById('doc-content');

                let opt =
                    {
                        margin:       1,
                        filename:     'contract_pdf.pdf',
                        image:        { type: 'jpeg', quality: 0.98 },
                        html2canvas:  { scale: 2 },
                        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
                        pagebreak: { mode: 'avoid', before: '#condistions-p2' },
                    };

                // New Promise-based usage:
                let pdfFile = html2pdf().set(opt).from(element).outputPdf('blob') // Output the PDF as a Blob object
                    .then(function(pdfBlob) {
                        // Create a FormData object
                        let data = new FormData();
                        data.append('customerEmail', this.reservation.userEmail);
                        data.append('pdfContract', pdfBlob, 'contract_pdf.pdf');

                        try {
                            this.$store.dispatch('submitContract', data).then(
                                response => {
                                    if(response.data.status === 'success') {
                                        this.$store.dispatch('toastMessage',  {type: 'success', msg: response.data.msg})
                                    } else {
                                        this.$store.dispatch('toastMessage',  {type: 'error', msg: response.data.msg})
                                    }
                                }
                            );
                        } catch (error) {
                            console.log('error ', error)
                        }
                    }.bind(this));

            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .btn-action-group {
        button {
            margin: 10px;
        }
    }


    .signature {
        font-family: Shalimar;
        font-size: 28px;
        height: inherit;
    }

    .row {
        font-family: "Calibri";
        .container {
            max-width: 720px;
        }
    }

    .waterMark {
        position: absolute;
        opacity: 0.3;
        font-size: 40px;
        letter-spacing: 8px;
        -webkit-transform: rotate(-35deg);
    }

    #waterMark1 {
        top: 33%;
        left: 19%;
    }
    #waterMark2 {
        top: 55%;
        left: 19%;
    }
    #waterMark3 {
        top: 70%;
        left: 19%;
    }


</style>
