<template>
    <form @submit.prevent="savePersonalInfo">
        <div class="row">
            <div class="col-md-7">
                <h3>Personal Information</h3>
                <!-- first name input -->
                <div class="form-outline mb-4">
                    <input type="text" v-model="form.first_name" class="form-control" placeholder="First Name" disabled/>
                    <div class="form-error" v-if="v$.form.first_name.$error">
                        <small v-if="v$.form.first_name.required.$invalid" >Invalid First Name</small>
                    </div>
                </div>

                <!-- last name input -->
                <div class="form-outline mb-4">
                    <input type="text" v-model="form.last_name"  class="form-control" placeholder="Last Name" disabled/>
                    <div class="form-error" v-if="v$.form.last_name.$error">
                        <small v-if="v$.form.last_name.required.$invalid" > Invalid Last Name</small>
                    </div>
                </div>


                <div class="form-outline mb-4">
                    <input type="text"  class="form-control" placeholder="Last Name" :value="`+${form.oldPhoneCountryCode} ${form.oldPhone}`" disabled/>
                </div>

                <!-- Email input -->
                <div class="form-outline mb-4">
                    <input type="text" v-model="form.email" id="email" class="form-control" placeholder="Enter Email"/>
                    <div class="form-error" v-if="v$.form.email.$error" >
                        <small v-if="v$.form.email.required.$invalid"> Email Is Required</small>
                        <small v-if="v$.form.email.email.$invalid" > Not Valid Email</small>
                    </div>
                </div>

            </div>
        </div>


    </form>

</template>

<script>
    import { useVuelidate } from '@vuelidate/core'
    import appHeader2 from '../src/components/AppHeader2.vue';
    import appFooter from '../src/components/appFooter.vue';
    import { RouterLink, RouterView } from 'vue-router';
    import {computed } from 'vue'
    import { required, minLength, maxLength, between, email, sameAs, numeric } from '@vuelidate/validators';
    import telInputField from '../src/components/tel-input-field.vue';


    //must have least  6 characters and least one number special chars allowed.
    const mustInclude = (value) => {
        const regex = new RegExp("^(?=.*[A-Za-z])(?=.*?[0-9])(?=.*\\d)[A-Za-z\\d$@$!%#?&]{6,}$");
        return regex.test(value);
    }

    export default {
        name: 'MyAccount',
        data() {

            return {
                v$: useVuelidate(),
                form: {
                    first_name: computed(() => this.$store.state.user?.first_name),
                    last_name: computed(() => this.$store.state.user?.last_name),
                    email: computed(() => this.$store.state.user?.email),
                    oldPhoneCountryCode: computed(() => this.$store.state.user?.phoneCountryCode),
                    oldPhone:  computed(() => this.$store.state.user?.phone),
                    newPhoneCountryCode: null,
                    newPhone: null,
                },
            }
        },

        components: {
            appHeader2,
            appFooter,
            telInputField
        },

        methods: {
            savePersonalInfo() {
                console.log(this.$store.state.user)
                this.v$.form.$validate();

            },
            getUserPhoneNumber(value) {
                this.form.newPhone = value.number;
                this.form.newPhoneCountryCode = value.countryCode;
            },
        },

        validations () {
            return {
                form: {
                    first_name: {
                        required,
                    },
                    last_name: {
                        required,

                    },
                    phone: {
                    },
                    email: {
                        required,
                    }
                }
            }
        },

    }

</script>

<style lang="scss" scoped>
    .col-md-7 {
        margin: auto;
    }
</style>