<template>
    <!-- Modal Content -->
    <div>
        <form  id="reservation-form" @submit.prevent="" style="">
            <div class="form-group ">
                <label style="display: block" class="control-label" for="car-id">Car Name <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('car-id')"></i> </label>
                <select v-model="form.car_id" id="car-id" disabled>
                    <option v-for="car in cars" :key="car.Id" :value="car.Id">
                        {{ car.Name }}
                    </option>
                </select>


            </div>

            <div class="form-group">
                <img id="drivingLicense" :src=" this.$imgBaseUrl + this.form.drivingLicensePath" width="400" />
            </div>

            <div class="form-group">
                <label class="control-label" for="user-firstName">First Name <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('user-firstName')"></i></label>
                <input type="string" v-model="this.form.userFirstName"  class="form-control" id="user-firstName" name="user_firstName" disabled>
            </div>

            <div class="form-group">
                <label class="control-label" for="user-lastName">Last Name <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('user-lastName')"></i></label>
                <input type="string" v-model="this.form.userLastName"  class="form-control" id="user-lastName" name="user-lastName" disabled>
            </div>
            <div class="form-group">
                <label class="control-label" for="user-email">User Email <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('user-email')"></i></label>
                <input type="email" v-model="this.form.userEmail" class="form-control" id="user-email" name="userEmail" disabled>
            </div>


            <div v-if="preCheckinInfo" class="form-group">
                <label class="control-label" for="current-address">Current Address </label>
                <input type="text" :value="preCheckinInfo.currentAddress" class="form-control" id="current-address" name="currentAddress" disabled>
            </div>

            <div v-if="preCheckinInfo" class="form-group">
                <label class="control-label" for="local-address">Local Address </label>
                <input type="text" :value="preCheckinInfo.secondaryAddress" class="form-control" id="local-address" name="localAddress" disabled>
            </div>

            <div v-if="preCheckinInfo" class="form-group">
                <label class="control-label" for="dob">Date of Birth </label>
                <input type="text" :value="preCheckinInfo.customerDob" class="form-control" id="dob" name="dob" disabled>
            </div>


            <div class="form-group phone-with-countryCode">
                <label class="control-label" for="user-phone">User Phone <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('phone_info')"></i></label>
                <div class="input-group">
                    <input type="text" class="form-control phone_info" v-model="this.form.userPhoneCountryCode"
                           id="user-phone-country-code" name="userPhoneCountryCode" placeholder="Code" disabled>
                    <input type="tel" class="form-control phone_info" v-model="this.form.userPhone" id="user-phone" name="userPhone"
                           placeholder="Number" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Trip Start Date</label>
                <div class="">
                    <input type="string" v-model="this.form.startDate" class="form-control input-md" name="startDate" id="startDate" disabled>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Trip End Date</label>
                <div class="">
                    <input type="string" v-model="this.form.endDate" class="form-control input-md" name="endDate" id="endDate" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Promo Code</label>
                <div class="">
                    <input type="string" v-model="this.form.promoCode" class="form-control input-md" name="promoCode" id="promoCode" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Payment Network Type</label>
                <div class="">
                    <input type="string" v-model="this.form.paymentCardNetwork" class="form-control input-md" name="paymentCardNetwork" id="paymentCardNetwork" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Debit/Credit Card</label>
                <div class="">
                    <input type="string" v-model="this.form.paymentCardType" class="form-control input-md" name="paymentCardType" id="paymentCardType" disabled>
                </div>
            </div>


            <div class="form-group">
                <label class="control-label">Total <small>(this is for old reservations)</small></label>
                <div class="">
                    <input type="string" v-model="this.form.total" class="form-control input-md" name="totalPriceWithInsurance" id="totalPriceWithInsurance" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Total Without Insurance</label>
                <div class="">
                    <input type="string" v-model="this.form.totalPriceWithoutInsurance" class="form-control input-md" name="totalPriceWithoutInsurance" id="totalPriceWithoutInsurance" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Total With Insurance</label>
                <div class="">
                    <input type="string" v-model="this.form.totalPriceWithInsurance" class="form-control input-md" name="totalPriceWithInsurance" id="totalPriceWithInsurance" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Due Upon Pickup <small>(this amount is for the insurance bought by customer)</small></label>
                <div class="">
                    <input type="string" v-model="this.form.totalInsuranceCost" class="form-control input-md" name="totalInsuranceCost" id="totalInsuranceCost" disabled>
                </div>
                <div class="col-md-12 insurance-breakdown" >
                    <div class="d-flex justify-content-end ldw" >
                        <label>Collision Damage Waiver: </label><input  type="string" v-model="this.form.ldwCost" class="form-control input-md" name="totalPrice" id="ldwCost" disabled>
                    </div>
                    <div class="d-flex justify-content-end">
                        <label>Liability Insurance: </label><input type="string" v-model="this.form.rcliCost" class="form-control input-md" name="totalPrice" id="rcliCost" disabled>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Deposit <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('deposit')"></i></label>
                <div class="">
                    <input type="string" v-model="this.form.deposit" class="form-control input-md" name="deposit" id="deposit" disabled>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Charge Id</label>
                <div class="">
                    <input type="string" v-model="this.form.charge_id" class="form-control input-md" name="charge_id" id="chargeId" disabled>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Miles Included <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('milesIncluded')"></i></label>
                <div class="">
                    <input type="string" v-model="this.form.milesIncluded" class="form-control input-md" name="milesIncluded" id="milesIncluded" disabled>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Start Mileage <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('startMileage')"></i></label>
                <div class="">
                    <input type="string" v-model="this.form.startMileage" class="form-control input-md"
                           name="startMileage" id="startMileage" disabled>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">End Mileage <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('endMileage')"></i></label>
                <div class="">
                    <input type="string" v-model="this.form.endMileage" class="form-control input-md"
                           name="endMileage" id="endMileage" disabled>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Young Driver Fee <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('youngDriverFee')"></i></label>
                <div class="">
                    <input type="string" v-model="this.form.youngDriverFee" class="form-control input-md"
                           name="youngDriverFee" id="youngDriverFee" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Notes <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('notes')"></i></label>
                <div class="">
                <textarea rows="4" cols="50" v-model="this.form.notes" class="form-control input-md" placeholder="Write Any Note Here..."
                          name="notes" id="notes" disabled></textarea>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-check float-left">
                        <input type="checkbox" v-model="this.form.checked_in" class="form-control" id="checked-in"
                               name="checked_in" disabled>
                        <label class="form-check-label checkbox-labels">
                            Checked In <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('checked-in')"></i>
                        </label>
                    </div>

                    <div class="form-check float-right">
                        <input type="checkbox" v-model="this.form.checked_out" class="form-control" id="checked-out"
                               name="checked_out" disabled>
                        <label class="form-check-label checkbox-labels">Checked Out <i class="fa fa-pencil-square" aria-hidden="true" @click="toggleField('checked-out')"></i>
                        </label>
                    </div>
                </div>
            </div>


            <div class="row ckeckbox-row">
                <div class="col-md-6">
                    <div class="form-check">
                        <input type="checkbox" v-model="this.form.LDW" class="form-check-input" id="ldw" name="LDW" disabled>
                        <label class="form-check-label">Loss Damage Waiver</label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" v-model="this.form.RCLI" class="form-check-input" id="rcli" name="RCLI" disabled>
                        <label class="form-check-label">Renter's Contingent Liability Insurance</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-check">
                        <input type="checkbox" v-model="this.form.PAI" class="form-check-input" id="pai" name="PAI" disabled>
                        <label class="form-check-label">Personal Accident Insurance</label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" v-model="this.form.prepaidFueling" class="form-check-input"
                               name="prepaidFueling" id="prepaidFueling" disabled>
                        <label class="form-check-label">Prepaid Fueling</label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" v-model="this.form.isLastMinuteBooking" class="form-check-input"
                               name="isLastMinuteBooking" id="isLastMinuteBooking" disabled>
                        <label class="form-check-label">Last Minute Booking</label>
                    </div>
                </div>
            </div>
            <div class="btn-div">
                <button v-if="this.form.isConfirmed" @click="confirmBeforeUpdateConfirm()" class="btn btn-primary">Update</button>
                <button v-if="!this.form.isConfirmed" @click="confirmBeforeUpdateConfirm()" class="btn btn-primary" >Confirm</button>
                <button v-if="!this.form.isConfirmed" @click="confirmBeforeCancel()" class="btn btn-primary" >Cancel</button>
            </div>

        </form>

    </div>


</template>

<script>
    import {useVuelidate} from '@vuelidate/core';
    import moment from 'moment'
    import {required, email, decimal, numeric, maxValue, minValue} from '@vuelidate/validators';

    const now = moment();
    export default {
        name: 'ReservationModalView',
        props: ['item', 'preCheckinInfo'],

        mounted() {
        },
        created() {
            // Conditionally assign properties from this.preCheckinInfo
            if (this.preCheckinInfo) {
                this.form.currentAddress = this.preCheckinInfo.currentAddress;
                this.form.localAddress = this.preCheckinInfo.secondaryAddress;
                this.form.dob = this.preCheckinInfo.customerDob;
            }
        },

        data() {

            return {
                form: {
                    checked_in: !!this.item.checked_in,
                    checked_out: !!this.item.checked_out,
                    LDW: !!this.item.LDW,
                    RCLI: !!this.item.RCLI,
                    PAI: !!this.item.PAI,
                    id: this.item.id,
                    car_id: this.item.car_id,
                    user_id: this.item.user_id,
                    userFirstName: this.item.userFirstName,
                    userLastName: this.item.userLastName,
                    userEmail: this.item.userEmail,
                    userPhone: this.item.userPhone,
                    userPhoneCountryCode: this.item.userPhoneCountryCode,
                    startDate: this.item.startDate,
                    endDate: this.item.endDate,
                    paymentCardNetwork: this.item.card_network,
                    paymentCardType: this.item.funding_type,
                    promoCode: this.item.promoCode,
                    totalPriceWithInsurance: this.item.totalPriceWithInsurance,
                    total: this.item.totalPrice,
                    totalPriceWithoutInsurance: this.item.totalPriceWithoutInsurance,
                    totalInsuranceCost: this.item.ldwCost + this.item.rcliCost,
                    ldwCost: this.item.ldwCost,
                    rcliCost: this.item.rcliCost,
                    totalPrice: this.item.totalPrice,
                    deposit: this.item.deposit,
                    prepaidFueling: !!this.item.prepaidFueling,
                    milesIncluded: this.item.milesIncluded,
                    isLastMinuteBooking: !!this.item.isLastMinuteBooking,
                    youngDriverFee: this.item.youngDriverFee,
                    notes: this.item.notes,
                    charge_id: this.item.charge_id,
                    receipt_url: this.item.receipt_url,
                    isActive: this.item.isActive,
                    isConfirmed: this.item.isConfirmed,
                    startMileage: this.item.startMileage,
                    endMileage: this.item.endMileage,
                    drivingLicensePath: this.item.drivingLicensePath,
                    car: this.$store.state.cars.filter(car => car.Id === this.item.car_id)[0].Name,

                },
                cars: this.$store.state.cars,
//                reservationStatus: this.reservationClassification(),
            }

        },
        components: {},
        methods: {
            closeReservationModal() {
                this.$emit("close");
            },

            confirmBeforeUpdateConfirm() {
                let result = '';
                if(this.item.isConfirmed) {
                    result = window.confirm('Are You Sure You Would Like to Update Reservation?');
                } else {
                    result = window.confirm('Are You Sure You Would Like to Confirm Reservation?');
                }
                if (result) {
                    this.updateConfirm();
                }
            },

            updateConfirm() {
                let someReservationInfo = {
                    id: this.form.id,
                    car_id: this.form.car_id,
                    charge_id: this.form.charge_id,
                    userFirstName: this.form.userFirstName,
                    userLastName: this.form.userLastName,
                    userEmail: this.form.userEmail,
                    userPhoneCountryCode: this.form.userPhoneCountryCode,
                    userPhone: this.form.userPhone,
                    milesIncluded: this.form.milesIncluded,
                    startMileage: this.form.startMileage,
                    endMileage: this.form.endMileage,
                    youngDriverFee: this.form.youngDriverFee,
                    checked_in: this.form.checked_in,
                    checked_out: this.form.checked_out,
                    deposit: this.form.deposit,
                    notes: this.form.notes,
                    customAmount: 0,
                }
                if(this.item.isConfirmed) {
                    // update reservaton

                    this.$store.dispatch('updateReservation', {someReservationInfo}).then(
                        response => {
                            if(response.data === 'success') {
                                this.closeReservationModal();
                                //update frontend
                                const index = this.$store.state.reservations.findIndex((reservation) => reservation.charge_id === someReservationInfo.charge_id);
                                // Use the spread operator and object destructuring to create a new object with the updated properties
                                const updatedReservation = {...this.$store.state.reservations[index], ...someReservationInfo};
                                this.$store.state.reservations[index] = updatedReservation;

                                this.$store.dispatch('toastMessage',  {type: 'success', msg: "Reservation Updated"})
                            } else {
                                this.$store.dispatch('toastMessage',  {type: 'error', msg: "Error  Try Again To Update Reservation"})
                            }
                        }
                    );

                } else {
                    // confirm reservation
                    this.$store.dispatch('confirmReservation', {someReservationInfo}).then(
                        response => {
                            if(response.data === 'success') {
                                this.closeReservationModal();
                                //update frontend
                                const index = this.$store.state.reservations.findIndex((reservation) => reservation.charge_id === someReservationInfo.charge_id);
                                this.$store.state.reservations[index].isConfirmed = true;
                                this.$store.state.reservations[index].isActive = true;
                                this.$store.dispatch('toastMessage',  {type: 'success', msg: "Reservation Confirmed"})
                            } else {
                                this.$store.dispatch('toastMessage',  {type: response.data.status, msg: response.data.msg})
                            }
                        }
                    );
                }
            },


            confirmBeforeCancel() {
                const result = window.confirm('Are you sure you want cancel this reservation?');
                if (result) {
                    this.cancelUnconfirmedReservation();
                }
            },

            cancelUnconfirmedReservation() {
                let someReservationInfo = {
                    id: this.form.id,
                    charge_id: this.form.charge_id,
                    userEmail: this.form.userEmail,
                }
                this.$store.dispatch('cancelUnconfirmedReservation', {someReservationInfo}).then(
                    response => {
                        if(response.data === 'success') {
                            this.closeReservationModal();
                            //update frontend
                            const index = this.$store.state.reservations.findIndex((reservation) => reservation.charge_id === someReservationInfo.charge_id);
                            // Use the spread operator and object destructuring to create a new object with the updated properties
                            this.$store.state.reservations[index].isActive = false;
                            this.$store.state.reservations[index].isConfirmed = false;
                            this.$store.dispatch('toastMessage',  {type: 'success', msg: "Reservation Canceled"})
                        } else {
                            this.$store.dispatch('toastMessage',  {type: response.data.status, msg: response.data.msg})
                        }
                    }
                );

            },

            shouldToggleBeEnabled(checked_in, checked_out, startTime, endTime, el) {
                if(checked_in && checked_out) {
                    return false;
                } else if((checked_in && !checked_out && el === 'endMileage') || (checked_in && !checked_out && el === 'checked-out'))  {
                    return true;
                } else if(!checked_in && !checked_out) {
                    return true;
                } else {
                    return false;
                }
            },

            toggleField(fieldName) {
                let el = document.getElementById(fieldName);
                if(this.item.isConfirmed) {
                    if(fieldName === 'notes') {
                        el = document.getElementById(fieldName)
                        el.disabled = !el.disabled;
                        return;
                    }

                    if(fieldName === 'phone_info') {
                        el = document.getElementsByClassName('phone_info')
                        for (let i = 0; i < el.length; i++) {
                            el[i].disabled = !el[i].disabled;
                        }
                        return;
                    }

                    const start = moment(this.form.startDate);
                    if(fieldName ===  'checked-in' && !start.isSame(now, 'day')) {
                        this.$store.dispatch('toastMessage',  {type: 'error', msg: "Past Or Future Reservation. Don't Check In Reservation if it is in the future."})
                    }


                    if(this.shouldToggleBeEnabled(this.item.checked_in, this.item.checked_out, this.item.startDate, this.item.endDate, el.id)) {
                        el.disabled = !el.disabled;
                    } else {
                        this.$store.dispatch('toastMessage',  {type: 'error', msg: "You Can't Change This Field After Checkout"})
                    }
                } else {
                    this.$store.dispatch('toastMessage',  {type: 'error', msg: "Reservation Has not Been confirm. Please Confirm Reservation First"})
                }

            }
        }
    }

</script>

<style lang="scss" scoped>
    form {
        margin: 40px 0px 35px 0px;
        overflow: auto;
        height: 530px;
        overflow-x: hidden;
        padding: 0px 5px 0px 5px;

        .btn-div {
            margin: 10px;

            button {
                margin: inherit;
            }
        }

        .form-group {
            padding: 15px;

            .insurance-breakdown {
                margin-left: 13%;

                .ldw {
                    margin: 10px 0px 10px 0px
                }
                div {
                    input {
                        width: 38%;
                    }
                }
            }
        }
        .phone-with-countryCode {
            .input-group {
                display: flex;
            }
            #user-phone {
                width: 88%
            }

            #user-phone-country-code {
                width: 12%
            }
        }
        .ckeckbox-row {
            text-align: start;
            font-size: 13px;
            padding: 25px;
        }
    }



    @media screen and (max-width: 800px) {
        form {
            height: 480px;

            #checked-in {

            }
            #checked-out {

            }
            .checkbox-labels {
                font-size: 13px;
            }

        }
        #drivingLicense {
            width: 250px;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 359px) {
        form {
            height: 300px;
        }
        #drivingLicense {
            width: 200px;
        }
    }

</style>