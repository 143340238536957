export default {
    setCars: function (state, payload) {
        state.cars = payload;
    },
    setPriceManipulations: function (state, payload) {
        state.priceManipulations = payload;
    },
    setInsurancePrices: function (state, payload) {
        state.insurancePrices = payload;
    },
    setEstimateModalToggle: function (state, payload) {
        state.isEstimatePriceModalOpen = payload;
    },
    setPossibleReservationData: function (state, payload) {
        state.possibleReservationData = payload;
    },
    setTaxesAndFeesPercentage: function (state) {
        state.taxesAndFeesPercentage = {
            SalesTaxPercentage: 0.076,
            tourismTaxPercentage: 0.095,
            vehicleLicenseFee: 4,
            parkingFeePercentage: 0.2,
            depositPercentage: 0.28,
            lastMinuteBookingPercentage: 0.07,
        };
    },
    setAuthUser: function (state, payload) {
        state.user = payload;
    },
    setVerificationCode: function (state, payload) {
        state.verificationCode = payload;
    },
    ADD_TOAST_MESSAGE: function (state, payload) {
        state.toastMessage = payload;
    },
    setLoader: function (state, payload) {
        state.showLoader = payload;
    },
    setPaymentLoader: function (state, payload) {
        state.showPaymentLoader = payload;
    },
    setReservations: function (state, payload) {
        state.reservations = payload;
    },
    setPreCheckInFormReservation: function (_a, payload) {
        var commit = _a.commit;
        this.state.preCheckInFormReservation = payload;
    }
};
