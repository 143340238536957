<template >
  <router-view></router-view>

</template>


<script>

import HomeView from '../views/HomeView.vue'

export default {
  name: 'App',
  components: {
//    HomeView
  },

     mounted() {

      this.$store.dispatch('getAuthUser');
      this.$store.dispatch('fetchCars');
      this.$store.dispatch('fetchInsurancePrices');
      this.$store.dispatch('fetchPriceManipulations');
      this.$store.commit('setTaxesAndFeesPercentage');


      // Global toast for messages
         this.$store.watch(state => state.toastMessage, toastMessage => {
             if(toastMessage) {
                 this.$toast.show(toastMessage.msg, {
                     type: toastMessage.type,
                     position: 'bottom',

                 });
             }
         });
    }
}
</script>

<style lang="scss">
  #app {
    font-family: "Poppins", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #f8f9fa !important;
  }
  @media (max-width: 767.98px) {
    h2 {
      font-size: 40px;
      font-weight: 600;
    }
  }

  h2 {
    font-size: 27px !important;
    font-weight: 600 !important;
  }
  .modal {
    z-index: 1;
  }
  .form-outline {
    .form-error {
      small {
        display: block;
        color: #f22f41;
        font-size: 12px;
      }
    }
  }



</style>
