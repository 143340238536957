<template>
    <Modal :modalActive="modalActive" :showPrintButton="true"
           :mediaModalHeight="'777px'"  :headerName="'Choose A Car'"
           :showModalSelectError="showModalSelectError"
           @close="closeModal" @printEstimatePage="printPage" @makeReservation="makeReservationPage">
        <div class="float-container">
            <div class="cars-section">
                <div class="list-group"  v-for="car in cars" :key="car.Id">
                    <button type="button" @click="selectCar(car.Id)" class="list-group-item list-group-item-action" :class="{ active : active_el == car.Id }" >
                        {{car.Name}} <br/> <img :src="this.$imgBaseUrl + car.Picture" width="120" /> </button>
                </div>
            </div>
            <div class="selected-car-section">
                <div class="pl-thead">
                    <div class="row green-it">
                        <div class="col">Unlimted Miles?</div>
                        <div class="col col2"><span id="unlimited"> <input type="checkbox" v-bind:disabled="!active_el"   v-on:change="toggleUnlimitedMiles(active_el)" v-model="this.isUnlimtedMiles" ></span></div>

                    </div>
                </div>

                <div class="pl-thead">
                    <div class="row green-it">
                        <div class="col">Prepaid refuel</div>
                        <div class="col col2"><span id="prePaidRefuel"> <input type="checkbox" v-bind:disabled="!active_el"   v-on:change="toggleprePaidRefuel(active_el)" v-model="this.prePaidRefuel" ></span></div>

                    </div>
                </div>

                <div class="pl-thead">
                    <div class="row green-it">
                        <div class="col">Days:</div>
                        <div class="col col2"><span id="numDays"> {{possibleReservationData?.totalDays}}</span></div>
                    </div>
                </div>
                <div class="pl-thead">
                    <div class="row green-it">
                        <div class="col">Miles Included:</div>
                        <div class="col col2"><span id="miles_included"> {{milesIncluded}}</span></div>
                    </div>
                </div>
                <div class="pl-tbody">
                    <div class="row">
                        <div class="col">Rental Cost:</div>
                        <div class="col col2">$<span id="carRental">{{carPrice.toFixed(2)}} </span></div>
                    </div>

                    <div class="row">
                        <div class="col">Taxes and Fees:</div>
                        <div class="col col2">$<span id="totalTax">{{ totalTaxAndFees.toFixed(2) }} </span></div>
                    </div>
                    <div class="row">
                        <div class="col">Young Driver Fee:</div>
                        <div class="col col2">$<span id="youngDriver">{{ possibleReservationData?.youngDriverFee}} </span></div>
                    </div>

                    <div class="row red-it">
                        <div class="col">
                            Refundable Deposit:
                            <!--<img class="info-icon" data-toggle="tooltip" title="Tooltip on bottom" src="images/info.png" width="14" />-->
                            <span class="icon icon-info" title="Deposit will be refunded after trip is over"></span>
                        </div>
                        <div class="col col2">$<span id="refundableDeposite">{{deposit.toFixed(2)}} </span></div>
                    </div>
                    <div class="row btn-secondary" style="border-radius: 12px;">
                        <div class="col">Sub-Total:</div>
                        <div class="col col2">$<span id="subtotal">{{subTotal.toFixed(2)}} </span></div>
                    </div>
                    <div class="row green-it">
                        <div class="col">Refund:</div>
                        <div class="col col2">-$<span id="refund"> {{deposit.toFixed(2)}} </span> </div>
                    </div>
                    <div class="row btn-primary total-primary">
                        <div class="col">Total Cost:</div>
                        <div class="col col2">$<span id="totalEstimate" >{{total.toFixed(2)}}</span></div>
                    </div>

                </div>
            </div>

        </div>

    </Modal>
</template>
<script >
    import Modal from './modal/modal.vue';
    import { ref, toRaw } from 'vue';
    import { useStore } from 'vuex'
    import { computed } from 'vue'
    import { Estimate,  } from '../types'
    import moment from 'moment'
    export default {

        props: {

        },
        data() {
            const store = useStore()
            return {
                modalActive: computed(() => store.state.isEstimatePriceModalOpen),
                cars: computed(() => store.state.cars),
                priceManipulations: computed(() => store.state.priceManipulations),
                isUnlimtedMiles: false,
                prePaidRefuel: false,

                possibleReservationData:  computed(() => store.state.possibleReservationData),
                taxesAndFeesPercentage: computed(() =>store.state.taxesAndFeesPercentage),
                totalTaxAndFees: 0,
                carPrice: 0,
                deposit: 0,
                prePaidRefuelingPrice: 0,
                subTotal: 0,
                total: 0,
                parkingAndLicensingFeeNum: 0,
                active_el:0,
                selectedCar: '',
                showModalSelectError: true,
                milesIncluded: 0,
                lastMinuteBooking: computed(() => {
                    let startTime = moment(store.state.possibleReservationData.pickupTime +' '+ store.state.possibleReservationData.pickupDate, 'MMM D, YYYY');
                    const now = moment();
                    const diffMs = startTime.diff(now);
                    const diffHours = Math.round(moment.duration(diffMs).asHours());
                    if(diffHours < 12) {
                        return true;
                    } else return false;
                }),
            }
        },
        components: {
            Modal
        },

        computed: {

        },

        methods: {
            toggleUnlimitedMiles(id) {
                    this.selectCar(id)
            },
//            toggleLastMinuteBooking(id) {
//                this.selectCar(id)
//            },

            toggleprePaidRefuel(id) {
                this.selectCar(id)
            },

            selectCar(id) {
                this.active_el = id;
                this.calculateMilesIncluded();
                this.calculateTotalEstimate(id);

            },

            calculateMilesIncluded() {
                if(this.isUnlimtedMiles) {
                    this.milesIncluded = 'unlimited';
                } else {
                    if(this.possibleReservationData.totalDays >= 30){
                        this.milesIncluded =  this.possibleReservationData.totalDays * this.$store.state.plus30DailyMilage;
                    } else if(this.possibleReservationData.totalDays >= 20) {
                        this.milesIncluded = this.possibleReservationData.totalDays * this.$store.state.plus20DailyMilage;
                    } else {
                        this.milesIncluded =  this.possibleReservationData.totalDays * this.$store.state.dailyMilage;
                    }
                }
            },

            calculateTotalEstimate(id) {
                this.showModalSelectError = false;
                let car = toRaw(this.cars.filter(car => car.Id == id));
                this.selectedCar = car;

                //manipulate price according to month so that busy month will be more expensive.
                let carDailyPrice = 0;
                const reservationMonth = moment(this.possibleReservationData.pickupDate, 'MMM D, YYYY').month();

                let priceManipulationObject = this.priceManipulations.find((priceManipulation)=> {
                    return priceManipulation.Month === reservationMonth;
                })

                if(priceManipulationObject?.Month){
                    carDailyPrice = Number(car[0].Price) + priceManipulationObject.Price;
                } else {
                    carDailyPrice = Number(car[0].Price);
                }


                this.selectedCar = car;
                this.prePaidRefuelingPrice = Number(this.selectedCar[0].PrePaidRefueling)
                if(this.milesIncluded == 'unlimited') {
                    carDailyPrice = carDailyPrice + this.$store.state.unlimtedMilesDailyFee ;
                }
                if(this.possibleReservationData.totalDays >= 20) {
                    this.carPrice =  (carDailyPrice - 3) * this.possibleReservationData.totalDays
                }  else if(this.possibleReservationData.totalDays >= 10) {
                    this.carPrice =  (carDailyPrice - 2) * this.possibleReservationData.totalDays
                } else if(this.possibleReservationData.totalDays < 4) {
                    this.carPrice =  (carDailyPrice + 2) * this.possibleReservationData.totalDays;
                } else {
                    this.carPrice =  carDailyPrice * this.possibleReservationData.totalDays;
                }

                if(this.lastMinuteBooking) {
                    this.carPrice = this.carPrice + (this.carPrice * this.$store.state.taxesAndFeesPercentage.lastMinuteBookingPercentage)
                }

                //Calculate taxes and fees
                this.parkingAndLicensingFeeNum = (this.possibleReservationData.totalDays * this.taxesAndFeesPercentage.vehicleLicenseFee) +
                    (this.carPrice * this.taxesAndFeesPercentage.parkingFeePercentage);
                this.totalTaxAndFees = (this.taxesAndFeesPercentage.SalesTaxPercentage * this.carPrice)
                    + (this.taxesAndFeesPercentage.tourismTaxPercentage * this.carPrice)
                    + this.parkingAndLicensingFeeNum;
                this.deposit = this.carPrice * this.taxesAndFeesPercentage.depositPercentage;

                if(this.deposit > 330) {
                    this.deposit = 330;
                }else if(this.deposit < 65) {
                    this.deposit = 65;
                }

                // calculate total amount
                this.total = this.carPrice + this.totalTaxAndFees + this.possibleReservationData.youngDriverFee;


                if(this.prePaidRefuel) {
                    this.total += this.prePaidRefuelingPrice;
                }

                // calculate subtotal
                this.subTotal = this.total + this.deposit;
            },

            closeModal() {
                this.$store.dispatch('toggleEstimatePriceModal', false);
                this.active_el = 0;
                this.total = 0;
                this.subTotal = 0;
                this.parkingAndLicensingFeeNum = 0;
                this.deposit = 0;
                this.carPrice = 0;
                this.totalTaxAndFees = 0;
                this.milesIncluded = null;
                this.active_el = 0;
                this.selectedCar = null;
                this.isUnlimtedMiles = false;
                this.prePaidRefuel = false;
            },
            buildUrl(base, key, value) {
                if(base) {
                    var sep = (base.indexOf('?') > -1) ? '&' : '?';
                    return base + sep + key + '=' + value;
                } else {
                    return '&' + key + '=' + value
                }

            },
            printPage() {
                let route = this.$router.resolve({ path: "/printable" });
                if(!this.active_el) {
                    this.showModalSelectError = true;
                } else  {
                    let url =
                        this.buildUrl(route.href, 'totalDays', this.possibleReservationData.totalDays) +
                        this.buildUrl('', 'carCost', this.carPrice.toFixed(2)) +
                        this.buildUrl('', 'deposit', this.deposit.toFixed(2)) +
                        this.buildUrl('', 'subTotal', this.subTotal.toFixed(2)) +
                        this.buildUrl('', 'totalTaxAndFees', this.totalTaxAndFees.toFixed(2)) +
                        this.buildUrl('', 'total', this.total.toFixed(2)) +
                        this.buildUrl('', 'youngDriverFeeTotal', this.possibleReservationData.youngDriverFee.toFixed(2)) +
                        this.buildUrl('', 'pickupTime', this.possibleReservationData.pickupTime) +
                        this.buildUrl('', 'dropOffTime', this.possibleReservationData.dropOffTime) +
                        this.buildUrl('', 'pickupDate', this.possibleReservationData.pickupDate) +
                        this.buildUrl('', 'dropOffDate', this.possibleReservationData.dropOffDate) +
                        this.buildUrl('', 'description', this.selectedCar[0].Name) +
                        this.buildUrl('', 'milesIncluded', this.milesIncluded) +
                        this.buildUrl('', 'isLastMinuteBooking', this.lastMinuteBooking) +
                        ( this.prePaidRefuel ? this.buildUrl('', 'prePaidRefuelingCost',this.prePaidRefuelingPrice.toFixed(2)) : '');
                    window.open(url);
                    this.closeModal()
                }

            },

            makeReservationPage() {
                if(!this.active_el) {
                    this.showModalSelectError = true;
                } else {

                    let estimate = new Estimate()
                    estimate.totalDays = this.possibleReservationData.totalDays;
                    estimate.carCost = this.carPrice;
                    estimate.deposit = parseFloat(this.deposit.toFixed(2));
                    estimate.subTotal = this.subTotal;
                    estimate.totalTaxAndFees = this.totalTaxAndFees;
                    estimate.totalWithOutInsurance = parseFloat(this.total.toFixed(2));
                    estimate.totalWithInsurance = parseFloat(this.total.toFixed(2));
                    estimate.youngDriverFeeTotal = this.possibleReservationData.youngDriverFee;
                    estimate.pickupTime = this.possibleReservationData.pickupTime;
                    estimate.dropOffTime = this.possibleReservationData.dropOffTime;
                    estimate.pickupDate = this.possibleReservationData.pickupDate;
                    estimate.dropOffDate = this.possibleReservationData.dropOffDate;
                    estimate.description = this.selectedCar[0].Name;
                    estimate.milesIncluded = this.milesIncluded;
                    estimate.prePaidRefuelingCost = this.prePaidRefuel ? this.prePaidRefuelingPrice : 0;
                    estimate.selectedCar = this.selectedCar[0];
                    estimate.isLastMinuteBooking = this.lastMinuteBooking;
                    this.$store.state.estimate = estimate;
                    this.$router.push('/reservation');
                    this.closeModal();
                }
            },
        }
    }
</script>
<style lang="scss">
    .float-container {
        .modal-footer {
            display: block;
            text-align: right;
        }

        .cars-section {
            overflow: auto;
            width: 200px;
            height: 400px;
            float: left;

            .list-group {
                padding: 4px;
            }
        }
        .selected-car-section {
            width: 335px;
            margin-left: 45px;
            height: 400px;
            float: left;

            .pl-thead {
                font-size: 12px;
                margin-top: -5px;
            }

            .pl-thead, .pl-tbody {
                .row {
                    margin-top: 9px;
                    margin-right: -4px;
                    margin-left: -15px;
                    text-align: left;
                }

                .green-it {
                    font-weight: 500;
                    color: green;
                }
                .red-it {
                    color: red;
                    font-weight: 300;
                }
                .col2 {
                    text-align: end;
                }

                .total-primary {
                    font-size: 20px;
                    border-radius: 12px;
                }


            }




            .modal-contact-info {
                top: 16px;
                position: relative;

                .last-min-booking {
                    display: block ruby;
                }
            }
        }
    }



    @media screen and (max-width: 800px) {

        .cars-section {
            width: 270px !important;
            margin-left: -5px;
            height: 155px !important;
            white-space: nowrap;
            position: relative;
            overflow-x:scroll;
            overflow-y:hidden;


            div{
                background-color: #eee;
                float: none;
                margin: 0 0.25%;
                display: inline-block;
            }
        }

        .selected-car-section {
            margin: 4px !important;
            width: 260px !important;
        }


        .modal_price {
            padding: 0px;
            width: 48%;
        }

        .pl-tbody {
            width: auto;
            font-size: 11px;
        }

    }

</style>