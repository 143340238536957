<template>
    <div class="container">
        <app-header2 />
        <h2>Your Reservations</h2>
        <div v-if="reservations === null">Loading...</div>
        <div v-else>
           <my-reservations-list-view :reservations="this.reservations"></my-reservations-list-view>
        </div>
    </div>



    <app-footer />
</template>
<script>
    import appHeader2 from '../src/components/AppHeader2.vue';
    import appFooter from '../src/components/appFooter.vue';
    import MyReservationsListView from './MyReservationsListView.vue'
    export default {
        name: 'MyReservations',



        mounted() {
            console.log(this.user.email);
            this.$store.dispatch('getReservationsByEmail', this.user.email)
                .then(result => {
//                    console.log(result);
                    this.reservations = result.data.reservations; // Assign the resolved value to reservations
                })
                .catch(error => {
                    console.error(error);
                });
        },


        data() {

            return {
                user: this.$store.state.user,
                reservations: null,
            }
        },


        methods: {

        },



        components: {
            appHeader2,
            appFooter,
            MyReservationsListView,

        }
    }
</script>
<style>

</style>