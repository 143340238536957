<template>
    <appHeader2/>

    <div class="container">
        <div class="loader-div" v-if="this.showPaymentLoader">
            <div  class="loader"></div>
        </div>
        <div class="row">
            <div class="col-md-7">
                <div class="row pickup-dropoff-row">
                    <div class="col-md-12 estimate-subtitle-div">
                        <h4 class="days-miles">{{this.$store.state.estimate.totalDays}} Days {{this.$store.state.estimate.milesIncluded}} {{ $t("message.miles_included") }} </h4>
                        <p>{{this.$store.state.estimate.selectedCar?.Name}}</p>
                        <p>{{ $t("message.similar") }} </p>
                        <img :src="this.$imgBaseUrl + this.$store.state.estimate?.selectedCar?.Picture" width="120" />
                    </div>
                    <div class="col-md-6 pickup-div" >
                        <h6>{{ $t("message.pick_up") }}</h6>
                        <div class="address">{{ $t("message.address") }} </div>
                        <div>{{this.$store.state.estimate.pickupTime}} {{this.$store.state.estimate.pickupDate}}</div>
                    </div>
                    <div class="col-md-6">
                        <h6>{{ $t("message.return") }}</h6>
                        <div class="address" >{{ $t("message.address") }} </div>
                        <div>{{this.$store.state.estimate.dropOffTime}} {{this.$store.state.estimate.dropOffDate}}</div>
                    </div>
                </div>


                <div class="row info-form-row">
                    <div class="col-md-12">
                        <h4>{{ $t("message.your_info") }}</h4>
                    </div>
                    <div class="col-md-12">
                        <form >
                            <!-- first name input -->
                            <div class="form-outline mb-4">
                                <input type="text" v-model="form.firstName"  class="form-control" placeholder="First Name"/>
                                <div class="form-error" v-if="v$.form.firstName.$error">
                                <small v-if="v$.form.firstName.required.$invalid" >Invalid First Name</small>
                                </div>
                            </div>

                            <!-- last name input -->
                            <div class="form-outline mb-4">
                                <input type="text" v-model="form.lastName"  class="form-control" placeholder="Last Name" />
                                <div class="form-error" v-if="v$.form.lastName.$error">
                                <small v-if="v$.form.lastName.required.$invalid" > Invalid Last Name</small>
                                </div>
                            </div>


                            <!-- phone input -->
                            <div class="form-outline mb-4">
                                <tel-input-field  v-model="form.phone" @onChange="getPhoneNumber"></tel-input-field>
                                <div class="form-error" v-if="v$.form.phone.$error" >
                                <small   v-if="v$.form.phone.required.$invalid"> Phone Is Required</small>
                                <small  v-if="v$.form.phone.allowOnlyNumBrackets.$invalid" > Only Numbers Allowed</small>
                                <small   v-if="v$.form.phone.minLength.$invalid || v$.form.phone.maxLength.$invalid">
                                Must Be Valid Phone Number</small>
                                </div>
                            </div>


                            <!-- Email input -->
                            <div class="form-outline mb-4">
                                <input type="text" v-model="form.email" id="email" class="form-control" placeholder="Enter Email"/>
                                <div class="form-error" v-if="v$.form.email.$error" >
                                <small v-if="v$.form.email.required.$invalid"> Email Is Required</small>
                                <small v-if="v$.form.email.email.$invalid" > Not Valid Email</small>
                                </div>
                            </div>

                            <div class="form-group">
                                <small>Upload Drivings License <span class="red">(Driving license information should be clearly visible)</span></small>
                                <input type="file" class="form-control" @change="onFileSelected" placeholder="Picture">
                                <div class="form-error" v-if="v$.form.drivingLicense.$error" >
                                    <small v-if="v$.form.drivingLicense.required.$invalid"> Driving License Is Required</small>
                                    <small v-if="v$.form.drivingLicense.allowedImageSizeFormats.$invalid"> Only JPEG or PNG Formats Under 4MB Allowed</small>
                                </div>
                            </div>


                        </form>
                        <div class="form-outline mb-4">
                            <div class="form-check form-check-inline" style="margin-right: 40px;">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="credit" value="1" v-model="form.cardType">
                                <label class="form-check-label" for="credit">{{ $t("message.credit_card") }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="debit" value="2.2" v-model="form.cardType">
                                <label class="form-check-label" for="debit">{{ $t("message.debet_card") }}</label>
                            </div>
                            <div class="form-error" v-if="v$.form.cardType.$error" >
                                <small v-if="v$.form.cardType.required" >{{ $t("message.card_type") }}</small>
                            </div>

                        </div>

                        <div  class="form-outline mb-4" >
                            <div  id="stripe-element-mount-point" class="form-control">
                            </div>
                            <div class="form-error"  v-if="this.paymentCardValidations.errorMessage">
                                <small v-if="this.paymentCardValidations.errorMessage"> {{this.paymentCardValidations.errorMessage}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 estimate-div">
                <div class="all-charges-and-taxes">
                    <div class="estimate-details" v-if="!!this.$store.state.estimate.youngDriverFeeTotal">
                        <span>Young Driver Fee: </span>
                        <span class="pull-right">${{this.$store.state.estimate.youngDriverFeeTotal}}</span>
                    </div>
                    <div class="estimate-details" v-if="!!this.$store.state.estimate.prePaidRefuelingCost">
                        <span>Prepaid refuel: </span>
                        <span class="pull-right">{{this.$store.state.estimate.prePaidRefuelingCost ? this.$store.state.estimate.prePaidRefuelingCost : 'No'}}</span>
                    </div>
                    <div class="estimate-details">
                        <span>Base Rate: </span>
                        <span class="pull-right">${{this.roundNumbers(this.$store.state.estimate.carCost)}}</span>
                    </div>

                    <div class="estimate-details">
                        <span class="btn-link" data-toggle="collapse" href="#fee-details" role="button" aria-expanded="false" aria-controls="collapseExample">Taxes & Fees: </span>
                        <span class="pull-right">${{this.roundNumbers(this.$store.state.estimate.totalTaxAndFees)}}</span>
                        <div class="collapse" id="fee-details">
                            <div class="card card-body">
                                <div>7.6% Sales Tax</div>
                                <div>9.5% Tourism Tax</div>
                                <div>$4/Day Registration Fee</div>
                                <div>20% Rental Facility Fee</div>
                            </div>
                        </div>
                    </div>

                    <div class="estimate-details" v-if="isPromoCodeUsed">
                        <span>Promo Code: </span>
                        <span class="pull-right"> -${{this.form.promoCodeNameObject.price_discount}}</span>
                    </div>

                    <div class="estimate-details" v-if="this.rcli || this.ldw">
                        <span>Insurance:<span class="small">Due Upon Pick-Up </span> </span>
                        <span class="pull-right">${{this.totalInsuranceCost}}</span>
                    </div>
                    <div class="estimate-details cost-red">
                        <span>Refundable Deposit: <span class="small">Due Upon Pick-Up</span></span>
                        <span class="pull-right">${{this.roundNumbers(this.$store.state.estimate.deposit * form?.cardType) }}</span>
                    </div>
                </div>

                <div class="estimate-details btn-primary cost-white" style="margin-top: 10px;">
                    <span>Total Due: </span>
                    <span class="pull-right">${{this.roundNumbers(this.$store.state.estimate.totalWithInsurance)}}</span>
                </div>

                <div class="final-pricing-and-deposits" >

                    <div class="estimate-details" v-if="this.rcli || this.ldw" >
                        <span>Due Upon Pick-Up: </span>
                        <span class="pull-right">${{this.totalInsuranceCost}}</span>
                    </div>


                    <div class="estimate-details" >
                        <span>Due Now <span class="small">This is Rental Cost</span> </span>
                        <span class="pull-right">${{this.roundNumbers(this.$store.state.estimate.totalWithOutInsurance)}}</span>
                    </div>
                </div>



                <hr/>

                <div class="promo-code-section">
                    <a class="promo-code-text" href="#promo-code-fold" data-toggle="collapse" role="button" aria-expanded="false"> Enter Promo Code</a>

                    <div class="collapse rental-insurance-info" id="promo-code-fold" >
                        <div class="card card-body" v-if="!this.isPromoCodeUsed">
                            <div class="input-group">
                                <input type="text" v-model="promoCodeEntered" class="form-control" placeholder="Enter promo code">
                                <button class="btn btn-primary" @click="applyPromoCode()" >Apply</button>
                            </div>
                        </div>

                        <div class="card card-body" v-if="this.isPromoCodeUsed">
                            <div class="input-group">
                                Promo Code: {{this.form.promoCodeNameObject.name}}
                            </div>
                        </div>

                    </div>

                </div>

                <div class="insurance-div-wrapper">
                    <div class="estimate-details-insurance" >
                        <span>If you have no insurance or more then $500 deductible Please purchase rental insurance <a href="#" @click="openRentalCover">here</a> </span>
                        <!--<input type="checkbox" v-model="this.ldw" v-on:change="calculateLdw()"/>-->
                        <!--<a class="small"  href="#rental-insurance-info" data-toggle="collapse" role="button" aria-expanded="false"> More Info</a>-->
                        <!--<span class="pull-right">$ {{ this.$store.state.insurancePrices[0].loss_damage_waiver }}/Day </span>-->

                        <!--<div class="collapse rental-insurance-info" id="rental-insurance-info">-->
                            <!--<div class="card card-body">-->
                                <!--<div> <i class="fa fa-check"  aria-hidden="true"></i> $0 out of pocket</div>-->
                                <!--<div> <i class="fa fa-check"  aria-hidden="true"></i> Collision Damage Waiver UpTo $35,000.  </div>-->
                                <!--<div> <i class="fa fa-check"  aria-hidden="true"></i> Covered Additional Dirvers</div>-->
                                <!--<div> <i class="fa fa-check"  aria-hidden="true"></i> Loss of Use Fees For Rental Company</div>-->
                                <!--<div> <i class="fa fa-check"  aria-hidden="true"></i> Avoid paying for damage to our vehicle</div>-->
                            <!--</div>-->
                        <!--</div>-->

                    </div>
                    <div class="estimate-details-insurance" >
                        <span>Rental Liability Insurance </span>
                        <input type="checkbox" v-model="this.rcli" v-on:change="calculateRcli()"/>
                        <a class="small" href="#liability-insurance-info" data-toggle="collapse" role="button" aria-expanded="false"> More Info</a>
                        <span class="pull-right">$ {{this.$store.state.insurancePrices[0].rental_liability_insurance }}/Day</span>

                        <div class="collapse rental-insurance-info" id="liability-insurance-info">
                            <div class="card card-body">
                                <div> <i class="fa fa-check"  aria-hidden="true"></i> Bodily Injury - Per Person - $25,000</div>
                                <div> <i class="fa fa-check"  aria-hidden="true"></i> Bodily Injury - Aggregate - $65,000</div>
                                <div> <i class="fa fa-check"  aria-hidden="true"></i> Property Damage - $15,000</div>
                            </div>
                        </div>

                    </div>
                    <div class="insurance-success" v-if="this.ldw && this.rcli">
                        <p >You are fully protected</p>
                    </div>
                    <div class="insurance-warning" v-else>
                        <p >If you do not have personal insurance coverage, it is mandatory to purchase rental and liability insurance</p>
                    </div>


                </div>

                <div class="estimate-details-insurance" >
                    <span class="cost-red">No I have My Personal Insurance </span>
                    <input type="checkbox" href="#insurance-decline" data-toggle="collapse" role="button" aria-expanded="false" v-model="this.insurance_decline"  v-bind:disabled="insuranceDecline_el"/>
                </div>
                <div class="collapse insurance-decline" id="insurance-decline">
                    <div class="card card-body" style="float: left; font-size: 15px">
                        <h5>You will need to provide the following:</h5>
                        <p>
                            Proof of Personal Insurance Coverage (Declaration Page)
                            Comprehensive and Liability insurance with a minimum deductible of $500
                        </p>
                    </div>
                </div>
                <div class="terms-conditions">
                   <input type="checkbox" v-model="this.agree_conditions"/>   I agree <a target="_blank" href="terms&conditions.pdf">Terms and Conditions</a>
                </div>

                <!-- Submit button -->
                <div>
                    <button type="submit" class="btn btn-primary btn-block mb-4" v-bind:disabled="disablePayNowBtn" @click="preReservationValidation()">Pay Now</button>
                </div>
            </div>

        </div>

        <general-modal
                :modalActive="modalActive"
                @close="closeModal"
                :headerName="'You Are Missing Out A gread Deal!!!'"
        >

            <div class="modal-insurance-warning">

                <div class="rental-insurance-info">
                    <p class="modal-insurance-warning-header">Premier Car Rental Insurance For Only ${{ this.$store.state.insurancePrices[0].loss_damage_waiver }}/Day </p>
                    <div class="card card-body">
                        <div> <i class="fa fa-check"  aria-hidden="true"></i> $0 out of pocket</div>
                        <div> <i class="fa fa-check"  aria-hidden="true"></i> Collision Damage Waiver UpTo $50,000.  </div>
                        <div> <i class="fa fa-check"  aria-hidden="true"></i> Trip Interruption Protection</div>
                        <div> <i class="fa fa-check"  aria-hidden="true"></i> Baggage Loss/Damage Up to $1,000</div>
                        <div> <i class="fa fa-check"  aria-hidden="true"></i> Avoid paying for damage to our vehicle</div>
                    </div>
                </div>
                <div class="rental-insurance-info" >
                    <div class="or">Or</div>
                    <p class="modal-insurance-warning-header">Liability Insurance For Only ${{this.$store.state.insurancePrices[0].rental_liability_insurance }}/Day</p>
                    <div class="card card-body">
                        <div> <i class="fa fa-check"  aria-hidden="true"></i> Bodily Injury - Per Person - $200,000</div>
                        <div> <i class="fa fa-check"  aria-hidden="true"></i> Bodily Injury - Aggregate - $1,000,000</div>
                        <div> <i class="fa fa-check"  aria-hidden="true"></i> Property Damage - $10,000</div>
                    </div>
                </div>

            </div>

            <div class="more-padding">
                <button class="btn btn-primary" @click="continueReservation()" >Proceed</button>
                <button class="btn btn-secondary" @click="cancelReservation()" >Back</button>
            </div>

        </general-modal>

    </div>

    <app-footer style="margin-top: 40px;" />

</template>
<script>
    import { ref } from 'vue'
    import GeneralModal from '../src/components/modal/GeneralModal.vue';
    import { Reservation } from '../src/types';
    import appHeader2 from '../src/components/AppHeader2.vue'
    import appFooter from '../src/components/appFooter.vue'
    import telInputField from '../src/components/tel-input-field.vue'
    import { useVuelidate } from '@vuelidate/core';
    import store from '../src/store/index';
    import moment from 'moment'
//    import { loadStripe } from "@stripe/stripe-js";

    import { required, minLength, maxLength, between, email, sameAs, numeric } from '@vuelidate/validators'

    const style = {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#000000'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    };


    let stripe = null;
    let elements = null;
    let card = null;


    const allowOnlyNumBrackets = (value) => {
        // regex only valid if first three digit is surrounded by round brackets and has only numbers
        const regex = /^\(\d{3}\)[\d()]*$/;
        return regex.test(value);
    };

    const allowedImageSizeFormats = (value) => {
        if((value.type === "image/jpeg" || value.type === 'image/png') && value.size <= '4000000') {
            return true;
        }else  {
            return false;
        }
    };

    export default {
        name: 'ReservationPage',


        mounted() {
            stripe = Stripe(process.env.VUE_APP_STRIPE_PRODUCTION_PUBLIC_KEY);
//         stripe = Stripe(process.env.VUE_APP_STRIPE_TEST_PUBLIC_KEY);
            elements = stripe.elements();
            card = elements.create("card", { style: style });
            card.mount("#stripe-element-mount-point");
            card.addEventListener("change", (event) => {
                // Get a reference to the validation error element.
                // If there are errors in the input, display them in the error element.
                this.paymentCardValidations.errorMessage = event.error?.message;
            });
        },


        data: function () {
            return {
                v$: useVuelidate(),
                ldw: false,
                rcli: false,
                insurance_decline: false,
                agree_conditions: false,
                insuranceDecline_el: false,
                modalActive: false,
                showPaymentLoader: false,
                form: {
                    firstName: this.$store.state.user ? this.$store.state.user.first_name : null,
                    lastName: this.$store.state.user ? this.$store.state.user.last_name : null,
                    phone: this.$store.state.user ? this.$store.state.user.phone : null,
                    countryCode: null,
                    email: this.$store.state.user ? this.$store.state.user.email : null,
                    cardType: null,
                    drivingLicense: null,
                    promoCodeNameObject: null
                },
                ldwCost: 0,
                rcliCost: 0,
                totalInsuranceCost: 0,
                promoCodeEntered: null,
                isPromoCodeUsed: false,
                paymentCardValidations: {
                  errorMessage: 'Empty Payment Field',
                },
                bindProps:{
                    mode: 'international'
                }
            }

        },

        validations () {
            return {
                form: {
                    firstName: { required }, // Matches this.firstName
                    lastName: { required }, // Matches this.lastName
                    phone: { required, minLength: minLength(8), maxLength: maxLength(15), allowOnlyNumBrackets},
                    email: {
                        required,
                        email
                    },
                    cardType: { required, },
                    drivingLicense: {
                        required,
                        allowedImageSizeFormats,
                    },
                },
            }

        },

        components: {
            appFooter,
            appHeader2,
            GeneralModal,
            telInputField,
        },

        computed: {
            disablePayNowBtn() {
                if (this.agree_conditions) {
                    if (this.insurance_decline) {
                        return false;
                    } else if (this.ldw && this.rcli) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },

        },

        methods: {
            getPhoneNumber(value) {
                this.form.phone = value.number;
                this.form.countryCode = value.countryCode;
            },

            onFileSelected(event) {
                console.log(event, "image");
                this.form.drivingLicense = event.target.files[0];
            },

            roundNumbers(input) {
                return input?.toFixed(2);
            },

            applyPromoCode() {
                let promoCodeFormData = new FormData();
                promoCodeFormData.append('promoCodeEntered', this.promoCodeEntered);
                this.$store.dispatch('findPromoCode', promoCodeFormData).then(
                    response => {
                        if(response.data.status === 'success') {
                            if(moment().isBefore(response.data.promo.expiration_date)){
                                this.$store.state.estimate.totalWithOutInsurance -= parseInt(response.data.promo.price_discount);
                                this.form.promoCodeNameObject = response.data.promo;
                                this.isPromoCodeUsed = true;
                                this.$store.dispatch('toastMessage',  {type: 'success', msg: "$" + response.data.promo.price_discount +' discount was applied' })
                            } else {
                                this.$store.dispatch('toastMessage',  {type: 'error', msg: "Promo code is expired"})
                            }
                        } else {
                            this.$store.dispatch('toastMessage',  {type: 'error', msg: "Promo code is incorrect"})
                        }
                    }
                );
            },

            calculateLdw() {
                if(this.ldw) {
                    this.ldwCost = (this.$store.state.estimate.totalDays + 1) * this.$store.state.insurancePrices[0].loss_damage_waiver;
                    this.$store.state.estimate.totalWithInsurance += this.ldwCost;
                } else {
                    this.$store.state.estimate.totalWithInsurance -= this.ldwCost;
                    this.ldwCost = 0;
                }
                this.calculateTotalInsuranceCost();
                this.checkIfInsurancesSelected();
            },
            calculateRcli() {

                if(this.rcli) {
                    this.rcliCost = (this.$store.state.estimate.totalDays) * this.$store.state.insurancePrices[0].rental_liability_insurance;
                    this.$store.state.estimate.totalWithInsurance += this.rcliCost;
                }else {
                    this.$store.state.estimate.totalWithInsurance -= this.rcliCost;
                    this.rcliCost = 0;
                }
                this.calculateTotalInsuranceCost();
                this.checkIfInsurancesSelected();
            },

            calculateTotalInsuranceCost() {
                this.totalInsuranceCost = this.rcliCost + this.ldwCost;
            },

            checkIfInsurancesSelected() {
                if(this.rcli && this.ldw){
                    this.insuranceDecline_el = true;
                    this.insurance_decline = false;

                } else {
                    this.insuranceDecline_el = false;
                }
            },

            preReservationValidation() {
                this.v$.form.$validate();
                if(!this.v$.form.$error && !this.paymentCardValidations.errorMessage && this.$store.state.estimate.selectedCar) {
                    if(this.ldw && this.rcli)
                    {
                        this.continueReservation()
                    } else {
                        this.modalActive = true;
                    }
                }
            },


            continueReservation: async function () {
                this.showPaymentLoader = true;
                let reservation = new Reservation();
                reservation.car_id = this.$store.state.estimate.selectedCar.Id;
                reservation.car_name = this.$store.state.estimate.selectedCar.Name;
                reservation.user_id = this.$store.state.user.id ? this.$store.state.user.id : '';
                reservation.userFirstName = this.form.firstName;
                reservation.userLastName = this.form.lastName;
                reservation.userEmail = this.form.email;
                reservation.userPhone = this.form.phone;
                reservation.userPhoneCountryCode = this.form.countryCode;
                reservation.startDate = moment(this.$store.state.estimate.pickupDate  + ' ' + this.$store.state.estimate.pickupTime ).format("YYYY-MM-DD HH:mm:ss");
                reservation.endDate = moment(this.$store.state.estimate.dropOffDate + ' ' +  this.$store.state.estimate.dropOffTime).format("YYYY-MM-DD HH:mm:ss");
                reservation.totalPriceWithInsurance = this.$store.state.estimate.totalWithInsurance;
                reservation.totalPriceWithoutInsurance = this.$store.state.estimate.totalWithOutInsurance;


                reservation.totalTaxAndFees = this.roundNumbers(this.$store.state.estimate.totalTaxAndFees);
                reservation.carBaseRate = this.roundNumbers(this.$store.state.estimate.carCost);


                reservation.deposit = parseFloat((this.$store.state.estimate.deposit * this.form.cardType).toFixed(2));
                reservation.prepaidFueling = this.$store.state.estimate.prePaidRefuelingCost;
                reservation.milesIncluded = this.$store.state.estimate.milesIncluded;
                reservation.youngDriverFee = this.$store.state.estimate.youngDriverFeeTotal;
                reservation.checked_in = false;
                reservation.checked_out = false;
                reservation.isLastMinuteBooking = this.$store.state.estimate.isLastMinuteBooking;
                reservation.LDW = this.ldw;
                reservation.RCLI = this.rcli;
                reservation.rcliCost = this.rcliCost;
                reservation.ldwCost = this.ldwCost;
                reservation.cardType = this.form.cardType == 1 ? 'credit' : 'debit';
                reservation.drivingLicense = this.form.drivingLicense;
                if(this.isPromoCodeUsed) {
                    reservation.promoCodeName = this.form.promoCodeNameObject.code;
                } else {
                    reservation.promoCode = '';
                }


                // Create a new FormData object
                const reservationFormData = new FormData();

                // Iterate over the properties of the reservation object and append them to the FormData
                for (let key in reservation) {
                    reservationFormData.append(key, reservation[key]);
                }

                this.closeModal();
                try {
                    const result = await stripe.createToken(card);
                    reservationFormData.append("stripeToken", result.token.id)
                    this.$store.dispatch('submitStripeReservation', reservationFormData).then(
                        response => {
                            this.showPaymentLoader = false;
                            if(response.data.status === 'success') {
                                window.location.replace(response.data.receipt_url);
                            } else {
                                this.$store.dispatch('toastMessage',  {type: 'error', msg: response.data.msg})
                            }
                        }
                    );
                } catch (error) {
                    this.showPaymentLoader = false;
                    console.log('error ', error)
                }
            },

            cancelReservation() {
                this.closeModal();
            },

            closeModal() {
                    this.modalActive = false;
            },
            openRentalCover() {
                const url = "https://rentalcover.com";
                window.open(url, "_blank");
            }

        }


    }

</script>
<style lang="scss" scoped>
    .container {
        .modal-insurance-warning {
            font-size: 14px;

            .rental-insurance-info {
                .card-body {
                    background-color: #ffeb3b ;
                    padding: 10px;
                    p {
                        font-size: 14px;
                        padding: 12px;
                        border-radius: 5px;
                        margin-bottom: -7px;
                    }
                    h5 {
                        margin-bottom: 0px;
                    }
                }

                .modal-insurance-warning-header {
                    color: #4CAF50;
                    font-size: 17px;

                }
                .or {
                    font-size: 18px;
                }
            }
        }


        .address {
            font-size: 13px;
        }

        .loader-div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.7);
            z-index: 1;



            .loader {
                position: absolute;
                z-index: 2;
                border: 8px solid #f3f3f3; /* Light grey */
                border-top: 8px solid #ff992e; /* Blue */
                border-radius: 50%;
                width: 40px;
                height: 40px;
                animation: spin 2s linear infinite;
            }
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        }





        margin-top: 70px;
        .row {
            .pickup-dropoff-row {
                border-bottom: 2px dotted;
                padding: 10px;
                .pickup-div {
                    border-right: 2px dotted;
                }

                .estimate-subtitle-div {
                    margin-bottom: 20px;

                    .days-miles {
                        letter-spacing: 2px;
                        color: green;
                        font-weight: 565;
                    }
                }
            }
            .red {
                color: red;
                font-size: 12px;
            }
            .estimate-div {
                border-left: 2px dotted;
                padding: 5px;
                font-size: 16px;

                .final-pricing-and-deposits {
                    width: 80%;
                    margin-left: 70px;
                    font-size: 16px;

                    div {
                        margin: 5px;
                    }
                }
                .all-charges-and-taxes {
                    border: 2px dotted;
                    border-radius: 3px;
                    padding: 5px;
                }


                .promo-code-section {
                    padding: 10px;
                    .promo-code-text {
                        font-size: 15px;
                    }
                }


                .insurance-div-wrapper {
                    border: 1px dotted;
                    border-radius: 4px;
                    padding:10px;


                    .insurance-warning {
                        background-color: #ffeb3b ;

                        p {
                            font-size: 12px;
                            padding: 12px;
                            border-radius: 5px;
                        }
                    }

                    .insurance-success {
                        background-color: #4CAF50  ;

                        p {
                            font-size: 16px;
                            padding: 10px;
                            border-radius: 5px;
                            color: white;
                            font-weight: 501;
                        }
                    }
                }

                .insurance-decline {
                    .card-body {
                        background-color: #ffeb3b ;
                        padding: 10px;
                        p {
                            font-size: 14px;
                            padding: 12px;
                            border-radius: 5px;
                            margin-bottom: -7px;
                        }
                        h5 {
                            margin-bottom: 0px;
                        }
                    }
                }

                .estimate-details.btn-primary {
                    border-radius: 12px;
                    padding: 3px;
                }

                .estimate-details {
                    text-align: left;

                    .pull-right {
                        float: right;
                    }
                    
                    #fee-details {
                        .card-body {
                            color: green;
                        }
                    }
                    .btn-link {
                        text-decoration: none !important;
                    }
                    .card-body {
                        font-size: 15px;
                    }
                }

                .estimate-details-insurance {
                    text-align: left;
                    font-size: 16px;
                    margin-bottom: 10px;

                    .pull-right {
                        float: right;
                    }
                    .rental-insurance-info {
                        .card-body {
                            font-size: 16px;

                            .fa-check {
                                color: green;
                            }
                        }
                    }


                }

                .cost-green {
                    color: green;
                }
                .cost-red {
                    color: red;
                }
                .cost-white {
                    color: white;
                }
                #continue-btn {
                    position: absolute;
                    bottom: 0;
                }
                .precaution {
                    color: red;
                    font-size: 15px;
                }
                .terms-conditions {
                    float: left;
                    font-size: 17px;
                }
                .small {
                    font-size: 12px;
                    color: green;
                }


            }
        }

        .info-form-row {
            text-align: left;
            margin: 20px;
        }
        .modal-precaution {
            color: red;
            padding: 16px;
            line-height: inherit;
        }

        .more-padding {
            padding-bottom: 28px;

            .btn {
                margin: 10px;
            }
        }
    }

</style>