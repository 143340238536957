<template>

    <div class="row">

        <div  class="col-md-4 list-product" >
            <div class="card" header="List Product">
                <div class="card-deck"  v-for="car in getCars" :key="car.Id">
                    <product-item :item="car" @updateItem="updateProduct"/>
                </div>
                <h2 v-if="getCars.length < 1">No Products Found!</h2>
            </div>
        </div>

        <div  class="col-md-4 add-product-div">
            <add-product />
        </div>

    </div>



    <update-product-view v-if="selectedItem.Name && showModal" :showModal="showModal"  @closeModal="clsoeModal" :item="selectedItem"/>

</template>

<script>
    import UpdateProductView from '../../../views/UpdateProductView.vue'
    import UpdateProduct from './UpdateProduct.vue'
    import AddProduct from './AddProduct.vue'
    import ProductItem from './ProductItem.vue'
    import { toRaw } from 'vue'
    export default {
        data() {
            return {
                showModal: false,
                selectedItem: {},
            }

        },
        computed: {
            getCars() {
                let cars = this.$store.state.cars;
                return cars;
            }
        },

        components: {
            AddProduct,
            ProductItem,
            UpdateProductView,
        },
        methods: {
            deleteProduct(productId) {
                this.$emit('deleteProduct', productId);
            },
            updateProduct(id) {
                let car = this.getCars.find(car => car.Id === id)
                this.selectedItem = car;
                this.showModal = true;
            },
            clsoeModal() {
                this.showModal = !this.showModal;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .row {
        height: inherit;
    }
    .add-product-div {

        /*height: 91% !important;*/
    }
    .list-product {
        height: 99%;
    }
    .col-md-4 {
        overflow: auto;
        /*height: inherit;*/
        /*height: 99%;*/

        .card {
            height: inherit;
            overflow-x: hidden;
        }
    }

</style>
