<template>
    <general-modal :modalActive="showModal" :headerName="this.reservationStatus +' Reservation'" @close="closeModal" :showModalSelectError="false" :mediaModalHeight="'700px'">
        <div class="btn-section">
            <button class="managmentButtons" @click="toggleAdditionalChargeModal" >Make Additional Charges</button>
            <button  class="managmentButtons" @click="confirmBeforeSend">Delete&Resend Pre-Checkin Form</button>
            <button  class="managmentButtons" @click="printableContract">Print Contract</button>

        </div>

        <general-modal
                :modalActive="modalActive"
                @close="closeGeneralModal">
                        <form @submit.prevent="updateConfirm" id="reservation-form" style="">
                            <div class="form-group">
                                <label class="control-label">Charge Amount </label>
                                <input type="string" v-model="this.form.charge_amount" class="form-control" id="user-charge_amount" name="charge_amount" >
                            </div>

                            <div class="form-group">
                                <label class="control-label">Additional Charge Notes </label>
                                <div class="">
                                    <textarea rows="4" cols="50" v-model="this.form.additionalChargeNotes" class="form-control input-md" placeholder="Please Explain the charge..."
                                              name="additionalChargeNotes" id="additionalChargeNotes" ></textarea>
                                </div>
                            </div>
                            <button @click="confirmBeforeCharge">Make A Charge</button>
                        </form>



        </general-modal>

        <reservation-modal-view :item="item" :preCheckinInfo="this.preCheckinInfo" @close="closeModal" />
        <!--@close="closeModal"-->
    </general-modal>
</template>

<script>
    import GeneralModal from '../modal/GeneralModal.vue'
    import ReservationModalView from './ReservationModalView.vue'
    import moment from 'moment'
    export default {
        props: ["showModal", "item"],
        name: 'ReservationModal',


        async mounted() {
               this.preCheckinInfo = await this.$store.dispatch('getPrecheckinInfo', this.item.charge_id);
        },


        data() {
            return {
                reservationStatus: this.reservationClassification(),
                preCheckinInfo: null,
                modalActive: false,
                form: {
                    charge_amount: null,
                    additionalChargeNotes: null,
                }
            }
        },
        components: {
            ReservationModalView,
            GeneralModal,
        },

        methods: {
            closeModal() {
                this.$emit("closeModal");
            },

            closeGeneralModal() {
                this.modalActive = false;
            },
            toggleAdditionalChargeModal() {
                this.modalActive = !this.modalActive;
            },

            printableContract() {
                if(this.item && this.preCheckinInfo) {
                    const reservationQuery = {
                        reservation: JSON.stringify(this.item),
                        preCheckinInfo: JSON.stringify(this.preCheckinInfo)
                    };
                const url = this.$router.resolve({
                    path: '/reservationContract',
                    query: reservationQuery,
                }).href;
                window.open(url, '_blank');

                } else {
                    this.$store.dispatch('toastMessage',  {type: 'error', msg: "Customer Has Not Completed Pre-Check-in Form Yet"});
                }
            },

            confirmBeforeSend() {
                // Display the alert dialog
                const result = window.confirm('Are you sure you want to delete and resend the Pre-Checkin Form? This action can cause loss of important data');
                if (result) {
                    // Run your function to resend the Pre-Checkin Form
                    this.deleteAndResendPreCheckinContract();
                }
            },

            confirmBeforeCharge() {
                const result = window.confirm('Are you sure you want Charge Customer Additional Amount. Please Make Sure Amount Is Correct');
                if (result) {
                    this.chargeAdditionalAmount();
                }
            },

            chargeAdditionalAmount() {
                let data = new FormData();
                data.append('userEmail', this.item.userEmail);
                data.append('charge_amount', this.form.charge_amount);
                data.append('notes', this.form.additionalChargeNotes);
                data.append('charge_id', this.item.charge_id);
                this.$store.dispatch('chargeCustomerAdditionalFee', data).then(
                    response => {
                        if(response.data.status === 'success') {
                            this.closeGeneralModal();
                            this.$store.dispatch('toastMessage',  {type: 'success', msg: response.data.msg})
                        } else {
                            this.$store.dispatch('toastMessage',  {type: 'error', msg: response.data.msg})
                        }
                    }
                );


            },

            deleteAndResendPreCheckinContract() {
                let data = new FormData();
                data.append('to', this.item.userEmail);
                data.append('charge_id', this.item.charge_id);
                data.append('body', `In order to make checkout fast, please complete Pre-Checkin Form: www.carspeedyrental.com/preCheckInInformationForm/${this.item.charge_id}`);
                this.$store.dispatch('deleteAndResendContract', data)
                    .then(
                    response => {
                        if(response.data.status === 'success') {
                            this.$store.dispatch('toastMessage',  {type: 'success', msg: "Email Was Res"})
                        } else {
                            this.$store.dispatch('toastMessage',  {type: 'error', msg: "Error  Try Again To Update Reservation"})
                        }
                    }
                );
            },

            reservationClassification() {
                const now = moment();
                const start = moment(this.item.startDate);
                const end = moment(this.item.endDate);
                if(!this.item.isActive) {
                    return 'Canceled'
                } else {
                    if (start.isSame(now, 'day')) {
                        if (this.item.checked_in && !this.item.checked_out) {
                            return 'In Progress';
                        } else if (!this.item.checked_in && this.item.checked_out) {
                            return 'Error';
                        } else if(!this.item.checked_in && !this.item.checked_out){
                            return 'Coming Up';
                        }
                    } if(end.isSame(now, 'day') &&  start.isBefore(now) && this.item.checked_in && !this.item.checked_out) {
                        return "Due Today";
                    }if(start.isBefore(now) && !this.item.checked_out && !this.item.checked_in) {
                        return 'No Show';
                    } if(start.isBefore(now) && end.isBefore(now) &&!this.item.checked_out && this.item.checked_in) {
                        return "Late"
                    } else if (start.isAfter(now) && !this.item.checked_out && !this.item.checked_in) {
                        return 'Future';
                    } else if (start.isBefore(now) && !this.item.checked_out) {
                        return 'In Progress';
                    } else if (start.isBefore(now) && this.item.checked_in && this.item.checked_out) {
                        return 'Past';
                    } else {
                        return 'Unknown';
                    }
                }

            },
        }
    };
</script>
<style lang="scss" scoped>
    .btn-section {
        button {
            display: block;
            margin: auto;
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: 600px) {
        .managmentButtons {
            font-size: 13px;
        }
    }

    @media screen and (min-width: 550px) and (max-width: 799px) {
        .modal-inner {
            height: 700px !important;
        }
    }



</style>