<template>
    <Modal :modalActive="isModalOpen" @close="closeModal"  :mediaModalHeight="'370px'">
        <div class="modal-body">
            <div class="container">
                <form action="#" class="bg-light p-5 contact-form">
                    <div class="form-group">
                        <input type="text" v-model="fullName" id="fullName" class="form-control" placeholder="Full Name">
                    </div>
                    <div class="form-group">
                        <input type="text" v-model="email" id="email" class="form-control" placeholder="Your Email">
                    </div>
                    <div class="form-group">
                        <input @click="getFirstLastName()" type="button" value="Apply" class="btn btn-primary  px-5">
                    </div>
                </form>
            </div>

        </div>
    </Modal>



    <div class="text-center" style="padding:20px;">
        <button type="button" class="btn btn-info btn_print" @click="printInvoice">Print</button>
        <!--<input type="button" id="rep" value="Print" class="btn btn-info btn_print">-->
    </div>


    <div class="container_content" id="container_content" >


        <div class="invoice-box">



            <table id="section-one" cellpadding="0" cellspacing="0" >
                <tr class="top">
                    <td colspan="2">
                        <h2>Invoice</h2>
                        <span id="current_date"></span>
                    </td>
                </tr>

                <tr class="information">
                    <td colspan="2">
                        <table>
                            <tr>
                                <td>
                                    SpeedRental LLC.<br />
                                    www.carspeedyrental.com<br />
                                    #2 suit, 1324 Pharaoh Cir,<br />
                                    Murray, UT 84123<br />
                                    Phone: 8015734248
                                </td>

                                <td id="customer">
                                    <b>To:</b>
                                    <span id="name">{{ fullName }} </span><br />
                                    <span id="printEmail">{{ email }}</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>

            <table class="table table-bordered infoTable" style="min-width: auto !important;">
                <thead>
                <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Pickup Time</th>
                    <th scope="col">Dropoff Time</th>
                    <th scope="col">Days</th>
                    <th scope="col">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td ><span id="description">{{ invoiceData?.description }}</span><p class="small">or similar</p></td>
                    <td id="pickupTime">{{ invoiceData?.pickupDate}} {{ invoiceData?.pickupTime }}</td>
                    <td id="DropoffTime">{{ invoiceData?.dropOffDate }} {{ invoiceData?.dropOffTime }}</td>
                    <td id="days">{{ invoiceData?.totalDays }}</td>
                    <td id="carCost"> {{ invoiceData?.carCost }}</td>
                </tr>
                </tbody>
                <div>
                    <small> Miles Included:  <b>{{invoiceData?.milesIncluded}}</b> </small>
                </div>
            </table>



            <table class="price-breakdown">
                <tr>
                    <td> Taxes and Fees:</td>
                    <td class="numbers">$<span id="totalTaxAndFees" class="text-120 text-secondary-d1">{{ invoiceData?.totalTaxAndFees  }}</span></td>
                </tr>
                <tr>
                    <td>Young Driver Fee:</td>
                    <td class="numbers"> $<span id="youngDriverFee" class="text-110 text-secondary-d1">{{ invoiceData?.youngDriverFeeTotal  }}</span></td>
                </tr>

                <tr v-if="invoiceData?.prePaidRefuelingCost">
                    <td>Pre-Paid Refueling:</td>
                    <td class="numbers"> $<span id="prePaidRefuelingCost" class="text-110 text-secondary-d1">{{ invoiceData?.prePaidRefuelingCost  }}</span></td>
                </tr>

                <tr class="red-it">
                    <td> Refundable Deposit:</td>
                    <td class="numbers">$<span id="refundableDeposite" class="text-150 text-success-d3 opacity-2">{{ invoiceData?.deposit  }}</span></td>
                </tr>

                <tr>
                    <td>Sub-Total:</td>
                    <td class="numbers"> $<span id="subTotal" class="text-150 text-success-d3 opacity-2">{{ invoiceData?.subTotal  }}</span></td>
                </tr>
                <tr class="green-it">
                    <td>Refund:</td>
                    <td class="numbers"> -$<span id="refund" class="text-150 text-success-d3 opacity-2">{{ invoiceData?.deposit  }}</span></td>
                </tr>
                <tr class="btn-primary">
                    <td>Total Amount:</td>
                    <td class="numbers"> $<span id="total" class="text-150 text-success-d3 opacity-2">{{ invoiceData?.total  }}</span></td>
                </tr>
            </table>
            <div class="note ">
                <p>Refund will only be issued after the end of the trip(once the vehicle is being returned clean)</p>
            </div>
            <div class="fees_note">
                <p>Taxes and Fees Include: 7.6% Sales Tax | 9.5% Tourism Tax | $4/Day Registration Fee | 20% Rental Facility Fee</p>
                <p>please see terms and conditions at www.carspeedyrental.com</p>
            </div>

        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { computed } from 'vue'
    import Modal from '../src/components/modal/modal.vue';
    import router from 'vue-router'
    export default {
        name: 'Printable',
        props: {

        },
        data() {
            return {
                isModalOpen: true,
                fullName: null,
                emailName: null,
                invoiceData: null
            }


        },
        components: {
            Modal,
        },
        mounted() {
            this.getUrlData();
        },
        methods: {
            closeModal() {
                this.isModalOpen = false;
            },
            getFirstLastName() {
                this.closeModal();

            },
            getUrlData() {

                let url = document.location.href.replace(/%20/g, " "),
                    params = url.split('?')[1].split('&'),
                    data = {}, tmp;
                for (var i = 0, l = params.length; i < l; i++) {
                    tmp = params[i].split('=');
                    data[tmp[0]] = tmp[1];
                }
                this.invoiceData = data;
//                return data;
            },

            detectMob() {
                return (window.innerWidth <= 500 );
            },

            printInvoice() {
                let element = document.getElementById('container_content');

                let opt =
                    {
                        margin:       1,
                        filename:     'pageContent_invoice.pdf',
                        image:        { type: 'jpeg', quality: 0.98 },
                        html2canvas:  { scale: 2 },
                        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
                    };

                // New Promise-based usage:
                html2pdf().set(opt).from(element).save();
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
    .notransition {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        -ms-transition: none !important;
        transition: none !important;
    }
    .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
    }

    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }
    .top {
        text-align: center;
    }

    .invoice-box table td {
        padding: 5px;
        vertical-align: top;
    }
    .title2 {
        text-align: right;
    }
    thead {
        color: black !important;
    }
    .table-bordered th, .table-bordered td, .table-bordered tr {
        text-align: center;
        padding: 10px !important;
    }
    .table-bordered th {
        font-weight: 500 !important;
    }

    .small {
        font-size: 12px;
        margin-bottom: -10px;
    }
    #customer {
        text-align: right;
    }
    #section-one {
        margin-bottom: 70px;
    }
    .price-breakdown {
        width: auto !important;
        margin-right: 8px;
        margin-left: auto !important;
    }
    .numbers {
        text-align: end;
    }
    .red-it {
        color: red;
        font-weight: 300;
    }
    .green-it {
        color: green;
        font-weight: 300;
    }
    .note {
        text-align: center;
        font-size: 13px;
        margin-top: 63px;
    }
    .fees_note {
        text-align: center;
        font-size: 13px;
        margin-top: 25px;
    }
    .p-5 {
        margin-top: 50px;
    }

    @media screen and (max-width: 800px) {
        .p-5 {
            padding: 0px !important;
        }
    }


</style>
