<template>
    <div class="container">

        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Total Price</th>
                    <th>Miles Included</th>
                    <th>Deposit</th>
                    <th>LDW</th>
                    <th>RCLI</th>
                    <th>Receipt Url</th>
                </tr>
                </thead>
                <tbody>
                <my-reservation-list-view v-for="reservation in reservations" :reservation="reservation" />
                </tbody>
            </table>
        </div>


    </div>
</template>
<script>
    import MyReservationListView from './MyReservationListView.vue';
    export default {
        name: 'MyReservationsListView',
        props: ["reservations"],
        data() {
            return {
//                reservations: [],
            }
        },
        methods: {

        },
        components: {
            MyReservationListView,
        }
    }
</script>
<style lang="scss" scoped>
    // Adjust the background color of the table header
    table {
        thead th {
            background-color: #333;
            color: white;
        }
    }

    // Add hover effect to table rows
    table {
        tbody tr:hover {
            background-color: #f5f5f5;
        }
    }
</style>