<template>
    <div class="row">
        <div class="markings">
            <div>
                <span class="text"> Coming Up: &nbsp; </span><span class="comingup instructionals"> </span>
            </div>
            <div>
                <span class="text">In Progress &nbsp; </span><span class="inprogress instructionals"></span>
            </div>
            <div>
                <span class="text"> Late: &nbsp; </span> <span class="late instructionals"></span>
            </div>
            <div>
                <span class="text"> Finished: &nbsp; </span> <span class="finished instructionals"></span>
            </div>
            <div>
                <span class="text"> No Show: &nbsp; </span> <span class="noShow instructionals"></span>
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <div class="input-group">
            <input class="form-control border-end-0 border" placeholder="Last 6 of Charge Id" type="search" v-model="charge_id">
            <span class="input-group-append">
                    <button class="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5" type="button">
                        <i class="fa fa-search"></i>
                    </button>
                </span>
        </div>
    </div>

    <div class="pl-table ">
        <h4>Not Confirmed Reservations</h4>
        <div class="pl-thead tall">
            <reservations-view-table-header/>
        </div>
        <div class="pl-tbody ">
            <div  v-for="eachReservation in this.notConfirmedReservations" :key="eachReservation.id">
                <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
            </div>
        </div>
    </div>


    <div class="pl-table ">
        <h4>Late to Return Reservations<small class="small">(Confirmed)</small></h4>
        <div class="pl-thead tall">
            <reservations-view-table-header/>
        </div>
        <div class="pl-tbody ">
            <div  v-for="eachReservation in this.lateToReturnReservations" :key="eachReservation.id">
                <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
            </div>
        </div>
    </div>

    <div class="pl-table ">
        <h4>Finishing Reservations <small class="small">(Confirmed)</small></h4>
        <div class="pl-thead tall">
            <reservations-view-table-header/>
        </div>
        <div class="pl-tbody ">
            <div  v-for="eachReservation in this.finishingReservations" :key="eachReservation.id">
                <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
            </div>
        </div>
    </div>

    <div class="pl-table ">
        <h4>Current Reservations<small class="small">(Confirmed)</small></h4>
        <div class="pl-thead tall">
            <reservations-view-table-header/>
        </div>
        <div class="pl-tbody ">
            <div  v-for="eachReservation in this.currentReservations" :key="eachReservation.id">
                <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
            </div>
        </div>
    </div>

    <div class="pl-table ">
        <h4>Future Reservations<small class="small">(Confirmed)</small></h4>
        <div class="pl-thead tall">
            <reservations-view-table-header/>
        </div>
        <div class="pl-tbody ">
            <div  v-for="eachReservation in this.futureReservations" :key="eachReservation.id">
                <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
            </div>
        </div>
    </div>

    <div class="pl-table ">
        <h4>Past Reservations<small class="small">(Confirmed)</small></h4>

            <div class="pl-thead tall">
                <reservations-view-table-header/>
            </div>
            <div class="pl-tbody ">
                <div  v-for="eachReservation in this.pastReservations" :key="eachReservation.id">
                    <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
                </div>
            </div>
    </div>


    <div class="pl-table ">
        <h4>Unknown Reservations</h4>
        <div class="pl-thead tall">
            <reservations-view-table-header/>
        </div>
        <div class="pl-tbody ">
            <div  v-for="eachReservation in this.unknownReservations" :key="eachReservation.id">
                <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
            </div>
        </div>
    </div>


    <div class="pl-table ">
        <h4>Canceled Reservations</h4>
        <div class="pl-thead tall">
            <reservations-view-table-header/>
        </div>
        <div class="pl-tbody ">
            <div  v-for="eachReservation in this.canceledReservations" :key="eachReservation.id">
                <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
            </div>
        </div>
    </div>


    <div class="pl-table ">
        <h4>All Reservations</h4>
        <div class="pl-thead tall" data-toggle="collapse" href="#all-reservations" role="button" aria-expanded="false" aria-controls="collapseExample">
            <reservations-view-table-header/>
        </div>
        <div class="pl-tbody collapse" id="all-reservations">
            <div  v-for="eachReservation in this.reservations" :key="eachReservation.id">
                <reservation-view :reservation="eachReservation" @selectAction="selectReservation" />
            </div>
        </div>
    </div>



    <!-- Modal for selected reservation-->
    <reservation-modal v-if="selectedReservation?.charge_id && modalActive" :showModal="modalActive"  @closeModal="closeModal" :item="selectedReservation"/>
</template>
<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex'
    import moment from 'moment'
    import ReservationView from './ReservationView.vue'
    import ReservationsViewTableHeader from './ReservationViewTableHeader.vue';
    import GeneralModal from '../modal/GeneralModal.vue'
    import ReservationModal from './ReservationModal.vue'
    import {charge_id} from "../../types";

    const now = moment();
    export default {
        name: 'ReservationsView',
        data() {
            const store = useStore()


            return {
                reservations: computed(() => {
                        return store.state.reservations.filter((reservation) => {
                            if(!this.charge_id) {
                                return reservation;
                            } else if(reservation.charge_id.slice(-6) === this.charge_id.slice(-6)) {
                                return reservation;
                            }
                        })
                    }),
                charge_id: null,
                modalActive: false,
                selectedReservation: null
            }

        },
        components: {
            ReservationView,
            ReservationsViewTableHeader,
            ReservationModal
        },

        computed: {
            notConfirmedReservations() {
                return this.reservations.filter((reservation) => {
                    if(!reservation.isConfirmed && reservation.isActive) {
                        return reservation;
                    }
                })
            },

            futureReservations() {
                return this.reservations.filter((reservation) => {
                    const start = moment(reservation.startDate);
                    if(start.isAfter(now, 'day') && !reservation.checked_out && !reservation.checked_in && reservation.isConfirmed) {
                        return reservation;
                    }
                })
            },

            lateToReturnReservations() {
                return this.reservations.filter((reservation) => {
                    const start = moment(reservation.startDate);
                    const end = moment(reservation.endDate);
                    if(start.isBefore(now) && end.isBefore(now, 'day') &&!reservation.checked_out  && reservation.isConfirmed) {
                        return reservation;
                    }
                })
            },

            // Reservations that are in progress/late/coming up
            currentReservations() {
                return this.reservations.filter((reservation) => {
                    const start = moment(reservation.startDate);
                    const end = moment(reservation.endDate);
                    if(reservation.isConfirmed) {
                        if (start.isSame(now, 'day')) {
                            if (reservation.checked_in && !reservation.checked_out) {
                                return reservation;
                            } else if (!reservation.checked_in && !reservation.checked_out) {
                                return reservation;
                            }
                        } else if(start.isBefore(now) && end.isAfter(now, 'day') && !reservation.checked_out) {
                            return reservation;
                        }
                    }

                })
            },

            finishingReservations() {
                return this.reservations.filter((reservation) => {
                    const end = moment(reservation.endDate) ;
                    if (end.isSame(now, 'day') && reservation.checked_in && !reservation.checked_out && reservation.isConfirmed) {
                        return reservation;
                    }
                })
            },

            // Past reservations/ unknown reservations/ and errors...
            pastReservations() {
                return this.reservations.filter((reservation) => {
                    const start = moment(reservation.startDate);
                    const end = moment(reservation.endDate) ;
                    if ((start.isBefore(now)  && reservation.checked_in && reservation.checked_out)  ||
                        (start.isBefore(now) && end.isBefore(now, 'day') && !reservation.checked_in && !reservation.checked_out)
                        && reservation.isConfirmed) {
                        return reservation;
                    }
                })
            },

            //Unknown reservations/ Rrrors in reservation
            unknownReservations() {
                return this.reservations.filter((reservation) => {
                    const start = moment(reservation.startDate);
                    if ((!reservation.checked_in && reservation.checked_out) || (reservation.checked_in && reservation.checked_out && start.isAfter(now)) ||
                        (reservation.checked_in && !reservation.checked_out && start.isAfter(now, 'day'))) {
                        return reservation;
                    }
                })
            },

            canceledReservations() {
                return this.reservations.filter((reservation) => {
                    const start = moment(reservation.startDate);
                    if (!reservation.isActive) {
                        return reservation;
                    }
                })
            },

        },

        methods: {
            searchFileter(reservation, charge_id) {
                if(reservation.charge_id === charge_id)
                    return reservation;
            },

            selectReservation(charge_id) {
                this.selectedReservation = this.reservations.find((reservation) => {return reservation.charge_id === charge_id})
                this.modalActive = true;
            },


            closeModal() {
                this.modalActive = false;
//                this.$store.state.selectedReservation = null;
            },

            searchReservation() {
                console.log(this.charge_id, 'herer')
            }

        }
    }
</script>
<style lang="scss" >

    .input-group {
        .input-group-append {
            .btn {
                z-index: 0;
            }
        }
    }

    .markings {
        display: inline-flex;
        .text {
            display: inline-flex;
            height: 30px;
        }

        span {
            /*display: inline-flex;*/
            /*width: 40px;*/
            /*height: 30px;*/
            /*margin-right: 50px;*/
        }

        .instructionals {
            display: inline-flex;
            width: 40px;
            height: 30px;
            margin-right: 50px;
        }

        .comingup {
            background-color: #3498db;
        }

        .inprogress{
            background-color:  #f39c12;
        }

        .late {
            background-color: #e74c3c;
        }

        .finished {
            background-color: #2ecc71  ;
        }

        .noShow {
            background-color: #7012f3 ;
        }

    }

    .pl-table {
        margin: 40px 0px 40px 0px;
        overflow-x: auto;

        .small {
            font-size:13px;
        }
        .row{
            margin: 5px 0;
            padding: 0 20px;
            align-items: center;
        }
        .col{
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .pl-thead {
            color: #979797;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 11px;
            margin: 15px 0;
            &.tall {
                background-color: #263338;
                margin: 0;
                padding: 5px 0;
                color: rgba(255, 255, 255, 0.7);
                text-transform: initial;
                .col {
                    border-right: 1px solid rgba(255, 255, 255, 0.2);
                    text-align: center;
                    align-self: stretch;
                    &:last-child {
                        border: none;
                    }
                    .large {
                        font-size: 21px;
                        color: #ffffff;
                    }
                }
            }
        }
        .pl-tbody {
            .row:hover {
                background-color: rgba(115, 115, 115, 0.77);
            }
            .row {
                background-color: #ffffff;
                padding-top: 7px;
                padding-bottom: 7px;
                color: #1e1e1e;
                font-size: 11px;
            }
            &.scroll {
                max-height: 100px;
                overflow-x: scroll;
            }
        }
    }

    @media (max-width: 767px) {
        .pl-table {
            .pl-thead, .pl-tbody {
                width: 950px;
            }

        }

        .markings {
            display: inline-flex;
            .text {
                display: inline-flex;
                height: 20px;
                font-size: 12px;
            }

            .instructionals {
                display: inline-flex;
                width: 35px;
                height: 25px;
                margin-right: 30px;
            }

            .comingup {
                background-color: #3498db;
            }

            .inprogress {
                background-color: #f39c12;
            }

            .late {
                background-color: #e74c3c;
            }

            .finished {
                background-color: #2ecc71;
            }

            .noShow {
                background-color: #7012f3;
            }
        }
    }

</style>