<template>
    <div class="item-div">
        <!-- Card image -->
        <div class="view overlay">
            <img class="card-img-top" :src="this.$imgBaseUrl + item.Picture"
                 alt="Card image cap">
        </div>
        <!-- Card content -->
        <div class="card-body">
            <!-- Title -->
            <h4 class="card-title">{{item.Name}}</h4>
            <!-- Text -->
            <p class="card-text">{{item.Description}}</p>

            <ul class="list-group list-group-flush">
                <li class="list-group-item">Price: {{item.Price}}</li>
                <li class="list-group-item">Manufacture Year: {{item.Year}} </li>
                <li class="list-group-item">MPG:  {{item.Mpg}}</li>
                <li class="list-group-item">ODO: {{item.Odo_reading}}</li>
                <li class="list-group-item" :style="[item.IsRunning ? {'color': 'green'} : {'color': 'red'}]">Running</li>
                <li class="list-group-item" :style="[item.IsCleaned ? {'color': 'green'} : {'color': 'red'}]">Cleaned</li>
            </ul>

            <!-- Button -->
            <button  class="btn btn-warning" @click="updateCar(item.Id)" >Update Car</button>
            <button  class="btn btn-danger" @click="deleteCar(item.Id)">Delete Car</button>
        </div>
    </div>

</template>

<script>
    import UpdateProduct from './UpdateProduct.vue'
    export default {
        name: 'ProductItem',
        props: ['item'],
        data() {
            return {
            }
        },
        components: {
            UpdateProduct
        },

        methods: {
            updateCar(id) {
                this.$emit("updateItem", id);
            },
            deleteCar(id) {
                this.$store.dispatch('deleteCar', id)
            }
        }

    }
</script>
<style lang="scss" scoped >
    .item-div {
        margin: 20px;
        border: 2px dotted #ff992e;
        padding: 15px;
    }

    .overlay {
        margin: auto;
        .card-img-top {
            width: 220px;
        }
    }

    .card-body {
        padding: 0px;
        button {
            margin: 15px;
        }
    }
</style>