<template>

    <div class="row" v-for="car in this.getCars()" :key="car.Id">
        <div class="col-md-2 car-img-div">
            <img  :src="this.$imgBaseUrl + car.Picture" width="120" />
        </div>
        <div class="col-md-10">
            <vue-inline-calendar   :selected-dates="getCarReservations(car.Id)" />

        </div>
    </div>


</template>

<script>
import VueInlineCalendar from '../vueInlineCalendar/components/VueInlineCalendar.vue';

    export default {
        name: 'CalendarView',
        components: {
            VueInlineCalendar
        },
        data() {
            return {
            }
        },

        methods: {
            getCars() {
                return this.$store.state.cars;
            },

            getCarReservations(car_id){
                let carReservations = this.$store.state.reservations.length ? this.$store.state.reservations.filter((reservation) => reservation.car_id === car_id && reservation.isConfirmed && reservation.isActive) : [];
                return this.generateCarReservationDatesArray(carReservations)
            },
            generateCarReservationDatesArray(reservations) {
                let allDates = [];

                reservations.forEach(reservation => {
                    let startDate = new Date(reservation.startDate);
                    let endDate = new Date(reservation.endDate);

                    while (startDate <= endDate) {
                        allDates.push(new Date(startDate));
                        startDate.setDate(startDate.getDate() + 1);
                    }
                });
                return allDates;
            }

        }

    }
</script>
<style lang="scss" scoped>
    .row {
        .car-img-div {
            @media screen and (max-width: 800px) {
                img {
                    position: relative !important;
                }
            }

            img{
                position: absolute;
                margin: auto;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

        }
    }

</style>