<template>
    <footer class="ftco-footer ftco-bg-dark ftco-section" id="footer-section">
        <div class="container">
            <div class="row mb-5" >
                <div class="col-md">
                    <div class="ftco-footer-widget mb-4">
                        <h2 class="ftco-heading-2"> <router-link to="/" class="logo">Car<span>SpeedyRental</span></router-link></h2>
                        <!--<p>We are dedicated to provide affordable <br> car rental services, without compromising <br> the quality.</p>-->
                        <div class="block-23 mb-3">
                            <ul>
                                <li>
                                    <p><a target="_blank" href="terms&conditions.pdf">Terms and Conditions</a>.</p>
                                </li>

                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100082832427115"><span class="icon icon-facebook"></span><span class="text">Visit Us On Facebook</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md">
                    <div class="ftco-footer-widget mb-4">
                        <h2 class="ftco-heading-2">Have a Questions?</h2>
                        <div class="block-23 mb-3">
                            <ul>
                                <li><a href="#"><span class="icon icon-phone"></span><span class="text">+1 801 573 4248  </span></a></li>
                                <li><a href="#"><span class="icon icon-envelope"></span><span class="text">carspeedyrental@gmail.com</span></a></li>
                                <li><span class="icon icon-map-marker"></span><span class="text">4541S 700E, Suit 200B Millcreek UT, 84107</span></li>
                                <li><span class="icon icon-time-makes"></span><span class="text">Mon - Sat, 8:00am - 7:00pm</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'appFooter',
        props: {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .ftco-footer {
        font-size: 16px;
        background: #000;
        overflow: auto;
        /*padding: 7em 0;*/
        a {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.7);;
        }
        li {
            list-style: none;
        }

        .mb-5 {
            margin-bottom: 30px !important;
            margin-top: 50px;

            .block-23 {
                margin-left: -40px;
            }
        }

        .logo {
            text-transform: uppercase;
            font-weight: 700;
            color: #fff;
        }
        .ftco-heading-2 {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            /*text-align: left;*/

            span {
                color: #ff992e;
                font-weight: 800;
                font-size: 20px;
            }
        }
    }


</style>
