<template>
    <div style="background: gray" data-stellar-background-ratio="0.5">
        <appHeader/>
        <EstimateFormSection/>
    </div>

    <get-estimate-modal/>
    <!--<InventorySectionView/>-->
    <PricingSectionView/>
    <WhoWeAreSectionView/>



    <appFooter/>
</template>

<script>
    import appHeader from '../src/components/appHeader.vue'
    import appFooter from '../src/components/appFooter.vue'
    import EstimateFormSection from '../src/components/EstimateFormSection.vue'
    import InventorySectionView from './InventorySectionView.vue'
    import PricingSectionView from './PricingSectionView.vue'
    import WhoWeAreSectionView from './WhoWeAreSectionView.vue'
    import GetEstimateModal from '../src/components/GetEstimateModal.vue'

    export default {
        name: 'HomeView',
        props: {

        },
        components: {
            appHeader,
            appFooter,
            EstimateFormSection,
//            InventorySectionView,
            PricingSectionView,
            WhoWeAreSectionView,
            GetEstimateModal
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
