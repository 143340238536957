<template>
    <!-- start pricing section -->
    <section class="ftco-section ftco-cart" id="prices-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 heading-section text-center ftco-animate mb-5">
                    <h2 class="mb-2">Our Prices</h2>
                </div>
            </div>
            <div  class="unlimited">
                <p class="animate-title" >Go unlimited by purchasing unlimited mileage only for ${{this.$store.state.unlimtedMilesDailyFee}} a day</p>
            </div>
            <table-view class="table" />

        </div>

    </section>
    <!-- end pricing section -->
</template>
<script>
    import TableView from '../src/components/table/TableView.vue'

    export default {
        name: 'PricingSectionView',
        props: {

        },

        components: {
            TableView,
        }
    }

</script>
<style lang="scss">
    .table {
        overflow: auto;
    }
    .unlimited {
        font-weight: 700;
        margin-top: -40px;
        color: #1089ff;

        .animate-title {
            animation: blink;
            animation: zoom-in-zoom-out;
            animation-duration: 2s;
            animation-delay: 1s;
            animation-iteration-count: infinite;
        }
        @keyframes zoom-in-zoom-out {
            0% {
                transform: scale(1, 1);
            }
            50% {
                transform: scale(1.2, 1.2);
            }
            100% {
                transform: scale(1, 1);
            }
        }

    }
</style>