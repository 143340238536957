import {createWebHistory, createRouter } from 'vue-router';
import HomeView from '../../views/HomeView.vue';
import printable from '../../views/Printable.vue';
import login from '../../views/Login.vue'
import Register from '../../views/Register.vue'
import AdminPanel from '../../views/AdminPanel.vue'
import VehiclesView from '../components/adminPannelComponents/VehiclesView.vue'
import ReservationView from '../components/adminPannelComponents/ReservationsView.vue';
import CalendarsView from '../components/adminPannelComponents/CalendarsView.vue'
import MyAccount from '../../views/MyAccount.vue';
import ChangePassword from '../../views/ChangePassword.vue';
import PersonalInfo from '../../views/PersonalInfo.vue';
import MyReservations from '../../views/MyReservations.vue'
import ReservationPage from '../../views/ReservationPage.vue';
import ReservationContractView from '../components/adminPannelComponents/ReservationContractView.vue';
import preCheckInInformationForm from '../../views/PreCheckInInfoView.vue';
import errorPage from '../../views/ErrorPageView.vue';
import store from '../store';
import {charge_id} from "../types";


const router = createRouter({
    // scrollBehavior(to, from, savedPosition) {
    //     // always scroll to top
    //     return { top: 0 }
    // },
    history: createWebHistory(),
    base: '/vue_speedyrental/',
    routes: [
        { path: '/', name: 'Home', component: HomeView },
        { path: '/printable', name: 'printable', component: printable },
        { path: '/login', name: 'Login', component: login, meta: { needsAuthentification: false }},
        { path: '/register', name: 'Register', component: Register, meta: { needsAuthentification: false }},
        { path: '/myAccount', name: 'MyAccount', component: MyAccount, meta: { needsAuthentification: true, needsAuthorization: false },
            children: [
                {
                    path: '/myAccount',
                    name: 'MyAccount',
                    component: PersonalInfo,
                    meta: {
                        needsAuthentification: true,
                        needsAuthorization: false
                    }
                },
                { path: '/myAccount/personalInfo', component: PersonalInfo, name: 'PersonalInfo', meta: { needsAuthentification: true, needsAuthorization: false,}},
                { path: '/myAccount/changePassword', component: ChangePassword, name: 'ChangePassword' ,meta: { needsAuthentification: true, needsAuthorization: false,}},
            ]

        },
        { path: '/myReservations', name: 'MyReservations', component: MyReservations, meta: { needsAuthentification: true, needsAuthorization: false }},
        { path: '/reservation', name: 'ReservationPage', component: ReservationPage},
        { path: '/reservationContract', component: ReservationContractView, name: 'ReservationContractView', props: true, meta: { needsAuthentification: true, needsAuthorization: true,}},
        { path: '/adminPanel', name: 'AdminPanel', component: AdminPanel, meta: { needsAuthentification: true, needsAuthorization: true,},
            children: [
                {
                    path: '/adminPanel',
                    name: 'default',
                    component: VehiclesView,
                    meta: {
                        needsAuthentification: true,
                        needsAuthorization: true
                    }
                },
                { path: '/adminPanel/vehicles', component: VehiclesView, name: 'VehiclesView',meta: { needsAuthentification: true, needsAuthorization: true,}},
                { path: '/adminPanel/reservations', component: ReservationView, name: 'ReservationView', meta: { needsAuthentification: true, needsAuthorization: true,}},
                { path: '/adminPanel/calendar', component: CalendarsView, name: 'CalendarView', meta: { needsAuthentification: true, needsAuthorization: true,}},


            ],},

        { path: '/preCheckInInformationForm', name: 'preCheckInInformationForm', component: preCheckInInformationForm,
        children: [
            { path: '/preCheckInInformationForm/:id', name: 'preCheckInInformationForm', component: preCheckInInformationForm},
        ]},

        { path: '/errorPage', name: 'errorPage', component: errorPage },
    ]
});

router.beforeEach(async (to, from, next) => {




    if(to.meta.needsAuthentification) {
         await store.dispatch('getAuthUser').then(response=> {
             if(response?.data.status !== 'success') {
                 return next({ name: "Login"})
             } else if(to.meta.needsAuthorization && store.state.user.user_type !== "admin") {
                 return next({ name: 'MyAccount'})
             } else {
                 return next();
             }
        });
    } else {
            return next();
    }

})

export default router;