<template>
    <section class="ftco-section ftco-about" id="aboutus-section">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-md-6 img img-2 d-flex justify-content-center align-items-center" >
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.4891588741134!2d-111.9320657843126!3d40.685223347189535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87528b9f9fbcaec9%3A0xe5c0777e60669891!2s1324%20Pharaoh%20Cir%2C%20Salt%20Lake%20City%2C%20UT%2084123!5e0!3m2!1sen!2sus!4v1659909275815!5m2!1sen!2sus"
                            width="750" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="col-md-6 wrap-about ftco-animate">
                    <div class="heading-section heading-section-white pl-md-5">
                        <span class="subheading">About us</span>
                        <h2 class="mb-4">Who We Are*</h2>

                        <p>SpeedyRental was founded in 2022 in Salt Lake City Ut, This small rental company specializes in long and short term rental.
                            We always strive to provide high quality service and accommodate our customers with the best of our ability.
                            Contact us today and schedule you next trip for business or travel</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .ftco-section {
        padding: 3em 0;
        position: relative;

        .container {
            .no-gutters {
                .d-flex {
                    margin-top: -88px;
                }
                .ftco-animate {
                    .heading-section {
                        text-align: left;
                        color: rgba(255, 255, 255, 0.9);
                        .subheading {
                            color: rgba(255, 255, 255, 0.9);
                        }
                        .heading-section .subheading {
                            font-size: 18px;
                            display: block;
                            margin-bottom: 5px;
                            color: #1089ff;
                            font-size: 12px;
                            font-weight: 600;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                        }
                    }


                }
            }
        }



    }

    @media (max-width: 767.98px) {
        .ftco-section {
            padding: 3em 0; } }

    .ftco-about {
        position: relative;
        z-index: 0; }
    .ftco-about:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: #ff992e;
        z-index: -2;
        width: 63%; }
    @media (max-width: 767.98px) {
        .ftco-about:after {
            width: 100%; } }
</style>