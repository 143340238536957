<template>
    <div class="row body">
        <list-product/>

    </div>
</template>
<script>
    import ListProduct from './ListProduct.vue'
    export default {
        name: 'VehiclesView',

        data() {
            return {
            }
        },

        components: {
            ListProduct,
        },

        methods: {

        }
    }
</script>