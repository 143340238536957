<template>
    <transition name="modal-animation">
        <!-- used when we need to clock modal by clicking anywhere @click.self="close"-->
        <div v-show="modalActive" class="modal" >
            <transition name="modal-animation-inner">
                <div v-show="modalActive" class="modal-inner">
                    <h5 id="modal-title" class="modal-title"  >{{headerName}}</h5>
                    <i @click="close" class="far fa-times-circle"></i>
                    <!-- Modal Content -->
                    <slot />
                </div>
            </transition>

        </div>
    </transition>
</template>

<script>
    export default {
        props: ["modalActive", "mediaModalHeight", 'headerName', "showModalSelectError"],
        setup(props, { emit }) {
            const mobileModalHeight = props.mediaModalHeight  ? props.mediaModalHeight : '777px'
            const close = () => {
                emit("close");
            };



            const printEstimate = () => {
                showError();
                emit('printEstimatePage')
            };

            const showError = () => {
                let title = document.getElementById('modal-title');
                if(props.showModalSelectError) {

                    title.classList.add("animate-title")
                } else {
                    title.classList.remove("animate-title")
                }
            }



            return {
                close,
                printEstimate,
                mobileModalHeight,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .modal-animation-enter-active,
    .modal-animation-leave-active {
        transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
    }
    .modal-animation-enter-from,
    .modal-animation-leave-to {
        opacity: 0;
    }
    .modal-animation-inner-enter-active {
        transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
    }
    .modal-animation-inner-leave-active {
        transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
    }
    .modal-animation-inner-enter-from {
        opacity: 0;
        transform: scale(0.8);
    }
    .modal-animation-inner-leave-to {
        transform: scale(0.8);
    }
    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.7);
        .modal-inner {
            position: relative;
            max-width: 640px;
            width: 80%;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            background: #f8f9fa;
            padding: 0px 16px;
            height: auto;

            i {
                position: absolute;
                top: 15px;
                right: 15px;
                font-size: 20px;
                cursor: pointer;
                &:hover {
                    color: #ff992e;
                }
            }
            .modal-title {
                margin-top: 30px;
            }
            .animate-title {
                color: red;
                animation: blink;
                animation: zoom-in-zoom-out;
                animation-duration: 1s;
                animation-delay: 0s;
                animation-iteration-count: 3;
            }
            @keyframes zoom-in-zoom-out {
                0% {
                    transform: scale(1, 1);
                }
                50% {
                    transform: scale(1.4, 1.4);
                }
                100% {
                    transform: scale(1, 1);
                }
            }


            .footer {
                position: absolute;
                bottom: 10px;
                width: 100%;
                text-align: right;
                left: 0px;

                .btn {
                    background-color: #ff992e;
                }

                button {
                    margin-right: 20px;
                }
            }
        }
    }

    @media screen and (max-width: 349px) {
        .modal-inner {
            height: 500px !important;
        }
    }

    @media screen and (min-width: 350px) and (max-width: 400px) {
        .modal-inner {
            height: 680px !important;
        }
    }


</style>