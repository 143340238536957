<template>
    <tr v-if="reservation">
        <td>{{ reservation.userFirstName }} {{ reservation.userLastName }}</td>
        <td>{{ reservation.startDate }}</td>
        <td>{{ reservation.endDate }}</td>
        <td>{{ reservation.totalPrice }}</td>
        <td>{{ reservation.milesIncluded }}</td>
        <td>{{ reservation.deposit }}</td>
        <td>{{ reservation.LDW }}</td>
        <td>{{ reservation.RCLI }}</td>
        <td><a :href="reservation.receipt_url" target="_blank">View Receipt</a></td>

    </tr>
    <!--{{ reservation }}-->
</template>

<script>
    export default {
        name: "MyReservationListView",
        props: {
            reservation: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    // Adjust the background color of the table header
    table {
        thead th {
            background-color: #333;
            color: white;
        }
    }

    // Add hover effect to table rows
    table {
        tbody tr:hover {
            background-color: #f5f5f5;
        }
    }
</style>