<template>
    <div class="form-outline phone-div">
        <input
                v-model="this.phoneNumber"
                @input="this.onChange()"
                type="tel" class="form-control"
                :id="identifier"
                :style="{width: this.phoneInputWidth + 'px'}"
                maxlength="16"
                :placeholder="inputPlaceHolder"
                :disabled="disable"

        />

        <!--id="telephone"-->
    </div>
</template>
<script>
    //    import './App.css';
    //Bootstrap
    import 'bootstrap/dist/css/bootstrap.min.css';
    import { ref } from "vue";
    //intlTelInput
    import 'intl-tel-input/build/css/intlTelInput.css';
    import 'intl-tel-input/build/js/intlTelInput.js';
    import intlTelInput from 'intl-tel-input';

    export default {
//        props: ['identifier', 'disable'],
        props: {
            identifier: {
                default: 'defaultIdentifier' // Set your default value here
            },
            disable: {
                default: false,
            },
            inputPlaceHolder: {
                default: "(123)4567890",
            }
        },
        mounted(){
            const input = document.querySelector("#"+this.identifier);
            this.code = intlTelInput(input, {
                // any initialisation options go here
                preferredCountries: ["us", "ca"],
            });
            this.phoneInputWidth = this.$el.clientWidth

            const childElement = document.getElementById(this.identifier);
            const countryCodeDiv = childElement.previousSibling;
            countryCodeDiv.id = this.identifier + '-div';
            countryCodeDiv.addEventListener("click", this.onChange);
        },

        data() {
            return {
                phoneInputWidth: this.phoneInputWidth,
                phoneNumber: null,
                code: null,
            }
        },

        methods: {
            onChange() {
                if(this.phoneNumber){
                    // not allow letters
                    let x = this.phoneNumber
                        .replace(/\D/g, "")
                        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

                    // format it
                    this.phoneNumber = !x[2]
                        ? x[1]
                        : "(" + x[1] + ")" + x[2] + x[3];
                }
                this.$emit('onChange', {'number': this.phoneNumber, 'countryCode': this.code.selectedCountryData.dialCode})
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>
