<template>
    <div class="container">
        <app-header2 />
        <div class="row tab-row">
            <div class="tab-pane">
                <ul class="nav nav-tabs">
                    <li class="nav-item" >
                        <router-link :class="{ 'active': $route.path === '/adminPanel/vehicles' || $route.path === '/adminPanel' }" to="/adminPanel/vehicles" class="nav-link " aria-current="page" exact>Vehicles</router-link>
                    </li>
                    <li class="nav-item" >
                        <router-link :class="{  'active': $route.path === '/adminPanel/reservations' }" to="/adminPanel/reservations" class="nav-link" >Reservations</router-link>
                    </li>
                    <li class="nav-item" >
                        <router-link :class="{  'active': $route.path === '/adminPanel/calendar' }" to="/adminPanel/calendar" class="nav-link" >Calendar</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <router-view></router-view>
    </div>
    <!--<app-footer />-->
</template>

<script>
    import ListProduct from '../src/components/adminPannelComponents/ListProduct.vue'
    import appHeader2 from '../src/components/AppHeader2.vue';
    import appFooter from '../src/components/appFooter.vue'
    import { RouterLink, RouterView } from 'vue-router';
    export default {
        name: 'AdminPanel',

        mounted() {
            this.$store.dispatch('fetchReservations');
        },

        data() {
            return {
//                cars:
            }
        },

        components: {
            appHeader2,
            appFooter,
        },

        methods: {

        }

    }

</script>

<style lang="scss" scoped>
    .body {
        height: 80vh;
        maring-top: 70px !important;
    }
    .container {
        .tab-row {
            margin-bottom: 30px;
        }
        .row {
            .tab-pane {
                padding: 20px;
            }
        }
    }

</style>