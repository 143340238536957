<template>
    <div class="col-md-6 form-side-section align-items-center">
        <div class="services-wrap rounded-right w-100">
            <h3 class="heading-section mb-4">Call us reserve your car now</h3>
            <a href="index.html#prices-section" class="nav-link">
                <div class="row d-flex mb-4">
                    <div class="col-md-6 d-flex align-self-stretch ftco-animate">
                        <div class="services w-100 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">

                                <img src="../../public/images/long_term_logo1.png" width="95"/></div>
                            <div class="text w-100">
                                <h3 class="heading mb-2">Long Term Car Rental</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-self-stretch ftco-animate">
                        <div class="services w-100 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">

                                <img src="../../public/images/short_term_logo.png" width="95"/></div>
                            <div class="text w-100">
                                <h3 class="heading mb-2">Daily Rental</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <a href="tel:8015734248" class="btn btn-primary py-3 px-4">Call us At: 801-573-4248</a>
            <p class="min-required">Minimum of 4 Days Rental required</p>
        </div>
    </div>
</template>
<style lang="scss">
    .form-side-section {
        /*margin-top: 45px;*/
    }
    .services-wrap {
        padding: 2em 0em;
        font-size: 24px;
        font-weight: 600;

        h3 {
            font-weight: 700;
            margin-left: 26px;
        }

        h3.heading {
            font-size: 17px;
            font-weight: 500;
            margin-top: 20px;
            color: black;
        }

        .justify-content-center {
            padding: 0px;
        }

        .min-required {
            font-size: 19px;
            color: red;
            margin-top: 29px;
            margin-bottom: -6px;
        }

    }
    @media screen and (max-width: 830px) {
        .form-side-section {
            display: none;
        }
    }
</style>