<template>
    <thead class="thead-primary">
    <tr class="text-center">
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th class="bg-bright-orange  heading">
            {{ $t("message.longterm2") }}
            <p></p>
            <small>{{ $t('message.includedMiles', [300]) }}</small>
            <p></p>
        </th>
        <th class="bg-dark heading">
            {{ $t("message.longterm1") }}
            <p></p>
            <small>{{ $t('message.includedMiles', [250]) }}</small>
            <p></p>
        </th>
        <th class="bg-primary heading">
            {{ $t("message.perDay1") }}
            <p></p>
            <small>{{ $t('message.includedMiles', [200]) }}</small>
            <p></p>
        </th>
    </tr>
    </thead>
</template>
<script>
    export default {
        name: 'TableHeader',
        components: {},
        methods: {
        },
        computed: {
        },
    }
</script>
<style lang="scss">
    thead {
        color: white;
    }
    .thead-primary {
        tr {
            th {
                padding: 30px 10px;
                color: #fff !important;
                border: 1px solid transparent !important;
            }
        }
    }
    .bg-bright-orange {
        background-color: #ff992e;
    }
    @media screen and (max-width: 800px) {
        .thead-primary {
            tr {
                th {
                    padding: 10px 5px;
                }
            }
        }
    }

</style>