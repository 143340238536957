import axios from 'axios'
import { createLogger, createStore} from "vuex";
import Vuex from "vuex"
import state from './state';
import mutations from './mutations';
import router from '../router'
let baseUrl = 'https://localhost/speedyrental/backend/index.php?';
let baseUrl1 = 'https://localhost/speedyrental/backend/protected.php?';

// swhich base url for backend request
if(window.location.origin == "https://carspeedyrental.com" || window.location.origin == "http://carspeedyrental.com" +
    "" || window.location.origin == "https://www.carspeedyrental.com" || window.location.origin == "http://www.carspeedyrental.com") {
    baseUrl = '../../../backend/index.php?';
    baseUrl1 = '../../../backend/protected.php?';
}else if (window.location.origin == 'http://10.0.0.145:8080' || window.location.origin == 'https://10.0.0.145:8080') {
    baseUrl = 'http://10.0.0.145/speedyrental/backend/index.php?';
    baseUrl1 = 'http://10.0.0.145/speedyrental/backend/protected.php?';
}

const store = new Vuex.Store({
    state,
    mutations,
    actions:  {

            toastMessage({commit}, payload) {
                commit('ADD_TOAST_MESSAGE', payload)
            },


            async fetchCars({commit}, payload) {
                try {
                    const cars = await axios.get(`${baseUrl}action=getCars`);
                    commit('setCars', cars.data)
                } catch (e) {
                    console.log(e)
                    throw new Error(e);
                }
            },

            async fetchPriceManipulations({commit}, payload) {
                try {
                    const priceManipulations = await axios.get(`${baseUrl}action=getPriceManipulations`);
                    commit('setPriceManipulations', priceManipulations.data)
                } catch (e) {
                    throw new Error(e)
                }
            },

        async fetchInsurancePrices({commit}, payload) {
            try {
                const insurancePrices = await axios.get(`${baseUrl}action=getInsurancePrices`);
                commit('setInsurancePrices', insurancePrices.data)
            } catch (e) {
                console.log(e)
                throw new Error(e)
            }
        },

            async fetchReservations({commit}, payload) {
                try {
                    const reservations = await axios.get(`${baseUrl1}action=getReservations`);
                    commit('setReservations', reservations.data)
                } catch (e) {
                    throw new Error(e)
                }
            },

            async getReservationsByEmail({commit}, payload) {
                const formData = new FormData();
                formData.append('email', payload);
                try {
                    const result = await axios({
                        url: `${baseUrl}action=getReservationsByEmail`,
                        method: "POST",
                        data: formData,
                    })
                    return result;
                } catch (e) {
                    throw new Error(e)
                }



            },

            async getReservation({commit}, payload) {
                const formData = new FormData();
                formData.append('charge_id', payload);
                try {
                    const result = await axios({
                        url: `${baseUrl}action=getReservation`,
                        method: "POST",
                        data: formData,
                    })
                    if(result.data.status === 'success' && result.data.reservation.charge_id === router.currentRoute._value.params.id) {
                        commit('setPreCheckInFormReservation', result.data.reservation)
                    } else {
                        router.push('/errorPage');
                        commit('ADD_TOAST_MESSAGE', {type: 'error', msg: "Link Is Invalid Or Expired"});
                    }
                } catch (e) {
                    throw new Error(e)
                }
            },


        async getReservationForOnlineCheckIn({commit}, payload) {
            const formData = new FormData();
            formData.append('charge_id', payload);
            try {
                const result = await axios({
                    url: `${baseUrl}action=getReservation`,
                    method: "POST",
                    data: formData,
                })
                if(result.data.status === 'success' && result.data.reservation.charge_id) {
                    commit('setPreCheckInFormReservation', result.data.reservation)
                } else {
                    router.push('/errorPage');
                    commit('ADD_TOAST_MESSAGE', {type: 'error', msg: "Link Is Invalid Or Expired"});
                }
            } catch (e) {
                throw new Error(e)
            }
        },



        async getPrecheckinInfo({commit}, payload) {
            const formData = new FormData();
            formData.append('charge_id', payload);
            try {
                const result = await axios({
                    url: `${baseUrl}action=getPreCheckinData`,
                    method: "POST",
                    data: formData,
                })
                if(result.data.status === 'success') {
                    commit('ADD_TOAST_MESSAGE', {type: 'success', msg: "Pre-Checkin Form is completed For This Reservation"});
                    return result.data.preCheckinData;
                } else {
                    commit('ADD_TOAST_MESSAGE', {type: 'error', msg: result.data.preCheckinData});
                    return null;

                }
            } catch (e) {
                throw new Error(e)
            }
        },


            async submitPreCheckInForm({commit}, payload) {
                try {
                    const result = await axios({
                        url: `${baseUrl}action=submitPreCheckInInfo`,
                        method: "POST",
                        data: payload
                    })
                    return result;
                } catch (e) {
                    throw new Error(e)
                }
            },




            toggleEstimatePriceModal({commit}, payload) {
                commit('setEstimateModalToggle', payload)
            },

            reservationData({commit}, payload) {
                commit('setPossibleReservationData', payload)
            },

            async addCar({commit, dispatch}, payload) {
                const formData = new FormData();
                formData.append('name', payload.name);
                formData.append('vin', payload.vin);
                formData.append('description', payload.description);
                formData.append('price', payload.price);
                formData.append('$prePaidRefueling', payload.PrePaidRefueling);
                formData.append('mpg', payload.mpg);
                formData.append('odo', payload.odo);
                formData.append('year', payload.year);
                formData.append('isRunning', payload.isRunning);
                formData.append('isCleaned', payload.isCleaned);
                formData.append('picture', payload.picture);

                     await axios({
                        url: `${baseUrl1}action=addCar`,
                        method: "POST",
                        data: formData,
                        headers: {
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': "Bearer " + localStorage.getItem("jwt")
                        },
                        onUploadProgress: uploadEvent  => {
                            console.log('upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                        }
                    }).then(response =>{
                        if(response.data === 'success') {
                            dispatch('fetchCars');
                            commit('ADD_TOAST_MESSAGE', {type: 'success', msg: "Car Added Successfully"});
                        }
                    }).catch (error => {
                         commit('ADD_TOAST_MESSAGE', {type: 'error', msg: error});
                    })
            },

            async updateCar({commit, dispatch}, payload) {
                const formData = new FormData();
                formData.append('id', payload.id);
                formData.append('name', payload.name);
                formData.append('description', payload.description);
                formData.append('price', payload.price);
                formData.append('mpg', payload.mpg);
                formData.append('odo', payload.odo);
                formData.append('year', payload.year);
                formData.append('isRunning', payload.isRunning);
                formData.append('isCleaned', payload.isCleaned);
                formData.append('picture', payload.picture);

                await axios({
                    url: `${baseUrl1}action=editCar`,
                    method: "POST",
                    data: formData,
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem("jwt")
                    },
                    onUploadProgress: uploadEvent  => {
                        console.log('upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                    }
                }).then(response =>{
                    if(response.data === 'success') {
                        dispatch('fetchCars');
                        commit('ADD_TOAST_MESSAGE', {type: 'success', msg: "Car Updated Successfully"});
                    }
                }).catch (error => {
                    commit('ADD_TOAST_MESSAGE', {type: 'error', msg: error});
                })
            },

            deleteCar({commit, dispatch}, payload) {
                const formData = new FormData();
                formData.append('id', payload);
                try {
                    const result = axios({
                        url: `${baseUrl1}action=deleteCar`,
                        method: "POST",
                        data: formData,
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem("jwt")
                        },
                    }).then(() =>{
                        dispatch('fetchCars');
                        commit('ADD_TOAST_MESSAGE', {type: 'success', msg: "Car Deleted Successfully"});
                    })
                } catch (e) {
                    commit('ADD_TOAST_MESSAGE', {type: 'error', msg: e});
                }
            },
            async registerUser({commit}, payload) {
                try {

                    const register = await axios.post(`${baseUrl}action=register`, {
                        firstName: payload.firstName,
                        lastName: payload.lastName,
                        email: payload.email,
                        phone: payload.phone,
                        phoneCountryCode: payload.phoneCountryCode,
                        password: window.btoa(payload.password),
                        passwordConfirm: window.btoa(payload.passwordConfirm),

                    })
                    return register;
                } catch (e) {
                    throw new Error(e)
                }
            },

            async loginUser({commit}, payload) {
                try {
                    const response = await axios.post(`${baseUrl}action=login`, {
                        email: payload.email,
                        password: window.btoa(payload.password),
                    })
                    commit('setAuthUser', response.data.user)
                    localStorage.setItem('jwt', response.data.jwt)
                    return response;
                } catch (e) {
                    throw new Error(e)
                }
            },

            logOutUser({commit}) {
                commit('setAuthUser', {});
                localStorage.setItem('jwt', '')
            },

            async changePassword({commit, dispatch}, payload) {
                const formData = new FormData();
                formData.append('confirmPassword', window.btoa(payload.confirmPassword));
                formData.append('newPassword', window.btoa(payload.newPassword));
                formData.append('oldPassword', window.btoa(payload.oldPassword));
                await axios({
                    url: `${baseUrl1}action=resetPassword`,
                    method: "POST",
                    data: formData,
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem("jwt")
                    },
                }).then(response =>{
                    // check if we have data and it is a code
                    if(response.data === 'success') {
                        dispatch('logOutUser');
                        commit('ADD_TOAST_MESSAGE', {type: 'success', msg: "Password Changed"});
                        router.push('/login');
                    }else if(response.data === "old_password_bad") {
                        commit('ADD_TOAST_MESSAGE',  {type: 'warning', msg: "Current Password is Incorrect"});
                    } else {
                        commit('ADD_TOAST_MESSAGE',  {type: 'error', msg: "Error  Try Again Later or Contact Support"});
                    }
                }).catch (error => {
                    commit('ADD_TOAST_MESSAGE', {type: 'error', msg: error});
                })
            },

            // Authenticate user on refresh
            async getAuthUser({commit, dispatch}) {
                if(localStorage.getItem("jwt")) {
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + localStorage.getItem("jwt");
                    try {
                        const response = await axios.post(`${baseUrl1}action=auth`);
                        commit('setAuthUser', response.data.user)
                        return response;
                    } catch (e) {
                        dispatch('logOutUser');
                        console.error(new Error(e))
                    }
                }


            },

            async verifyEmail({commit, dispatch}, payload) {
                const formData = new FormData();
                commit('setLoader', true);
                formData.append('email', payload);
                await axios({
                    url: `${baseUrl}action=Verification`,
                    method: "POST",
                    data: formData
                }).then(response =>{
                    // check if we have data and it is a code
                    if(response.data && typeof response.data === 'number') {
                        commit('setVerificationCode', response.data);
                        commit('setLoader', false);
                        commit('ADD_TOAST_MESSAGE', {type: 'success', msg: "Verification Code Was Sent Successfully"});
                    } else {
                        commit('ADD_TOAST_MESSAGE', {type: 'warning', msg: response.data});
                        commit('setLoader', false);
                    }
                }).catch (error => {
                    commit('ADD_TOAST_MESSAGE', {type: 'error', msg: error});
                    commit('setLoader', false);
                })
            },

            async submitStripeReservation({commit, dispatch, state}, payload) {
                try {
                    const result = await axios({
                        url: `${baseUrl}action=processReservation`,
                        method: "POST",
                        data: payload,
                        onUploadProgress: uploadEvent  => {
                            console.log('upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
                        }
                    })
                    return result;
                } catch (e) {
                    throw new Error(e)
                }
            },

            async updateReservation({commit}, payload) {
                try {

                    const result = await axios({
                        url: `${baseUrl1}action=updateReservation`,
                        method: "POST",
                        data: payload
                    })
                    return result;
                } catch (e) {
                    throw new Error(e)
                }
            },

            async confirmReservation({commit}, payload) {
                try {
                    const result = await axios({
                        url: `${baseUrl1}action=confirmReservation`,
                        method: "POST",
                        data: payload
                    })
                    return result;
                } catch (e) {
                    throw new Error(e)
                }
            },

            async cancelUnconfirmedReservation({commit}, payload) {
                try {
                    const result = await axios({
                        url: `${baseUrl1}action=cancelUnconfirmedReservation`,
                        method: "POST",
                        data: payload
                    })
                    return result;
                } catch (e) {
                    throw new Error(e)
                }
            },


        async submitContract({commit}, payload) {
            try {
                const result = await axios({
                    url: `${baseUrl}action=sendContract`,
                    method: "POST",
                    data: payload
                })
                return result;
            } catch (e) {
                throw new Error(e)
            }
        },

        async deleteAndResendContract({commit}, payload) {
            try {
                const result = await axios({
                    url: `${baseUrl}action=deleteAndResendContract`,
                    method: "POST",
                    data: payload
                })
                return result;
            } catch (e) {
                throw new Error(e)
            }
        },

        async chargeCustomerAdditionalFee({commit}, payload) {
            try {
                const result = await axios({
                    url: `${baseUrl1}action=chargeCustomerByStripeCustomerId`,
                    method: "POST",
                    data: payload
                })
                return result;
            } catch (e) {
                throw new Error(e)
            }
        },

        async findPromoCode({commit}, payload) {
            try {
                const result = await axios({
                    url: `${baseUrl}action=findPromoCode`,
                    method: "POST",
                    data: payload
                })
                return result;
            } catch (e) {
                throw new Error(e)
            }
        },

    },
});
export default store;