<template>

        <h5>New Product</h5>
        <form @submit.prevent="addProduct">
            <div class="form-group">
                <input type="text" class="form-control" v-model="form.name" placeholder="Car Name">
                <div class="form-error" v-if="v$.form.name.$error" >
                    <small v-if="v$.form.name.required.$invalid"> Name Is Required</small>
                </div>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" v-model="form.vin" placeholder="Vin Number">
                <div class="form-error" v-if="v$.form.vin.$error" >
                    <small v-if="v$.form.name.required.$invalid">Vin Number Is Required</small>
                </div>
            </div>

            <div class="form-group">
                <textarea class="form-control" id="message-text"  v-model="form.description" placeholder="Description"></textarea>
                <div class="form-error" v-if="v$.form.description.$error" >
                    <small v-if="v$.form.description.required.$invalid"> Description Is Required</small>
                </div>
            </div>
            <div class="form-group">
                <input type="text" class="form-control"  v-model="form.price" placeholder="Price">
                <div class="form-error" v-if="v$.form.price.$error" >
                    <small v-if="v$.form.price.required.$invalid"> Price Is Required</small>
                    <small v-if="v$.form.price.decimal.$invalid" > Price needs to be Decimal</small>
                    <small v-if="v$.form.price.minValue.$invalid || v$.form.price.maxValue.$invalid" > Price Must be Between 10 - 1000 </small>
                </div>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" v-model="form.mpg" placeholder="Miles Per Galon">
                <div class="form-error" v-if="v$.form.mpg.$error" >
                    <small v-if="v$.form.mpg.required.$invalid"> MPG Is Required</small>
                    <small v-if="v$.form.mpg.numeric.$invalid" > MPG needs to be Number</small>
                </div>
            </div>

            <div class="form-group">
                <input type="text" class="form-control" v-model="form.PrePaidRefueling" placeholder="Prepaid feueling Price">
                <div class="form-error" v-if="v$.form.PrePaidRefueling.$error" >
                    <small v-if="v$.form.PrePaidRefueling.required.$invalid"> MPG Is Required</small>
                    <small v-if="v$.form.PrePaidRefueling.numeric.$invalid" > MPG needs to be Number</small>
                </div>
            </div>

            <div class="form-group">
                <input type="text" class="form-control" v-model="form.odo" placeholder="Odometer Reading">
                <div class="form-error" v-if="v$.form.odo.$error" >
                    <small v-if="v$.form.odo.required.$invalid"> ODO Is Required</small>
                    <small v-if="v$.form.odo.numeric.$invalid" > ODO needs to be Number</small>
                </div>
            </div>

            <div class="form-group">
                <input type="text" class="form-control" v-model="form.year" placeholder="Manufacture Year">
                <div class="form-error" v-if="v$.form.year.$error" >
                    <small v-if="v$.form.year.required.$invalid"> ODO Is Required</small>
                    <small v-if="v$.form.year.allowedYears.$invalid" > Only Years Between 2007 To 2024</small>
                </div>
            </div>

            <div class="form-group">
                <p>Is Car Running</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="1" v-model="form.isRunning" id="isrunning1">
                    <label class="form-check-label" for="isrunning1">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="0" v-model="form.isRunning" id="isrunning2" >
                    <label class="form-check-label" for="isrunning2">
                        No
                    </label>
                </div>
                <div class="form-error" v-if="v$.form.isRunning.$error" >
                    <small v-if="v$.form.isRunning.required.$invalid"> Email Is Required</small>
                </div>
            </div>
            <div class="form-group">
                <p>Is Car Cleaned</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="1" v-model="form.isCleaned" id="iscleaned">
                    <label class="form-check-label" for="iscleaned">
                        Yes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="0" v-model="form.isCleaned" id="iscleaned1" >
                    <label class="form-check-label" for="iscleaned1">
                        No
                    </label>
                </div>
                <div class="form-error" v-if="v$.form.isCleaned.$error" >
                    <small v-if="v$.form.isCleaned.required.$invalid"> Email Is Required</small>
                </div>
            </div>
            <div class="form-group">
                <input type="file" class="form-control" @change="onFileSelected" placeholder="Picture">
                <div class="form-error" v-if="v$.form.picture.$error" >
                    <small v-if="v$.form.picture.required.$invalid"> Picture Is Required</small>
                    <small v-if="v$.form.picture.allowedImageSizeFormats.$invalid"> Only JPEG or PNG Formats Under 3MB Allowed</small>
                </div>
            </div>
            <!-- Submit button -->
            <button type="submit" class="btn btn-primary btn-block mb-4">Add Product</button>
        </form>


</template>

<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, email, decimal, numeric, maxValue, minValue } from '@vuelidate/validators';

    const allowedImageSizeFormats = (value) => {
        if((value.type === "image/jpeg" || value.type === 'image/png') && value.size <= '3000000') {
            return true;
        }else  {
            return false;
        }
    };
    const allowedYears = (value) => {
        if(value < 2007 || value > 2024) {
            return false;
        } else return true;
    };
    const minPrice = 10;
    const maxPrice = 1000;

    export default {

        name: 'AddProduct',
        data() {
            return {
                v$: useVuelidate(),
                form: {
                    name: null,
                    description: null,
                    price: null,
                    PrePaidRefueling: null,
                    mpg: null,
                    odo: null,
                    year: null,
                    isRunning: 0,
                    isCleaned: 0,
                    picture: null,
                    vin: null,
                },
            }
        },
        validations () {
            return {
                form: {
                    name: {
                        required,
                    },
                    vin: {
                        required,
                    },
                    description: {
                        required,
                    },
                    price: {
                        required,
                        decimal,
                        minValue: minValue(minPrice),
                        maxValue: maxValue(maxPrice),

                    },

                    PrePaidRefueling: {
                        required,
                        numeric

                    },

                    mpg: {
                        required,
                        numeric

                    },
                    year: {
                        required,
                        allowedYears,
                    },
                    odo: {
                        required,
                        numeric
                    },
                    isRunning: {
                        required,
                    },
                    isCleaned: {
                        required,
                    },
                    picture: {
                        required,
                        allowedImageSizeFormats,
                    },
                }
            }
        },

        components: {
        },

        methods: {
            addProduct() {
                this.v$.form.$validate();
                if(!this.v$.form.$error) {
                    this.$store.dispatch('addCar', this.form);
                    // Resert form & Reset errors
//                    Object.keys(this.form).forEach(v => this.form[v] = "");
//                    this.v$.form.$reset();
                }
            },
            onFileSelected(event) {
                this.form.picture = event.target.files[0];
            },
        }

    }

</script>

<style lang="scss" scopted>
    form {
        .form-group {
            .form-error {
                small {
                    display: block;
                    color: #f22f41;
                    font-size: 12px;
                }
            }
        }
    }
</style>