<template >
    <!--:autoplay="3000"-->
    <Carousel  :items-to-show="3" :wrap-around="true">

        <Slide v-for="(car, index) in getCars" :key="index">

            <!--<div class="carousel__item">-->
                <div class="card" id="inventory-carrusel-section">
                    <img v-bind:src="car.Picture" class="card-img-top" alt="images/toyota_cross_background.png">
                    <div class="card-body">
                        <div class="d-flex mb-3">
                            <h5 class="card-title">{{car.Name}}</h5>
                            <h5 class="card-price">{{(car.Price -2).toFixed(2)}}<span>/day</span></h5>
                        </div>
                        <!--<p v-show="!detectMob" class="card-text">{{car.Description}}</p>-->


                    </div>
            </div>

        </Slide>

        <!--<template #addons>-->
            <!--<Navigation />-->
        <!--</template>-->
    </Carousel>
</template>

<script>
    import { defineComponent } from 'vue';
    import { Carousel, Navigation, Slide } from 'vue3-carousel';
    import { toRaw } from 'vue'

    import 'vue3-carousel/dist/carousel.css';

    export default defineComponent({
        name: 'WrapAround',
        data() {
            return {

            }
        },

        components: {
            Carousel,
            Slide,
            Navigation,
        },
        methods: {
        },

        computed: {
            getCars() {
                let cars = toRaw(this.$store.state.cars)
                return cars;
            },
            detectMob() {
                return (window.innerWidth <= 500 );
            },
        },

    });
</script>
<style scoped lang="scss">
    .card-body {
        color: #000000;
        font-size: 20px;
        font-weight: 500;
        font-family: "Poppins", Arial, sans-serif;

        .card-text {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.3);
        }

        .card-price {
            margin-left: auto !important;
            color: #1089ff;
            margin-bottom: 0;
            font-weight: 600;

            span {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.3);
            }
        }
    }

    @media screen and (max-width: 800px) {
        .card-body {
            .card-price {
                font-size: 12px;

                span {
                    font-size: 9px;
                }
            }
            .card-title {
                font-size: 12px;
            }
        ;
        }
    }

</style>